import React, { useEffect, useRef, useState } from 'react';
import SectionDescription from '../common/sectionDescription';
import BreadCrumbs from '../common/BreadCrumbs';
import { moduleNames, nFormatter, plularliseData, slugToNormal, stripHtmlTags } from '../../utils/utils';

import { Loadingicon } from '../../AppIcons';
import ViewAllButton from '../common/viewallbutton';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { filter, isArray, isEmpty } from 'lodash';
import { useSearch } from '../../Context/SearchContext';
import searching from '../../assets/images/searching.gif';
import find from '../../assets/images/find.png';
import { getGlobalSearchResult } from '../../api/search';
import { MathJax, MathJaxContext } from 'better-react-mathjax';
import NotFound from '../common/notfound';

function GlobalSearch() {
    const [pageNumbersByFilter, setPageNumbersByFilter] = useState({});
    const [pageNo, setPageNo] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(true);
    const [loading, setLoading] = useState(true);
    const [isNotFound, setIsNotFound] = useState(false);
    const [scrollLoading, setScrollLoading] = useState(false);
    const [loadingLoadMore, setLoadingLoadMore] = useState(false);
    // const [allSearchResults, setAllSearchResults] = useState(null);
    const { sModuleType, setSModuleType, searchValue, setSearchLoading, searchLoading, allSearchResults, setAllSearchResults, fromSearch, setFromSearch } = useSearch();
    const [filterData, setFilterData] = useState();
    const [totalResultFound, setTotalResultFound] = useState(0);
    const scrollContainerRef = useRef(null);

    const navigation = useNavigate();

    useEffect(() => {
        if (searchLoading == true) {
            setTimeout(() => {
                setFilterData(filterTypeData()[0].value);
                setPageNumbersByFilter({});
                setPageNo(1)
                setAllSearchResults(null);
                handleSearchGlobal(null, 1);
            }, 1);
        }

    }, [searchLoading])

    useEffect(() => {
        setPageNumbersByFilter({});
        setPageNo(1);
        if(!fromSearch){
            setAllSearchResults(null);
            setFromSearch(false);
        }
        setFilterData(filterTypeData()[0].value);
    }, [sModuleType]);

    // fetch all search
    const handleSearchGlobal = async (type, pageNo) => {
        const mTypeMapping = {
            qb: 'qb',
            ff: 'ff',
            ex: 'exams',
            dic: 'dc',
            gig: 'gigs',
            bl: 'blogs',
            lyr: 'lyr',
            qt: 'sht',
            tut: 'tutorials',
            fm: 'forum',
            cn: 'cn',
            corp: 'corporates',
            edu: 'educators',
            default: 'iq'
        };

        const mType = mTypeMapping[sModuleType?.value] || mTypeMapping.default;

        const resSearchResult = await getGlobalSearchResult({
            type,
            query: searchValue,
            mType,
            pageNo
        });

        if (resSearchResult?.success || resSearchResult?.success === 200) {
            const resData = resSearchResult?.message || resSearchResult?.data || resSearchResult;

            if (!type && pageNo === 1) {

                setAllSearchResults(resData);
            } else if (type && pageNo !== 1) {
                setAllSearchResults(prevState => ({
                    ...prevState,
                    [filterData]: {
                        ...(resData?.[filterData]),
                        data: [
                            ...(prevState[filterData]?.data || []),
                            ...(resData?.[filterData]?.data || [])
                        ]
                    }
                }));
            }
        }

        // Update loading states
        setLoading(false);
        setLoadingLoadMore(false);
        setScrollLoading(false);
        setSearchLoading(false);
    };


    const totalCount = (type) => {
        const totalCountData = allSearchResults?.[type]?.total;
        return totalCountData ?? 0;
    }

    // load more seach 
    const loadMore = () => {
        
        setScrollLoading(true);
        setShowLoadMore(true);

        const nxtPage = pageNo + 1;
        setPageNumbersByFilter((prev) => ({
            ...prev,
            [filterData]: nxtPage,
        }));
        handleSearchGlobal(filterData, nxtPage);
        setPageNo(nxtPage);
    }


    const filterTypeData = () => {
        const filterMap = {
            qb: [
                { label: 'Subject', value: 'subject' },
                { label: 'Topic', value: 'topic' },
                { label: 'Question', value: 'question' }
            ],
            iq: [
                { label: 'Subject', value: 'subject' },
                { label: 'Topic', value: 'topic' },
                { label: 'Question', value: 'question' }
            ],
            ff: [
                { label: 'Subject', value: 'subject' },
                { label: 'Topic', value: 'topic' },
                { label: 'Fullform', value: 'fullform' }
            ],
            dic: [
                { label: 'Subject', value: 'subject' },
                { label: 'Topic', value: 'topic' },
                { label: 'Question', value: 'question' }
            ],
            ex: [
                { label: 'Subject', value: 'subject' },
                { label: 'Topic', value: 'topic' },
                { label: 'Exam', value: 'exam' }
            ],
            cn: [
                { label: 'Category', value: 'category' },
                { label: 'Sub Category', value: 'subcategory' },
                { label: 'News', value: 'news' },
                // { label: 'Tags', value: 'tags' }
            ],
            gig: [
                // { label: 'Category', value: 'category' },
                // { label: 'Sub Category', value: 'subcategory' },
                { label: 'Campaigns', value: 'campaign' }
            ],
            qt: [
                { label: 'Category', value: 'category' },
                { label: 'Author', value: 'author' },
                // { label: 'Tags', value: 'tags' },
                { label: 'Quotes', value: 'quotes' }
            ],
            lyr: [
                { label: 'Tags', value: 'tags' },
                { label: 'Artist', value: 'artist' },
                { label: 'Lyricist', value: 'writer' },
                { label: 'Lyrics', value: 'lyrics' }
            ],
            bl: [
                { label: 'Category', value: 'category' },
                { label: 'Sub Category', value: 'subcategory' },
                { label: 'Blogs', value: 'blogs' }
            ],
            fd: [
                { label: 'Feeds', value: 'fd' }
            ],
            fm: [
                { label: 'Category', value: 'category' },
                { label: 'Sub Category', value: 'subcategory' },
                { label: 'Forum', value: 'forum' }
            ],
            tut: [
                { label: 'Tags', value: 'tag' },
                { label: 'Tutorial', value: 'tutorial' }
            ],
            corp: [
                { label: 'Industries', value: 'industries' },
                { label: 'Corporate Types', value: 'corporateTypes' },
                { label: 'Locality', value: 'locality' },
                { label: 'State', value: 'states' },
                { label: 'City', value: 'cities' },
                { label: 'Corporate', value: 'corporate' }
            ],
            edu: [
                { label: 'Streams', value: 'streams' },
                { label: 'Type', value: 'types' },
                { label: 'State', value: 'states' },
                { label: 'City', value: 'cities' },
                { label: 'Locality', value: 'localities' },
                { label: 'Educators', value: 'educators' },
                { label: 'Courses', value: 'courses' },
                { label: 'Exams', value: 'exams' },
                { label: 'Admissions', value: 'bCourses' },
                { label: 'Specialization', value: 'specs' }
            ]

        };

        return filterMap[sModuleType?.value] || [];
    };

    useEffect(() => {
        setFilterData(filterTypeData()[0].value);
        // return () =>{}
    }, [])


    const handleFilterChange = (e) => {
        const fValue = e.target.value;
        if (!allSearchResults?.[fValue]?.data?.length) {
            setIsNotFound(true);
        } else {
            setIsNotFound(false);
        }

        setPageNumbersByFilter((prev) => ({
            ...prev,
            [filterData]: pageNo,
        }));

        const newPageNo = pageNumbersByFilter[fValue] || 1;

        setFilterData(fValue);
        setPageNo(newPageNo);

        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTo(0, 0);
        }

    }


    const redirectionLinks = (rData) => {
        const choosenModule = sModuleType.value;
        const choosenFilter = filterData;
        const moduleName = moduleNames?.[choosenModule];
        setFromSearch(true);
        if (['qb', 'iq', 'ff', 'dic'].includes(choosenModule)) {
            if (choosenFilter === 'subject') {
                navigation(`/${moduleName}/${rData?.subject_title.replaceAll(" ", "-").toLowerCase()}`, { state: { subid: rData.id } });
                
                return;
            }

            if (choosenFilter === 'topic') {
                const tSlug = stripHtmlTags(rData.topic_name).toLowerCase().replace(/ /g, '-');
                navigation(`/${moduleName}/${rData?.subject?.slug}/${tSlug}`, {
                    state: {
                        subid: rData?.subject?.id,
                        topicName: rData.topic_name,
                        catId: rData.id,
                        moduleName,
                    },
                });
                return;
            }

            if (['question', 'fullform'].includes(choosenFilter)) {
                const qSlug = stripHtmlTags(rData.question).toLowerCase().replace(/ /g, '-');
                navigation(`/${moduleName}/${rData?.subject?.slug}/${rData?.topic?.slug}/${qSlug}-${rData.id}`, {
                    state: {
                        catId: rData?.topic?.id,
                        qId: rData.id,
                        topicName: rData.topic.slug,
                        isFavourite: false,
                        moduleName,
                        subid: rData.subject.id,
                    },
                });
                return;
            }
        }
        if (['ex'].includes(choosenModule)) {
            if (choosenFilter === 'subject') {
                navigation(`/${moduleName}/${rData?.slug}`);
                return;
            }

            if (choosenFilter === 'topic') {
                navigation(`/${moduleName}/topic/${rData?.slug}`);
                return;
            }

            if (['exam'].includes(choosenFilter)) {
                navigation(`/${moduleName}/detail/${rData?.slug}`);
                return;
            }
        }
        if (['gig'].includes(choosenModule)) {
            if (['campaign'].includes(choosenFilter)) {
                navigation(`/gigs/tasks/${stripHtmlTags(rData.title).toLowerCase().replace(/ /g, '-')}-${rData.id}`);
                return;
            }
        }
        if (['qt'].includes(choosenModule)) {
            if (choosenFilter === 'category') {
                navigation(`/${moduleName}/topics/${rData?.id}/${rData?.slug}`);
                return;
            }

            if (choosenFilter === 'author') {
                navigation(`/${moduleName}/authors/${rData?.id}/${rData?.slug}`);
                return;
            }
            // if (choosenFilter === 'tags') {
            //     navigation(`/${moduleName}/topics/${rData?.id}/${rData?.slug}`);
            //     return;
            // }
            if (choosenFilter === 'quotes') {
                navigation(`/${moduleName}/detail/${rData?.id}/${rData?.slug}`);
                return;
            }

        }
        if (['lyr'].includes(choosenModule)) {
            if (choosenFilter === 'tags') {
                navigation(`/${moduleName}/album/${rData.id}/${rData.slug}`);
                return;
            }
            if (choosenFilter === 'artist') {
                navigation(`/${moduleName}/artist/${rData.id}/${rData.slug}`);
                return;
            }
            if (choosenFilter === 'writer') {
                navigation(`/${moduleName}/lyricist/${rData.id}/${rData.slug}`);
                return;
            }
            if (choosenFilter === 'lyrics') {
                navigation(`/${moduleName}/detail/${rData.id}/${rData.slug}`);
                return;
            }

        }
        if (['bl'].includes(choosenModule)) {
            if (choosenFilter === 'category') {
                navigation(`/${moduleName}/${rData.slug}`);
                return;
            }
            if (choosenFilter === 'subcategory') {
                navigation(`/${moduleName}/${rData.cat?.slug}/${rData.slug}`);
                return;
            }
            if (choosenFilter === 'blogs') {
                navigation(`/${moduleName}/detail/${rData.slug}-${rData.id}`);
                return;
            }

        }
        if (['tut'].includes(choosenModule)) {
            if (choosenFilter === 'tag') {
                navigation(`/${moduleName}/${rData.slug}`);
                return;
            }
            if (choosenFilter === 'tutorial') {
                navigation(`/${moduleName}/detail/${rData.slug}-${rData.id}`);
                return;
            }
        }
        if (['fm'].includes(choosenModule)) {
            if (choosenFilter === 'category') {
                navigation(`/${moduleName}/${rData.slug}`);
                return;
            }
            if (choosenFilter === 'subcategory') {
                navigation(`/${moduleName}/${rData.cat?.slug}/${rData.slug}`);
                return;
            }
            if (choosenFilter === 'forum') {
                navigation(`/${moduleName}/${rData.category?.slug}/${rData.subcategory?.slug}/${rData.slug}`);
                return;
            }

        }
        if (['cn'].includes(choosenModule)) {
            if (choosenFilter === 'category') {
                navigation(`/${moduleName}/top-10-trending-careers-streams/${rData.id}/${rData.slug}`);
                return;
            }
            if (choosenFilter === 'subcategory') {
                navigation(`/${moduleName}/top-10-trending-careers-streams/${rData.cat?.id}/${rData?.id}/${rData.slug}`);
                return;
            }
            if (choosenFilter === 'news') {
                navigation(`/${moduleName}/news/${rData.id}/${rData.slug}`);
                return;
            }

        }
        if (['corp'].includes(choosenModule)) {
            if (choosenFilter === 'industries') {
                navigation(`/${moduleName}/industry/${rData?.slug}`);
                return;
            }
            if (choosenFilter === 'corporateTypes') {
                navigation(`/${moduleName}/type/${rData?.slug}`);
                return;
            }
            if (choosenFilter === 'states') {
                navigation(`/${moduleName}/state/${rData?.slug}`);
                return;
            }
            if (choosenFilter === 'cities') {
                navigation(`/${moduleName}/city/${rData?.slug}`);
                return;
            }
            if (choosenFilter === 'locality') {
                navigation(`/${moduleName}/locality/${rData?.slug}`);
                return;
            }
            if (choosenFilter === 'corporate') {
                navigation(`/${moduleName}/company/${rData?.slug}-${rData?.id}`);
                return;
            }

        }
        if (['edu'].includes(choosenModule)) {
            if (choosenFilter === 'streams') {
                navigation(`/${moduleName}/streams/${rData?.slug}`);
                return;
            }
            if (choosenFilter === 'types') {
                navigation(`/${moduleName}/types/${rData?.slug}`);
                return;
            }
            if (choosenFilter === 'states') {
                navigation(`/${moduleName}/states/${rData?.slug}`);
                return;
            }
            if (choosenFilter === 'cities') {
                navigation(`/${moduleName}/cities/${rData?.slug}`);
                return;
            }
            if (choosenFilter === 'localities') {
                navigation(`/${moduleName}/localities/${rData?.slug}`);
                return;
            }
            if (choosenFilter === 'courses') {
                navigation(`/${moduleName}/courses/${rData?.slug}`);
                return;
            }
            if (choosenFilter === 'exams') {
                navigation(`/${moduleName}/exams/${rData?.slug}`);
                return;
            }
            if (choosenFilter === 'bCourses') {
                navigation(`/${moduleName}/admissions/${rData?.slug}`);
                return;
            }
            if (choosenFilter === 'specs') {
                navigation(`/${moduleName}/specializations/${rData?.slug}`);
                return;
            }
            if (choosenFilter === 'educators') {
                navigation(`/${moduleName}/university/${rData?.slug}`);
                return;
            }

        }

    };
    return (<>
        <div className='d-flex align-items-center justify-content-between'>

            <nav aria-label="breadcrumb" className="pt-0 px-0 pb-0 breadcrumb-card">
                <ol className="breadcrumb px-0 pt-0 breadcrumb-main-mobilea" style={{ display: 'flex', flexWrap: 'nowrap' }}>
                    <li className={`breadcrumb-item capitalize rest-bread`}>
                        <NavLink to='/' style={{ fontSize: 14, textDecoration: 'none' }}>Home</NavLink>
                    </li>
                    <li
                        className={`breadcrumb-item capitalize active clamping ellipsis`}
                    >
                        {`Search Results in ${sModuleType?.label}`}
                    </li>
                </ol>
            </nav>
        </div>
        <div className='row'>
            <div className='col-md-12'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1">
                    <div className="d-md-flex align-items-center justify-content-between my-2 gap-10">
                        {/*  */}
                        <h6 className="text-black-50s mb-md-0 mb-4 capitalize my-0 label label-default w-100 py-2" dangerouslySetInnerHTML={{ __html: searchValue?.length ? `Search Result For <strong>'${searchValue || ''}'</strong> in ${sModuleType?.label} ${filterData}  : ${searchLoading ? 'Searching...' : `${totalCount(filterData)} Result Found`}` : `Start Searching in ${sModuleType?.label}` }}></h6>
                        <select className='form-control w-100' style={{ maxWidth: 240 }} onChange={(e) => handleFilterChange(e)} value={filterData}>
                            {
                                filterTypeData()?.map((fData, i) => <option key={i + 'ftype'} value={fData?.value}>{fData?.label}</option>)
                            }
                        </select>
                    </div>

                    <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-4" />
                    {searchLoading ? (
                        <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                            <div className="w-full flex items-center justify-center mb-20 text-themecolor min-h-[400px]" style={{ background: '#f6f8fa' }}>

                                <img src={searching} style={{ maxWidth: 240, opacity: .6 }} />
                            </div>
                        </div>) : (totalCount(filterData) <= 0) ? (<NotFound message={'No data found'}/>
                            ) : <div className='row'>
                        {
                            allSearchResults?.[filterData]?.data?.length ? (
                                <InfiniteScroll
                                    dataLength={allSearchResults?.[filterData]?.to || 0}
                                    next={() => loadMore()}
                                    hasMore={allSearchResults?.[filterData].current_page < allSearchResults?.[filterData]?.last_page}
                                    loader={scrollLoading && (
                                        <div className="w-full flex items-center justify-center mb-20 text-themecolor min-h-[400px] absolute top-0 left-0 bg-white h-full bg-opacity-50">
                                            <div className="h-10 w-10">
                                                <Loadingicon />
                                            </div>
                                        </div>
                                    )}
                                    pullDownToRefresh
                                    pullDownToRefreshThreshold={90}
                                    refreshFunction={() => {
                                        setPageNo(1);
                                        setAllSearchResults([]);
                                    }}
                                    scrollableTarget={"qBank"}
                                    className={"relative"}
                                >


                                    <>
                                        <h5 className='mb-3 capitalize'>  {sModuleType?.label} : {
                                            plularliseData(filterData)}
                                        </h5>
                                        <div className="py-4 px-3 gap-4 multi-card lg:mt-0 mb-0 scroll-container-custom" ref={scrollContainerRef} style={{ overflow: "auto" }} id="qBank">
                                            {
                                                allSearchResults?.[filterData]?.data?.map((d, i) => (
                                                    <div className='card theme-color-hover cursor-pointer' key={i} onClick={() => redirectionLinks(d)} >
                                                        <div className='card-body shadow mb-2 hover-div'>
                                                            <div>
                                                                <div className='d-flex items-center gap-3'>
                                                                    <div style={{ width: 32, height: 32, display: 'contents' }}>
                                                                        <img src={find} width={32} height={32} alt="subject" />
                                                                    </div>
                                                                    <h6 className='text-black mb-0'>
                                                                        {/* dangerouslySetInnerHTML={{ __html: d.subject_title || d.topic_name || d.question }} */}
                                                                        <div className='m-0 searched-title'
                                                                        >

                                                                            <MathJaxContext>
                                                                                <MathJax inline dynamic><div className='m-0 searched-title' dangerouslySetInnerHTML={{ __html: d.subject_title || d.topic_name || d.question || d.title || d?.name || d?.keyword || d?.company_name || d?.listingName }}></div></MathJax>
                                                                            </MathJaxContext>
                                                                        </div>
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </>


                                </InfiniteScroll>) : null
                        }


                    </div>
                    }

                </div>


            </div>
        </div>

    </>)
}

export default GlobalSearch;
