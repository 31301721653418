import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import { useAuth } from '../../../../Context/AuthContext';
import {
    convertDateFormat, nFormatter, slugToNormal, modulesUrls,
    ERROR_SOMETHING_WENT_WRONG,
    CDN_BASE_URL,
    fetchUserInfo,
    viewRecentVisits
} from '../../../../utils/utils';
import {
    tutorialCommentAndReply,
    likeUnlikeDislike,
    getTutorialChaptersList,
    getTutorialDetailByChapter,
    bookMarkTutorial,
    writeReviewTutorial,
    reportAgainstTutorial
} from '../../../../api/tutorial';
import Share from '../../../common/share';
import Modal from '../../../common/Modal';
import styles from "../../../../assets/css/styles/Home.module.css";
import { Loadingicon } from '../../../../AppIcons';
import { getTutorialDetails } from '../../../../api/tutorial';
import TutorialsComments from '../../../common/comments/tutorialsComments';
import TutorialChapters from '../chapters';
import * as Yup from "yup";
import StarRatings from 'react-star-ratings';
import { Formik } from 'formik';
import CircleLoader from '../../../common/loaders/circleLoader';
import NotFound from '../../../common/notfound';

function TutorialDetail() {
    const { globalToken, user, setUser, setFinance, tutorialToken, setIsEmailVerifyCover } = useAuth();
    const [loading, setLoading] = useState(true);
    const [chapterLoading, setChpaterLoading] = useState(true);
    const [upvoteBtnLoader, setUpvoteBtnLoader] = useState(false);
    const [downvoteBtnLoader, setDownvoteBtnLoader] = useState(false);
    const [isNotFound, setIsNotFound] = useState(false);
    const [loadingLoadMore, setLoadingLoadMore] = useState(false);
    const [tutorialDetailData, setTutorialDetailData] = useState([]);
    const [isLocationChange, setIsLocationChange] = useState(false);
    const [nextPrevLoader, setNextPrevLoader] = useState(false);
    const [tutorialId, setTutorialId] = useState();
    const [tutorialSlug, setTutorialSlug] = useState();
    const [isShareActive, setIsShareActive] = useState(false);
    const [sHideChapters, setSHideChapters] = useState(false);
    const navigation = useNavigate();
    const params = useParams();
    const location = useLocation();
    const currentPath = location.pathname;
    const [isLiked, setIsLiked] = useState(false);
    const [isFav, setIsFav] = useState(false);
    const [isDisliked, setIsDisliked] = useState(false);
    const [tutorialChapters, setTutorialChapters] = useState();
    const [introLoad, setIntroLoad] = useState(true);
    const [avgRating, setAvgRating] = useState(0);
    const [dynamicDesc, setDynamicDesc] = useState();
    const [dynamicTitle, setDynamicTitle] = useState();
    const [ratingLoading, setRatingLoading] = useState(false);
    const [reportLoading, setReportLoading] = useState(false);
    const [isOpenRatingModal, setIsOpenRatingModal] = useState(false);
    const [isOpenReportModal, setIsOpenReportModal] = useState(false);
    const [reportVal, setReportVal] = useState(0);
    const [ratingVal, setRatingVal] = useState(0);
    const shareRef = useRef(null);
    const [favBtnLoading, setFavBtnLoading] = useState(false);
    const iconShareRef = useRef(null);

    const DisplayingErrorMessagesSchemaRating = Yup.object().shape({
        rating: Yup.string().required("Rating is required"),
        reviews: Yup.string().required("Review is required")

    });
    const DisplayingErrorMessagesSchemaReport = Yup.object().shape({
        report: Yup.string().required("Report message is required")

    });




    useEffect(() => {
        if (params?.tutorialSlug) {
            const slugSplit = params.tutorialSlug.split('-');
            let tId = slugSplit.pop();

            if (isNaN(tId) || !tId) {
                setLoading(false);
                setIsNotFound(true);
                return;
            }
            setTutorialSlug(params.tutorialSlug);
            setTutorialId(tId);
            handleFetchTutorialDetail(tId);
            handleChaptersList(tId);

            if (params?.chapterSlug) {
                const chapterSlugSplit = params.chapterSlug.split('-');
                let cId = chapterSlugSplit.pop();
                if (isNaN(cId) || !cId) {
                    setLoading(false);
                    setIsNotFound(true);
                    return;
                }

                handleTutorialDetailByChapter(tId, cId);
            }
            viewRecentVisits(globalToken);
        }
    }, []);

    useEffect(() => {
        if (params?.tutorialSlug) {
            const slugSplit = params.tutorialSlug.split('-');
            let tId = slugSplit.pop();

            if (isNaN(tId) || !tId) {
                setLoading(false);
                setIsNotFound(true);
                return;
            }
            setTutorialSlug(params.tutorialSlug);
            setTutorialId(tId);
            handleFetchTutorialDetail(tId);
            handleChaptersList(tId);


            if (params?.chapterSlug) {
                const chapterSlugSplit = params.chapterSlug.split('-');
                let cId = chapterSlugSplit.pop();
                if (isNaN(cId) || !cId) {
                    setLoading(false);
                    setIsNotFound(true);
                    return;
                }

                handleTutorialDetailByChapter(tId, cId);
            }
        }
    }, [isLocationChange]);


    const handleFetchTutorialDetail = async (tId) => {
        if (!params?.chapterSlug) {
            setIntroLoad(true);
        }
        const resTutorialsDetail = await getTutorialDetails({ token: globalToken, tutorialId: tId });

        setIsLiked(resTutorialsDetail?.data?.is_like);
        setIsDisliked(resTutorialsDetail?.data?.is_dislike);
        setIsFav(resTutorialsDetail?.data?.is_favourite);
        setAvgRating(resTutorialsDetail?.data?.avg_rating);
        setLoading(false);
        setLoadingLoadMore(false);
        if (resTutorialsDetail?.success) {
            setIsNotFound(false);
            if (resTutorialsDetail?.data?.tutorial) {
                setTutorialDetailData(resTutorialsDetail?.data?.tutorial);
            } else {
                setTutorialDetailData({});
                setIsNotFound(true);
            }
        } else {
        }
        setUpvoteBtnLoader(false);
        setDownvoteBtnLoader(false);
        setFavBtnLoading(false);
    }

    const openHideRatingModal = () => {
        if (isOpenRatingModal) {
            setRatingVal(0)
        }
        setIsOpenRatingModal(!isOpenRatingModal);
    }

    const openHideReportModal = () => {
        setIsOpenReportModal(!isOpenReportModal);
    }

    const changeRating = (newRating) => {
        setRatingVal(newRating);
    };
    const handleReviewRating = async (values) => {
        setRatingLoading(true);
        const resReviewRating = await writeReviewTutorial({ token: globalToken, tutorialId: tutorialDetailData?.id, reviews: values?.reviews, rating: values?.rating });
        // alert(JSON.stringify(resReviewRating))
        // console.log(resReviewRating?.id)
        if (resReviewRating?.success) {
            handleFetchTutorialDetail(tutorialDetailData?.id);
            toast.success('Review & Rating given');
            openHideRatingModal(false);
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }
        setRatingLoading(false);
    }

    const handleReport = async (values) => {
        setReportLoading(true);
        const resReviewRating = await reportAgainstTutorial({ token: globalToken, tutorialId: tutorialDetailData?.id, report: values?.report });
        // alert(JSON.stringify(resReviewRating))
        if (resReviewRating?.success) {
            handleFetchTutorialDetail(tutorialDetailData?.id);

            toast.success('Report registered');
            openHideReportModal(false);
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }
        setReportLoading(false);
    }


    // Handles adding/removing lyrics from favorites
    const FavouriteButton = ({ is_fav_camp }) => {


        const handleStartTask = async () => {
            setFavBtnLoading(true);
            const token = globalToken;
            const res = await bookMarkTutorial({ token: token, tutorialId: tutorialDetailData?.id, favourite: is_fav_camp ? 'unfav' : 'fav' });
            if (res?.success) {
                handleFetchTutorialDetail(tutorialDetailData?.id);
            }

        }

        return (
            <button
                type="button"
                disabled={favBtnLoading}
                onClick={handleStartTask}
                className="btn bg-white p-0 d-flex btn-like-circular"
                style={{ border: 'none', boxShadow: 'none' }}
            >
                {favBtnLoading ? (
                    <div className="h-5 w-5">
                        <Loadingicon />
                    </div>
                ) : (
                    <>
                        {is_fav_camp ? <i className="fa fa-heart heart active"></i> : <i className="fa heart fa-heart"></i>}
                    </>
                )}
            </button>
        );
    }

    // Handles sharing the tutorial
    const InlineShare = ({ tutorialsData, isShareActive, setIsShareActive }) => {
        const handleShareClick = () => {
            setIsShareActive(!isShareActive);
        };
        return (
            <small className="position-relative" title="Share">
                {isShareActive ? (
                    <span ref={shareRef}><Share
                        shareContent={{
                            url: `${modulesUrls?.tuteehubTutorial}/detail/${tutorialsData?.slug}`,
                            quote: `${tutorialsData?.description}`,
                            hashing: `#tuteehub`,
                            title: `${tutorialsData?.title}`,
                            id: tutorialsData?.id,
                        }}
                        isCustomPos={true}
                        isShare={isShareActive}
                        setIsShare={setIsShareActive}
                        iconShareRef={iconShareRef}
                        shareRef={shareRef}
                        refetchDetails={handleFetchTutorialDetail}
                    /></span>
                ) : null}
                <span
                    ref={iconShareRef}
                    className={`${styles.icons} ${styles.shareIco}`}
                    style={{ display: 'inline-block' }}
                    role="button"
                    onClick={handleShareClick}
                ></span>
            </small>
        );
    }

    // Handles upvoting or downvoting the lyrics
    const handleLikeUnlikeDislike = async (voteType, type) => {
        if (type === 'dLike') setDownvoteBtnLoader(true);
        else setUpvoteBtnLoader(true);
        const resLikeUnlike = await likeUnlikeDislike({ token: globalToken, tutorialId: tutorialDetailData.id, voteType });
        if (resLikeUnlike?.success) {
            fetchUserInfo(user?.tuteehub_id, globalToken, setUser, setFinance, user);
            handleFetchTutorialDetail(tutorialDetailData.id);
            toast.success(resLikeUnlike?.data?.message);
        }
    }

    // Handles submitting comments and replies
    const handleSubmitComment = async (commentId, setComment, comment, isReply, commentLoader, setIsReplyShow) => {
        if (user?.email_verified == 0) {
            toast.error('Please verify your email');
            setTimeout(() => { setIsEmailVerifyCover(true) }, 200);
            return;
        }
        commentLoader(true);
        try {
            const resCommentCommit = await tutorialCommentAndReply({
                token: globalToken,
                tutorialId: tutorialDetailData?.id,
                commentId: isReply ? commentId : 0,
                comment,
            });
            if (resCommentCommit?.success) {
                fetchUserInfo(user?.tuteehub_id, globalToken, setUser, setFinance, user);
                setIsReplyShow(false);
                toast.success(resCommentCommit?.message || resCommentCommit?.data?.message);
                handleFetchTutorialDetail(tutorialDetailData?.id);
            }
            setComment('');
        } catch (error) {
            toast.error('Error sending comment');
        } finally {
            commentLoader(false);
        }
    }

    // Modifies anchor tags to open in a new tab
    const modifyAnchorTags = (htmlContent) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        const anchorTags = doc.querySelectorAll('a');
        anchorTags.forEach(tag => {
            tag.setAttribute('target', '_blank');
        });
        return doc.documentElement.innerHTML;
    };



    // get all chapters list
    const handleChaptersList = async (tId) => {
        const resChaptersList = await getTutorialChaptersList({ token: globalToken, tutorialId: tId })
        if (resChaptersList?.success) {
            setTutorialChapters(resChaptersList?.data.reverse())
        }
    }

    // get detail by chapter
    const handleTutorialDetailByChapter = async (tId, cId) => {
        setChpaterLoading(true);
        setIntroLoad(false);
        const resDetailByChapter = await getTutorialDetailByChapter({ token: globalToken, tutorialId: tId, chapterId: cId })
        if (resDetailByChapter?.success) {
            setDynamicDesc(resDetailByChapter?.data?.description);
            setDynamicTitle(resDetailByChapter?.data?.title);
        }
        setLoading(false);
        setChpaterLoading(false);
    }


    const showHideChapters = () => {
        setSHideChapters(!sHideChapters);
    }

    return (
        <>
            {loading ? (
                <CircleLoader width={10} height={10} />
            ) : (
                <>
                    <div className='d-md-flex justify-content-between mb-4 align-items-md-center'>
                        <nav aria-label="breadcrumb" className="pt-0 px-0 pb-0 breadcrumb-card">
                            <ol className="breadcrumb px-0 pt-0 breadcrumb-main-mobilea mb-0" style={{ display: 'flex', flexWrap: 'nowrap' }}>
                                <li className={`breadcrumb-item capitalize rest-bread`}>
                                    <NavLink to='/' style={{ fontSize: 14, textDecoration: 'none' }}>Home</NavLink>
                                </li>
                                <li className={`breadcrumb-item capitalize rest-bread`}>
                                    <NavLink to='/tutorials' style={{ fontSize: 14, textDecoration: 'none' }}>Tutorials</NavLink>
                                </li>
                                {
                                    tutorialDetailData?.tag_list ? <li className={`breadcrumb-item capitalize rest-bread`}>
                                        <NavLink to={`/tutorials/${tutorialDetailData?.tag_list[0]?.slug}`} style={{ fontSize: 14, textDecoration: 'none' }}>{tutorialDetailData?.tag_list[0]?.name}</NavLink>
                                    </li> : null
                                }

                                <li
                                    className={`breadcrumb-item capitalize active clamping ellipsis`}
                                >

                                    {slugToNormal(params.tutorialSlug).length > 60
                                        ? slugToNormal(params.tutorialSlug).slice(0, 60) + '...'
                                        : slugToNormal(params.tutorialSlug)}
                                </li>
                            </ol>
                        </nav>
                        <div className='btn-group question-box question-box-card mw-220 d-flex justify-content-end'>
                            <button
                                className="btn-soft mr-2 cursor-pointer hover:bg-themecolor text-white" style={{ backgroundColor: '#ff7200' }}
                                onClick={() => navigation('/tutorials/my-tutorials')}
                            >
                                My Tutorials
                            </button>
                            <button
                                className="btn-soft cursor-pointer hover:bg-themecolor text-white" style={{ backgroundColor: '#ff7200' }}
                                onClick={() => navigation('/tutorials/post-a-tutorial')}
                            >
                                Post A Tutorial
                            </button>
                            <button
                                className="btn-soft d-md-none d-inline-block cursor-pointer hover:bg-themecolor" style={{ backgroundColor: '#f0f0f0', color: '#ff7200' }}
                                onClick={() => showHideChapters()}
                            >
                                Chapters
                            </button>
                        </div>
                    </div>
                    {
                        isNotFound ?
                            <div className="py-4 px-md-4 px-2 grid grid-cols-1 group-card card-body gap-4">
                                <NotFound/></div> : <div className="row">
                                <div className={`${tutorialChapters?.length ? "col-md-9" : "col-md-12"}`}>
                                    <div className="py-4 px-md-4 px-2 grid grid-cols-1 group-card card-body gap-4">
                                        <div className="px-0">
                                            <div className="flex-column d-flex flex-md-row justify-content-between">

                                                <div className="mr-md-5 pr-md-5 flex-1">
                                                    <h1 className="title-job d-flex align-items-center justify-content-between text-capitalize">
                                                        <strong>{tutorialDetailData?.title}</strong>
                                                        <span className="d-flex gap-3 align-items-center">
                                                            <FavouriteButton is_fav_camp={isFav} />
                                                            <InlineShare tutorialsData={tutorialDetailData} isShareActive={isShareActive} setIsShareActive={setIsShareActive} />
                                                        </span>
                                                    </h1>
                                                    <div className="d-flex mb-4 flex-md-row flex-column justify-content-between align-md-items-center">

                                                        <span className="text-black-50 gig-table items-center d-md-flex">
                                                            <small style={{ marginTop: 1, float: 'left' }}>
                                                                <StarRatings
                                                                    rating={avgRating || 0}
                                                                    starRatedColor="#F08645"
                                                                    changeRating={null}
                                                                    numberOfStars={5}
                                                                    starDimension="15px"
                                                                    starSpacing="0"
                                                                    name="rating"
                                                                /></small>

                                                            <span className="big-dots"></span>
                                                            <span>{nFormatter(tutorialDetailData?.views || 0)} views</span>
                                                            <span className="big-dots"></span>
                                                            <span>{tutorialDetailData?.comments_count || 0} comments</span>
                                                            <span className="big-dots"></span>
                                                            <span>{tutorialDetailData?.likes_count || 0} up votes</span>
                                                            {
                                                                tutorialToken?.id !== tutorialDetailData?.user_id ? <>
                                                                    {
                                                                        upvoteBtnLoader ? <span className="custom-loader-small ms-2 theme-color">
                                                                            <Loadingicon />
                                                                        </span> : <><i
                                                                            className={`fas fa-thumbs-up cursor-pointer ms-2 ${isLiked && "text-themecolor"
                                                                                }`}
                                                                            onClick={() => {
                                                                                if (user?.email_verified == 1) {
                                                                                    handleLikeUnlikeDislike(isLiked ? "unlike" : "like", 'lke')
                                                                                } else {
                                                                                    toast.error('Please verify your email');
                                                                                    setTimeout(() => { setIsEmailVerifyCover(true) }, 200)
                                                                                }
                                                                            }
                                                                            }
                                                                        ></i>{" "}</>
                                                                    }</> : null
                                                            }
                                                            <span className="big-dots"></span>
                                                            <span>{tutorialDetailData?.dislikes_count || 0} down votes</span>
                                                            {
                                                                tutorialToken?.id !== tutorialDetailData?.user_id ? <>
                                                                    {
                                                                        downvoteBtnLoader ? <span className="custom-loader-small ms-2 theme-color">
                                                                            <Loadingicon />
                                                                        </span> : <><i
                                                                            className={`fas fa-thumbs-down cursor-pointer ms-2 ${isDisliked && "text-themecolor"
                                                                                }`}
                                                                            onClick={() => {
                                                                                if (user?.email_verified == 1) {
                                                                                    handleLikeUnlikeDislike(isDisliked ? "rmdislike" : "dislike", 'dLike')
                                                                                } else {
                                                                                    toast.error('Please verify your email');
                                                                                    setTimeout(() => { setIsEmailVerifyCover(true) }, 200)
                                                                                }
                                                                            }

                                                                            }
                                                                        ></i>{" "}</>
                                                                    }</> : null
                                                            }
                                                            <span className="big-dots"></span>
                                                            <span>{nFormatter(tutorialDetailData?.share_count)} shares</span>
                                                            <span className='big-dots'></span>
                                                            <span>{convertDateFormat(tutorialDetailData?.posting_date || tutorialDetailData?.created_at)}</span>
                                                        </span>


                                                    </div>
                                                    {
                                                        tutorialToken?.id !== tutorialDetailData?.user_id ? <div className="col-span-12 px-0 question-box question-box-card py-2 mt-3 mb-3 mb-1">

                                                            <button
                                                                className="btn-soft mr-2 cursor-pointer hover:bg-themecolor hover:text-white"
                                                                onClick={() => {
                                                                    if (user?.email_verified == 1) {
                                                                        openHideRatingModal()
                                                                    } else {
                                                                        toast.error('Please verify your email');
                                                                        setTimeout(() => { setIsEmailVerifyCover(true) }, 200)
                                                                    }
                                                                }}
                                                            >
                                                                Write A Review
                                                            </button>
                                                            <button
                                                                className="btn-soft mr-2 cursor-pointer hover:bg-themecolor hover:text-white"
                                                                onClick={() => {
                                                                    if (user?.email_verified == 1) {
                                                                        openHideReportModal()
                                                                    } else {
                                                                        toast.error('Please verify your email');
                                                                        setTimeout(() => { setIsEmailVerifyCover(true) }, 200)
                                                                    }
                                                                }}
                                                            >
                                                                Report
                                                            </button>

                                                        </div> : null
                                                    }

                                                    <hr className="heading-hrs mb-0 opacity-5" />
                                                    <div className="thumbnail-containers rounded w-100">                                            {
                                                        tutorialDetailData?.image ? <img src={CDN_BASE_URL + tutorialDetailData?.image} className="rounded lazyload w-100 h-auto" alt='thumbnail-containers' /> : null
                                                    }

                                                    </div>

                                                    {
                                                        introLoad ? <>
                                                            <div className='chapter-head-container mt-5'>
                                                                <h5 className='m-0'><strong><i className='fa fa-user me-2'></i> About the Author</strong></h5>
                                                                <div className="bottom-line-container position-relative">
                                                                    <hr className='bottom-line-gray mt-2 mb-2' />
                                                                    <span className='bottom-line-orange'></span>
                                                                </div>
                                                            </div>
                                                            <div className="w-100 text-black-60 mt-4 leads cn-desc" dangerouslySetInnerHTML={{
                                                                __html: modifyAnchorTags(`${tutorialDetailData?.about?.replace(/<p>&nbsp;<\/p>/g, '').trim()}`)
                                                            }}></div>
                                                            <div className='chapter-head-container mt-4'>
                                                                <h5 className='m-0'><strong><i className='fa fa-book me-2'></i> About the Tutorial</strong></h5>
                                                                <div className="bottom-line-container position-relative">
                                                                    <hr className='bottom-line-gray mt-2 mb-2' />
                                                                    <span className='bottom-line-orange'></span>
                                                                </div>
                                                            </div>
                                                            <div className="w-100 text-black-60 mt-4 leads cn-desc" dangerouslySetInnerHTML={{
                                                                __html: modifyAnchorTags(`${tutorialDetailData?.description?.replace(/<p>&nbsp;<\/p>/g, '').trim()}`)
                                                            }}></div></> : !chapterLoading ? <>
                                                                <div className='chapter-head-container mt-4'>
                                                                    <h5 className='m-0'><strong>{dynamicTitle}</strong></h5>
                                                                    <div className="bottom-line-container position-relative">
                                                                        <hr className='bottom-line-gray mt-2 mb-2' />
                                                                        <span className='bottom-line-orange'></span>
                                                                    </div>
                                                                </div>
                                                                <div className="w-100 text-black-60 mt-4 leads cn-desc" dangerouslySetInnerHTML={{
                                                                    __html: `${dynamicDesc}`
                                                                }}></div>
                                                            </> : <CircleLoader width={10} height={10} />
                                                    }


                                                </div>

                                            </div>


                                        </div>
                                    </div>

                                    {
                                        (tutorialDetailData?.faq) ? <div className="py-4 px-md-4 px-2 grid grid-cols-1 group-card card-body mt-4">
                                            <div className="heading-main position-relative my-3 overflow-hidden">
                                                <h5 className="text-left bg-white pr-3 b"><strong>FAQ</strong></h5>
                                                <hr className="heading-hr mb-0" />
                                            </div>
                                            {
                                                JSON.parse(tutorialDetailData?.faq)?.map((fq, i) => (<div key={i + 'faq'} className='faq-item'>
                                                    <h6><strong>{fq?.question}</strong></h6>
                                                    <p dangerouslySetInnerHTML={{ __html: fq?.answer }}></p>
                                                    <hr style={{ borderColor: '#ccc' }} />
                                                </div>))
                                            }
                                        </div> : null
                                    }

                                    <div className="flex justify-between mt-4">
                                        <button id="nextprevbtn"
                                            className={` text-white px-5 py-3 bg-opacity-100 rounded disabled:bg-gray-200 font-medium disabled:text-black`}
                                            style={{ backgroundColor: !tutorialDetailData?.prevUrl ? '#ccc' : '#ff7200' }}
                                            disabled={tutorialDetailData?.prevUrl ? false : true}
                                            onClick={() => { navigation(`/tutorials/detail/${tutorialDetailData?.prevUrl?.slug}-${tutorialDetailData?.prevUrl?.id}`); setIsLocationChange(!isLocationChange); viewRecentVisits(globalToken); }}
                                        >
                                            {
                                                nextPrevLoader ? <div className="h-5 w-5 mr-2">
                                                    <Loadingicon />
                                                </div> : <>Previous</>
                                            }

                                        </button>
                                        <button id="nextprevbtn"
                                            className={` text-white px-5 py-3 bg-opacity-100 rounded disabled:bg-gray-200 font-medium disabled:text-black`}
                                            style={{ backgroundColor: !tutorialDetailData?.nextUrl ? '#ccc' : '#ff7200' }}
                                            disabled={tutorialDetailData?.nextUrl ? false : true}
                                            onClick={() => { navigation(`/tutorials/detail/${tutorialDetailData?.nextUrl?.slug}-${tutorialDetailData?.nextUrl?.id}`); setIsLocationChange(!isLocationChange); viewRecentVisits(globalToken); }}
                                        >
                                            {
                                                nextPrevLoader ? <div className="h-5 w-5 mr-2">
                                                    <Loadingicon />
                                                </div> : <>Next</>
                                            }
                                        </button>
                                    </div>
                                    {
                                        tutorialDetailData?.tag_list?.length > 0 &&
                                        <div className="py-4 px-md-4 px-2 grid grid-cols-1 group-card card-body gap-4 mt-4">
                                            <div className="heading-main position-relative my-3 overflow-hidden">
                                                <h5 className="text-left bg-white pr-3 b"><strong>Related Searches</strong></h5>
                                                <hr className="heading-hr mb-0" />
                                                <div className='tags-container mt-3'>
                                                    {
                                                        tutorialDetailData?.tag_list.map((tag, i) => (
                                                            <span key={'tTag' + i} onClick={() => navigation(`/tutorials/${tag?.slug}`)} className='tag-item py-2 px-3 cursor-pointer text-theme-color'>{tag?.name}</span>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <div className="py-4 px-md-4 px-2 grid grid-cols-1 group-card card-body gap-4 mt-4">
                                        <div className="heading-main position-relative my-3 overflow-hidden">
                                            <h5 className="text-left bg-white pr-3 b"><strong>Comments({tutorialDetailData?.comments_count})</strong></h5>
                                            <hr className="heading-hr mb-0" />
                                        </div>
                                        <TutorialsComments cData={tutorialDetailData} handleSubmitComment={handleSubmitComment} />
                                    </div>

                                </div>
                                {
                                    tutorialChapters?.length ? <div className="col">
                                        <div className={` ${sHideChapters ? 'show-chapters-mob' : 'position-sticky c-menu'}`} style={{ top: 80 }}>
                                            <div className="py-4 px-md-4 px-2 grid grid-cols-1 group-card card-body gap-1">

                                                <TutorialChapters chaptersList={tutorialChapters} introLoad={introLoad} tutorialSlug={params?.tutorialSlug} tutorialId={tutorialId} handleFetchTutorialDetail={handleFetchTutorialDetail} handleTutorialDetailByChapter={handleTutorialDetailByChapter} showArrow={true} title={'Chapters'} />
                                            </div>

                                        </div>
                                    </div> : null
                                }

                            </div>

                    }

                </>
            )}


            <Modal
                closeModal={openHideRatingModal}
                setIsOpen={setIsOpenRatingModal}
                isOpen={isOpenRatingModal}
                callBack={openHideRatingModal}
                title={'Write A Review'}
                modalFooter={true}
            >
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        reviews: null,
                        rating: null
                    }}
                    validationSchema={DisplayingErrorMessagesSchemaRating}
                    onSubmit={(values) => {
                        if (values) {
                            handleReviewRating(values);
                        }
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        handleSubmit,
                    }) => (
                        <form className="" onSubmit={handleSubmit}>

                            <div className="mb-2">
                                <textarea
                                    disabled={false}
                                    type={'text'}
                                    name={'reviews'}
                                    autoComplete={'off'}
                                    id={'reviews'}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder={'Write A Review'}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    rows={5}

                                // value={}
                                ></textarea>
                                <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                    {touched['reviews'] && errors['reviews'] && errors['reviews']}
                                </div>
                            </div>
                            <div className="mb-3 text-center">

                                <StarRatings
                                    rating={ratingVal}
                                    starRatedColor="#F08645"
                                    starHoverColor="rgb(253 160 104)"
                                    changeRating={(newRating) => { changeRating(newRating); setFieldValue('rating', newRating) }}
                                    numberOfStars={5}
                                    starDimension="35px"
                                    starSpacing="5px"
                                    name="rating"
                                />
                                <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                    {touched['rating'] && errors['rating'] && errors['rating']}
                                </div>
                            </div>
                            <div className="footer-bottom-modal w-100 d-flex align-items-center justify-content-end mt-0">

                                <button
                                    type="submit"
                                    disabled={ratingLoading}
                                    className="bg-themecolor text-white border py-2 px-8 mr-0 rounded-lg border-themecolor text-themecolor text-lg disabled:opacity-50 w-[119px] flex justify-center items-center"


                                >
                                    {ratingLoading ? (
                                        <div className="h-5 w-5">
                                            <Loadingicon />
                                        </div>
                                    ) : 'Submit'
                                    }
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>

            </Modal>


            {/* modal for report */}


            <Modal
                closeModal={openHideReportModal}
                setIsOpen={setIsOpenReportModal}
                isOpen={isOpenReportModal}
                callBack={openHideReportModal}
                title={'Report Against Company'}
                modalFooter={true}
            >
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        report: null
                    }}
                    validationSchema={DisplayingErrorMessagesSchemaReport}
                    onSubmit={(values) => {
                        if (values) {
                            handleReport(values);
                        }
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        handleSubmit,
                    }) => (
                        <form className="" onSubmit={handleSubmit}>

                            <div className="mb-2">
                                <textarea
                                    disabled={false}
                                    type={'text'}
                                    name={'report'}
                                    autoComplete={'off'}
                                    id={'report'}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder={'Write message'}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    rows={5}
                                ></textarea>
                                <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                    {touched['report'] && errors['report'] && errors['report']}
                                </div>
                            </div>

                            <div className="footer-bottom-modal w-100 d-flex align-items-center justify-content-end mt-0">

                                <button
                                    type="submit"
                                    disabled={reportLoading}
                                    className="bg-themecolor text-white border py-2 px-8 mr-3 rounded-lg border-themecolor text-themecolor text-lg disabled:opacity-50 w-[119px] flex justify-center items-center"
                                >
                                    {reportLoading ? (
                                        <div className="h-5 w-5">
                                            <Loadingicon />
                                        </div>
                                    ) : 'Submit'
                                    }
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>

            </Modal>
        </>
    );
}

export default TutorialDetail;
