import React from 'react';
import { Loadingicon } from '../../../AppIcons';

const CircleLoader = ({ width, height }) => {
  return (
    <div className="w-full flex items-center justify-center py-4 text-themecolor min-h-[400px] group-card card-body">
        <div className={`h-${height} w-${width} theme-color`}>
            <Loadingicon />
        </div>
    </div>
  );
};

export default CircleLoader;
