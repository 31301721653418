import React, { forwardRef, useEffect, useState } from 'react';
import Question from './Question';
import Navigation from './Navigation';
import Controls from './Controls';
import './ExamPage.css';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ERROR_SOMETHING_WENT_WRONG } from '../../../../utils/utils';
import { toast } from 'react-toastify';
import { getExamData, submitExam } from '../../../../api/exam';
import { useAuth } from '../../../../Context/AuthContext';
import CustomModal from './CustomModal';
import Modal from '../../../common/Modal';

const ExamPage = () => {
  const { globalToken } = useAuth();
  const [questions, setQuestions] = useState([]);
  const [btnLoader, setBtnLoader] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [answers, setAnswers] = useState([]); // To store answers
  const [status, setStatus] = useState([]); // To store question statuses
  const [title, setTitle] = useState(""); 
  const [slug, setSlug] = useState(""); 
  const [examId, setExamId] = useState(); 
  const [timeRemaining, setTimeRemaining] = useState(0); // 10 minutes timer (600 seconds)
  const [isFullscreen, setIsFullscreen] = useState(false); // Track fullscreen state
  const location = useLocation();
  const navigation = useNavigate();
  const { ref } = location.state || {};
  const params = useParams();


   const [isOpenSubmitTestModal, setIsOpenSubmitTestModal] = useState(false);


    const openHideSubmitTestModal = () =>{
      // alert(isOpenSubmitTestModal)
      setIsOpenSubmitTestModal(!isOpenSubmitTestModal);
    }
    // useEffect(()=>{
    //   openHideSubmitTestModal()
    // },[])


  const handleOptionChange = (optionIndex) => {
    const updatedAnswers = [...answers];
    updatedAnswers[currentQuestionIndex] = optionIndex;
    setAnswers(updatedAnswers);

    const updatedStatus = [...status];
    updatedStatus[currentQuestionIndex] = 'answered';
    setStatus(updatedStatus);
  };

  const handleSaveAndNext = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex((prev) => prev + 1);
    }
  };

  const handleClearResponse = () => {
    const updatedAnswers = [...answers];
    updatedAnswers[currentQuestionIndex] = null;
    setAnswers(updatedAnswers);

    const updatedStatus = [...status];
    updatedStatus[currentQuestionIndex] = 'not_answered';
    setStatus(updatedStatus);
  };

  const handleMarkForReview = () => {
    const updatedStatus = [...status];
    updatedStatus[currentQuestionIndex] = 'marked';
    setStatus(updatedStatus);
    handleSaveAndNext();
  };

  const handleSubmitTest = () => {
    console.log('hi')
    const answeredCount = answers.filter((answer) => answer !== null).length;
    const notAnsweredCount = questions.length - answeredCount;
    setIsOpenSubmitTestModal(true);
    // setIsModalOpen(true); // Open the modal
  };

  const handleModalSubmit = async () => {
    setBtnLoader(true);
    const answersPayload = questions.reduce((result, question, index) => {
      if (answers[index] !== null) {
        result[question.ques_id] = answers[index] + 1;
        result[`time_spent[${question.ques_id}]`] = 0;
      }
      return result;
    }, {});

    // Submit answers to the backend
    const response = await submitExam({
      token: globalToken,
      answersPayload,
      examSlug: params?.examSlug,
    });

    if (response.success) {          
      toast.success('Test submitted successfully!');  
      navigation(`/exams/result/${examId}`);
      // navigation(`/exams/topic/${slug}`);
    } else {
      toast.error('Failed to submit the test.');
    }
    setIsModalOpen(false); // Close the modal
    setBtnLoader(false);
  };

  // Handle modal close (cancel submit)
  const handleModalClose = () => {
    setIsModalOpen(false);
  };


  const fetchQuestions = async (examId) => {
    const response = await getExamData({token : globalToken, examId}); // Replace with your API endpoint
    if (response?.success){
      const questionsData = response?.data?.quizz.map((item, index) => ({
        id: index + 1,
        ques_id: item.questionbank?.id,
        text: item.questionbank?.question,
        options: item.questionbank?.answers,
        marks: item.questionbank?.marks
      }));
      setQuestions(questionsData);
      setTitle(response?.data?.exam?.title);
      setSlug(response?.data?.topic?.slug);
      setTimeRemaining(response?.data?.quizz?.time_to_spend || 1800);
      setAnswers(Array(response?.data?.quizz?.length).fill(null)); // Initialize answers array
      setStatus(Array(response?.data?.quizz?.length).fill('not_visited')); // Initialize status array
    }   else  {
      toast.error(ERROR_SOMETHING_WENT_WRONG)
    }
  };

  useEffect(() => {
    if (params?.examSlug) {
      console.log(params?.examSlug)
      const examId = (params?.examSlug).split('-').pop();
      setExamId(examId);
      fetchQuestions(examId);
    }
    const timer = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer);
          handleSubmit(); 
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    const handleBeforeUnload = (event) => {
        event.preventDefault();
        // Set a custom message (ignored by most modern browsers, but still required for older ones)
        event.returnValue = "You are about to leave this page. Your exam session will be lost.";
        return event.returnValue; // Necessary for some browsers
    };

  

// Handle back/forward navigation
const handlePopState = () => {
  const confirmationMessage = "You are about to leave this page. Your exam session will be lost.";
  const userConfirmed = window.confirm(confirmationMessage);
  if (!userConfirmed) {
      // Prevent navigation by pushing the current state back
      window.history.pushState(null, "", window.location.href);
  }
};

// Add event listeners
window.addEventListener("beforeunload", handleBeforeUnload);
window.addEventListener("popstate", handlePopState);

// Push an initial state to the history stack
window.history.pushState(null, "", window.location.href);

return () => {
  clearInterval(timer);
  // Cleanup event listeners
  window.removeEventListener("beforeunload", handleBeforeUnload);
  window.removeEventListener("popstate", handlePopState);
};
}, []);
  
  
//   useEffect(() => {
//     if (params?.examSlug) {
//         console.log(params?.examSlug);
//         const examId = params.examSlug.split('-').pop();
//         setExamId(examId);
//         fetchQuestions(examId);
//     }

//     // Timer logic
//     const timer = setInterval(() => {
//         setTimeRemaining((prevTime) => {
//             if (prevTime <= 1) {
//                 clearInterval(timer); // Clear the interval
//                 handleSubmit(); // Automatically submit when time runs out
//                 return 0;
//             }
//             return prevTime - 1;
//         });
//     }, 1000);

//     // Cleanup function
//     return () => {
//         clearInterval(timer); // Clean up the interval on component unmount
//     };
// }, [params?.examSlug]);

  // useEffect(() => {
  //   // Detect fullscreen mode change
  //   const handleFullscreenChange = () => {
  //     if (
  //       document.fullscreenElement ||
  //       document.webkitFullscreenElement ||
  //       document.mozFullScreenElement ||
  //       document.msFullscreenElement
  //     ) {
  //       setIsFullscreen(true);
  //     } else {
  //       setIsFullscreen(false);
  //     }
  //   };

  //   document.addEventListener('fullscreenchange', handleFullscreenChange);
  //   document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
  //   document.addEventListener('mozfullscreenchange', handleFullscreenChange);
  //   document.addEventListener('msfullscreenchange', handleFullscreenChange);

  //   return () => {
  //     document.removeEventListener('fullscreenchange', handleFullscreenChange);
  //     document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
  //     document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
  //     document.removeEventListener('msfullscreenchange', handleFullscreenChange);
  //   };
  // }, []);


  // useEffect(() => {
  //   if (ref.current) {
  //     if (ref.current.requestFullscreen) {
  //       ref.current.requestFullscreen();
  //     } else if (ref.current.mozRequestFullScreen) {
  //       ref.current.mozRequestFullScreen();
  //     } else if (ref.current.webkitRequestFullscreen) {
  //       ref.current.webkitRequestFullscreen();
  //     } else if (ref.current.msRequestFullscreen) {
  //       ref.current.msRequestFullscreen();
  //     }
  //   }  
  // }, [ref]);

  // Function to format the time as MM:SS
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  // Handle test submission
  const handleSubmit = () => {
    alert("Time's up! Submitting the test.");
    // Logic to submit the answers, like sending them to an API or showing a summary
    handleSubmitTest();
    // ...
  };

  return (
    <div ref={ref} style={{backgroundColor: 'white', display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <header className="exam-header" style={{ flexShrink: 0 }}>
        <h1>{title}</h1> {/* Displaying the exam title passed from state */}
      </header>

      <div style={{ display: 'flex', flex: 1 }}>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <div className="exam-info-section">
            <div className="info-item">
              <strong>Time :</strong> {formatTime(timeRemaining)}
            </div>
            <div className="info-item">
    <strong>Question :</strong> {currentQuestionIndex + 1}/{questions?.length}
  </div>
          </div>
          <div className="exam-page">
            <Question 
              question={questions[currentQuestionIndex]} 
              onOptionChange={handleOptionChange} 
              selectedOption={answers[currentQuestionIndex]} 
            />
            <Controls 
              onSaveAndNext={handleSaveAndNext} 
              onClearResponse={handleClearResponse} 
              onMarkForReview={handleMarkForReview} 
              isFullscreen = {isFullscreen}
              isLastQ={questions?.length === currentQuestionIndex + 1}
              
            />
            
          </div>
        </div>
        
        <Navigation 
          questions={questions} 
          setCurrentQuestionIndex={setCurrentQuestionIndex} 
          status={status}
          setStatus={setStatus}
          style={{ minHeight: '100vh', flexShrink: 0 }}
          onSubmitTest={handleSubmitTest}
          isFullscreen = {isFullscreen}
        />


      <Modal
        closeModal={openHideSubmitTestModal}
        setIsOpen={setIsOpenSubmitTestModal}
        isOpen={isOpenSubmitTestModal}
        callBack={handleModalSubmit}
        title={'Submit Test'}
        modalFooter={false}
        loading={btnLoader}
        disabled={btnLoader}
      >
                 
            <div className='test-info w-100 d-flex items-center justify-between mb-2'>
              Total Questions <strong>{questions.length}</strong>
            </div>
            <div className='test-info w-100 d-flex items-center justify-between mb-2'>
              Answered <strong>{answers.filter((answer) => answer !== null).length}</strong>
            </div>   
            <div className='test-info w-100 d-flex items-center justify-between mb-2'>
              Not Answered <strong>{questions.length - answers.filter((answer) => answer !== null).length}</strong>
            </div> 
            <hr style={{borderColor:'#f0f0f0'}}/>   
    </Modal>
        {/* <CustomModal
        // isOpen={isModalOpen}
        // onClose={handleModalClose}
        // onSubmit={handleModalSubmit}
        openHideSubmitTestModal={openHideSubmitTestModal}
        isOpenSubmitTestModal={isOpenSubmitTestModal}
        setIsOpenSubmitTestModal={setIsOpenSubmitTestModal}
        totalQuestions={questions.length}
        answeredCount={answers.filter((answer) => answer !== null).length}
        notAnsweredCount={questions.length - answers.filter((answer) => answer !== null).length}
      /> */}
      </div>
    </div>
  );
};

export default ExamPage;