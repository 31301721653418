import React, { useEffect, useState } from 'react';
import SectionDescription from '../../../common/sectionDescription';
import BreadCrumbs from '../../../common/BreadCrumbs';
import { CDN_BASE_URL, ERROR_SOMETHING_WENT_WRONG, nFormatter, yearSatra } from '../../../../utils/utils';
import { getAllPatternListing, getAllStreams } from '../../../../api/careernews';
import { toast } from "react-toastify";
import { Loadingicon } from '../../../../AppIcons';
import ViewAllButton from '../../../common/viewallbutton';
import PopularCareerCategories from '../popularcategories';
import StreamBranchList from '../common/streambranchlist';
import { useNavigate, useParams } from 'react-router-dom';
import NotFound from '../../../common/notfound';


function NewsPatternList() {
    const [pageNo, setPageNo] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isNotFound, setIsNotFound] = useState(false);
    const [loadingLoadMore, setLoadingLoadMore] = useState(false);
    const [allStreams, setAllStreams] = useState([]);
    const [patternType, setPatternType] = useState();

    const navigation = useNavigate();
    const params = useParams();

    useEffect(() => {
        if(params?.patternType){
            setPatternType(params?.patternType)
            handleNewsPatternList(1, params?.patternType);
        }
        
    }, [])

    // fetch all pattern list
    const handleNewsPatternList = async (pageNo, patternType) => {
        const resPatternList = await getAllPatternListing({ pageNo: pageNo, priority:patternType });
        console.log('lo', resPatternList)
        if (resPatternList?.success) {
            setIsNotFound(false)
            resPatternList?.next_page_url ? setShowLoadMore(true) : setShowLoadMore(false);
            if (resPatternList?.data?.length && pageNo == 1) {
                setAllStreams(resPatternList?.data);
            } else if (resPatternList?.data?.length && pageNo !== 1) {
                setAllStreams([...allStreams, ...resPatternList?.data]);
            } else {
                if (pageNo == 1) {
                    setAllStreams([]);
                    setIsNotFound(true);
                }
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }

        setLoading(false);
        setLoadingLoadMore(false);
    }

    // load more streams data
    const loadMore = () => {
        setLoadingLoadMore(true);
        const nxtPage = pageNo + 1;
        handleNewsPatternList(nxtPage, patternType);
        setPageNo(nxtPage);
    }

    const popularCareerCats = [
        {
            name: "Latest Govt Jobs",
            value: "Latest Govt Jobs",
            redirectUrl: "#"
        },
        {
            name: "Govt Jobs By Qualification",
            value: "Govt Jobs By Qualification",
            redirectUrl: "#"
        },
        {
            name: "Govt Jobs By Location",
            value: "Govt Jobs By Location",
            redirectUrl: "#"
        },
        {
            name: "Latest Private Jobs",
            value: "Latest Private Jobs",
            redirectUrl: "#"
        },
        {
            name: "Private Jobs By Qualification",
            value: "Private Jobs By Qualification",
            redirectUrl: "#"
        },
        {
            name: "Private Jobs By Location",
            value: "Private Jobs By Location",
            redirectUrl: "#"
        },
        {
            name: "Latest Internships",
            value: "Latest Internships",
            redirectUrl: "#"
        },
        {
            name: "Internships By Education",
            value: "Internships By Education",
            redirectUrl: "#"
        },
        {
            name: "Internships By Location",
            value: "Internships By Location",
            redirectUrl: "#"
            
        }
    ];


    return (<>
        <BreadCrumbs />
        <div className='row'>
            <div className='col-md-9'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1">
                    <div className="d-flex align-items-center justify-content-between">
                        <SectionDescription title={params?.patternType=='admissions-location'?`<strong>Latest 10 University, College, Degree, Course Admissions Near You For Session ${yearSatra()}</strong>`:params?.patternType=='jobs-location'?`<strong>Latest 10 Jobs By Location Public and Private Sector Jobs Near You For Session ${yearSatra()}</strong>`:params?.patternType=='jobs-education'?`<strong>Latest 10 Jobs For Your Qualification For Session ${yearSatra()}</strong>`:`<strong>Latest 10 University, College, Degree, Course Admissions For Your Qualification For Session ${yearSatra()}</strong>`} subTitle={params?.patternType=='jobs-location'?`Get Alerts on upcoming and ongoing Admissions for all University/College/Degree/Courses Near You in ${yearSatra()}. Practice for Banking, Railways, Defense, Civil Services entrance and more in Question Bank. Prepare for any job interview with Interview Questions`:`Get alerts for all Public / Private Sector jobs near you in ${yearSatra()}. Practice for Banking, Railways, Defense, Civil Services entrance and more in Question Bank. Prepare for any job interview with Interview Questions`} />
                    </div>

                    <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-4" />

                    {loading ? (
                        <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                            <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                                <div className="h-10 w-10">
                                    <Loadingicon />
                                </div>
                            </div>
                        </div>) : isNotFound ? (
                            <NotFound/>) : <>
                        {
                            allStreams?.map((stream, sIndex) => (<>
                            
                                <StreamBranchList stream={stream} thumbPrefix={(params?.patternType=='jobs-location' || params?.patternType=='admissions-location')?`thcn/state/thumb/`:(params?.patternType=='jobs-education' || params?.patternType=='admissions-education')?`thcn/qualification/thumb/`:`thcn/news/desktop/thumb/`} key={sIndex} type="pattern" sType={(params?.patternType=='jobs-education' || params?.patternType=='admissions-education' || params?.patternType=='jobs-location' || params?.patternType=='admissions-location')?'':'patternNews'} redirectionFn={()=>navigation(sIndex==0 && params?.patternType=='jobs-location'?`/career-news/jobs/all-india-news`:sIndex==0 && patternType=='admissions-location'?`/career-news/admissions/all-india-news`:params?.patternType=='jobs-location'?`/career-news/jobs/city-list/${stream?.state_id}/${stream?.slug}`:params?.patternType=='admissions-location'?`/career-news/admissions/city-list/${stream?.state_id}/${stream?.slug}`:params?.patternType=='admissions-education'?`/career-news/admissions-education/${stream?.education_id}/${stream?.slug}`:patternType=='jobs-education'?`/career-news/jobs-education/${stream?.education_id}/${stream?.slug}`:`/career-news/news/${stream?.id}/${stream?.slug}`)}/>
                                </>
                            ))
                        }
                    </>
                    }
                </div>
                {
                    showLoadMore ? <ViewAllButton
                        disabled={false}
                        redirectionUrl={'/'}
                        handleClickFn={loadMore}
                        btnText={'Load More'}
                        type={'button'}
                        btnClass={'loadButton card-btn-1'}
                        loading={loadingLoadMore}
                    /> : null
                }

            </div>
            <div className='col-md-3'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1 position-sticky" style={{ top: 80 }}>
                  
                    {/* <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-4" /> */}
                    <PopularCareerCategories catsList={popularCareerCats} title={`Popular Categories`}/>
                </div>
            </div>
        </div>

    </>)
}

export default NewsPatternList;
