import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import ReportProblem from "@material-ui/icons/ReportProblem";
import CircularProgress from "@material-ui/core/CircularProgress";
import EditOutlined from "@material-ui/icons/EditOutlined";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import { PostDataAuth } from "../../../api/feeds/PostDataAuth";
import { closeModal } from "../../../utils/utils";
import { PostDataFile } from "../../../api/feeds/PostDataFile";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { GetDataAuth } from "../../../api/feeds/GetDataAuth";
import { useFeeds } from "../../../Context/FeedsContext";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../Context/AuthContext";
import { toast } from "react-toastify";

export default function MoreOption({
  item,
  apiToken,
  user,
  _allFeed,
  modalOpen,
  setModalOpen,
  setShowHideMoreOption,
  showHideMoreOption,
  svgRef
  // moreOptionsRef
}) {
  const {globalToken, setIsEmailVerifyCover} = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  // const { setFeedDetail } = useFeeds();
  const {
    setMyFav,
    setFeeds,
    setMyFeeds,
    setSearch,
    loading,
    setLoading,
    feeds,
    setFeedDetail
  } = useFeeds();

  const navigation = useNavigate();
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [feedText, setFeedText] = useState(item?.feed_text);
  const [reportId, setReportId] = useState(1);
  const [enableReport, setEnableReport] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditPostModal, setShowEditPostModal] = useState(false);
  const reportType = [
    { id: 1, title: "Sexual Content" },
    { id: 2, title: "Child Abuse" },
    { id: 3, title: "Promote Terorism" },
    { id: 4, title: "Spam or Misleading" },
    { id: 5, title: "Other" },
  ];

  const moreOptionsRef = useRef(null);
  const modalRRef = useRef(null);
  const modalDRef = useRef(null);
  const modalEPRef = useRef(null);
  const modalEpTRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        moreOptionsRef.current &&
        !moreOptionsRef.current.contains(event.target) &&
        (!modalRRef.current || !modalRRef.current.contains(event.target)) &&
        (!modalDRef.current || !modalDRef.current.contains(event.target)) &&
        (!modalEPRef.current || !modalEPRef.current.contains(event.target)) &&
        (!modalEpTRef.current || !modalEpTRef.current.contains(event.target)) && (!svgRef.current || !svgRef.current.contains(event.target))
      ) {
        setShowHideMoreOption(false); // Close the MoreOption if clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setShowHideMoreOption]);

  const handleReportModal = () => {
    setShowReportModal(!showReportModal);
  };

  const handleEditPostModal = () => {
    setShowEditPostModal(!showEditPostModal);
  };

  const handleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const reportFeed = async () => {
    if (!apiToken) {
      setModalOpen(true);
      return;
    }
    setIsLoading(true);
    const returnReportFeed = await PostDataAuth(
      `feed/report/create`,
      apiToken,
      { feed_id: item?.id, report_id: reportId }
    );

    if (returnReportFeed?.success) {
      setShowMessage(true);
      setMessage(returnReportFeed?.message);
      setTimeout(() => {
        setShowMessage(false);
        setMessage("");
        handleReportModal();
      }, 3000);
      setIsLoading(false);
      setEnableReport(false);
    } else {
      setIsLoading(false);
    }
  };
  const handleMyFeeds = async () => {
    navigation("/feeds");

    setMyFav(false);
    setSearch(false);

    if (localStorage.getItem("userDetails")) {
      // const aToken = localStorage.getItem("___fTkn");
      const returnMyFeeds = await GetDataAuth(`feed/my`, globalToken);
      if (returnMyFeeds?.success) {
        setFeeds(returnMyFeeds);
        setMyFeeds(true);
        localStorage.removeItem("feedDetail");
      } else {
        setMyFeeds(true);
      }
    } else {
      console.log("");
    }
  };

  const handleDeleteFeed = async () => {
    if (!apiToken) {
      alert("Please login to delete post.");
      return;
    }
    setIsLoading(true);
    const returnReportFeed = await PostDataAuth(
      `my/feed/delete`,
      apiToken,
      { feed_id: item?.id }
    );

    if (returnReportFeed?.success) {
      setShowHideMoreOption(false)
      setShowMessage(true);
      setMessage(returnReportFeed?.message);
      setTimeout(() => {
        setShowMessage(false);
        // _feedDetails(item?.id)
        handleDeleteModal();
        handleMyFeeds();

        setMessage("");
      }, 2000);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const browseImage = () => {
    const browseFile = document.querySelector("#choosepostfile");
    browseFile?.click();
  };
  const handleEditPost = async () => {
    // const apiToken = localStorage.getItem("___fTkn");
    
    if (!globalToken) {
      alert("Please login to edit post.");
      return;
    }
    setIsLoading(true);
    if (!feedText) {
      setMessage("Please write something to post");
      setShowMessage(true);
      setTimeout(() => {
        setShowMessage(false);
      }, 2000);
      setIsLoading(false);
      return;
    }
    let formdata = new FormData();
    formdata.append("feed_text", feedText);
    formdata.append("feed_id", item?.id);

    if (globalToken) {
      const editPostReturn = await PostDataFile(
        `feed/edit`,
        globalToken,
        formdata
      );
      if (editPostReturn?.success) {
        setShowHideMoreOption(false)
        _allFeed(1);
        setMessage(editPostReturn?.message);
        setShowMessage(true);
        _feedDetails(item?.id)
        setTimeout(() => {
          setShowMessage(false);
          handleEditPostModal();
        }, 2000);

        
      } else {
        if(editPostReturn?.status==5){
          setShowEditPostModal(false);
            toast.error(editPostReturn?.errors);
            setTimeout(() => { setIsEmailVerifyCover(true) }, 200)
        }
      }
      setIsLoading(false);
    }
  };
  const _feedDetails = async (id) => {
    if (localStorage.getItem("userDetails")) {
      // const aToken = localStorage.getItem("___fTkn");
      const feedDetailsReturn = await GetDataAuth(
        `feed-more/details/${id}/via-authentic-user`,
        globalToken
      );
      if (feedDetailsReturn?.success) {
        
        if(feedDetailsReturn?.data){
            setFeedDetail([feedDetailsReturn?.data])
        }else{
            navigation('/feeds')
        }
      } else {
        
      }
    } else {
    }
  };
  return (
    <>
      {" "}
      {showHideMoreOption?
      <div className="col d-flex flex-column more-option" ref={moreOptionsRef}>
        {item?.user_detail?.tuteehub_id == user?.tuteehub_id ? (
          <>
            {" "}
            <span
              role="button"
              onClick={() => handleEditPostModal()}
              className="py-2 flex-1 w-100 d-flex align-items-center"
            >
              <EditOutlined style={{ fill: "#585858", fontSize: 18 }} />{" "}
              <span className="ms-2">Edit Feed</span>
            </span>
            <span
              role="button"
              onClick={() => handleDeleteModal()}
              className="py-2 flex-1 w-100 d-flex align-items-center"
            >
              <DeleteOutline style={{ fill: "#585858", fontSize: 18 }} />{" "}
              <span className="ms-2">Delete Feed</span>
            </span>
          </>
        ) : null}
        <span
          role="button"
          className="py-2 flex-1 w-100 d-flex align-items-center"
          onClick={() => handleReportModal()}
        >
          <ReportProblem style={{ fill: "#585858", fontSize: 18 }} />{" "}
          <span className="ms-2">Report</span>
        </span>
      </div>:null
      }
      {/* report popup START  */}
      <div ref={modalRRef}>
      <Modal
          
          show={showReportModal}
          onHide={handleReportModal}
          size="sm"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h5 className="mb-0">Reports</h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {showMessage ? (
              <div
                className="alert alert-success alert-dismissible fade show"
                role="alert"
              >
                {message}
                {/* <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></button> */}
              </div>
            ) : null}
            <div className="radio-buttons d-flex flex-column">
              {reportType?.map((rType, i) => (
                <label key={i} className="d-flex align-items-center my-2" htmlFor={`report-${rType?.id}`}>
                  <input
                    id={`report-${rType?.id}`}
                    //   checked={i==0?true:false}
                    value={rType?.id}
                    name="reportType"
                    type="radio"
                    className="me-2"
                    onChange={(e) => {setReportId(e.target?.value);setEnableReport(true)}}
                  />
                  {rType?.title}
                </label>
              ))}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"

              disabled={!enableReport || isLoading ? true : false}
              className="btn btn-warning custom-theme-button d-flex align-items-center"
              onClick={() => reportFeed()}
            >
              Submit{" "}
              {isLoading ? (
                <CircularProgress size={15} color={"inherit"} className="ms-2" />
              ) : null}
            </button>
          </Modal.Footer>
        </Modal>
        </div>
      {/* report popup END  */}
      {/* delete alert START  */}
      <div ref={modalDRef}>
      <Modal
        
        show={showDeleteModal}
        onHide={handleDeleteModal}
        size="sm"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {showMessage ? (
            <div
              className="alert alert-success alert-dismissible fade show"
              role="alert"
            >
              {message}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          ) : null}
          <strong>Hey!</strong> Do you realy want to delete?
        </Modal.Body>
        <Modal.Footer style={{ border: "none" }}>
          <button
            type="button"
            className="btn btn-warning custom-theme-button d-flex align-items-center"
            onClick={() => handleDeleteFeed()}
          >
            Yes{" "}
          </button>
          <button
            type="button"
            className="btn btn-light d-flex align-items-center ms-2"
            onClick={() => handleDeleteModal()}
          >
            No{" "}
          </button>
        </Modal.Footer>
      </Modal>
      </div>
      {/* delete alert popup END  */}
      {/* edit post popup START  */}
      <div  ref={modalEPRef}>
      <Modal show={showEditPostModal} onHide={handleEditPostModal} centered>
        <Modal.Header closeButton>
          <h5 className="mb-0">Edit Post</h5>
        </Modal.Header>
        <Modal.Body>
          {showMessage ? (
            <div
              className="alert alert-success alert-dismissible fade show"
              role="alert"
            >
              {message}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          ) : null}

          <textarea
            type="text"
            multiple
            value={feedText}
            style={{ wordWrap: "break-word" }}
            className="form-control mb-3"
            onChange={(e) => setFeedText(e.target.value)}
            required
            placeholder={`What's on your mind, ${user?.name}?`}
          ></textarea>
          {item?.feed_media?.length  && item?.feed_media?.[0]?.media_type=='image' ? (
            <img
              src={item?.feed_media[0]?.media_url}
              width="100"
              height={100}
              laytout="responsive"
            />
          ) : <video src={item?.feed_media?.[0]?.media_url} width={100} controls/>}

          <button
            type="button"
            disabled={isLoading ? true : false}
            className="mt-3 btn btn-block w-100 btn-warning custom-theme-button py-2 justify-content-center d-flex align-items-center"
            onClick={() => handleEditPost()}
          >
            Save{" "}
            {isLoading ? (
              <CircularProgress size={15} color={"inherit"} className="ms-2" />
            ) : null}
          </button>
        </Modal.Body>
      </Modal>
      </div>
      <div ref={modalEpTRef}>
      {/* edit post popup END  */}
      {item?.user_detail?.tuteehub_id == user?.tuteehub_id ? (
        <div
          
          className="modal fade"
          id="editPostPopup"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content ">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Edit Post
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body px-4 py-5">
                {showMessage ? (
                  <div
                    className="alert alert-success alert-dismissible fade show"
                    role="alert"
                  >
                    {message}
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="alert"
                      aria-label="Close"
                    ></button>
                  </div>
                ) : null}

                <input
                  type="text"
                  value={feedText}
                  className="form-control mb-3"
                  onChange={(e) => setFeedText(e.target.value)}
                  required
                  placeholder={`What's on your mind, ${user?.name}?`}
                />
                {item?.feed_media?.length && item?.feed_media[0]?.media_type=='image' ? (
                  <img
                    src={item?.feed_media[0]?.media_url}
                    width="100"
                    height={100}
                    laytout="responsive"
                  />
                ) : <video src={item?.feed_media[0]?.media_url}/>}

                <button
                  type="button"
                  disabled={isLoading ? true : false}
                  className="btn btn-block w-100 btn-warning custom-theme-button py-2 justify-content-center d-flex align-items-center"
                  onClick={() => handleEditPost()}
                >
                  Save{" "}
                  {isLoading ? (
                    <CircularProgress
                      size={15}
                      color={"inherit"}
                      className="ms-2"
                    />
                  ) : null}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      </div>
    </>
  );
}
