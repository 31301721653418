import { WhatsApp } from "@material-ui/icons";
import React, { useEffect } from "react";

// import { Facebook, Telegram, Twitter } from 'react-social-sharing'
// import Image from "next/image";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
} from "react-share";
import { FEEDS_API_Basepath, modulesUrls } from "../../../utils/utils";
import { handleShareIncrease } from "../../../api/OurService";

export default function Share({ shareContent, isCustomPos = false, isShare = false, setIsShare = null, iconShareRef = null, shareRef = null, refetchDetails=null, slug=null }) {

  const handleOutsideClick = (e) => {

    if (
      shareRef.current &&
      !shareRef.current.contains(e.target) &&
      iconShareRef.current &&
      !iconShareRef.current.contains(e.target)
    ) {

      setIsShare(false);
    }
  };

  useEffect(() => {
    if (isShare) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isShare]);

  const shareCountIncrease = async (baseURL, type) => {
    const resShareCountIncrease = await handleShareIncrease(baseURL, 'share', shareContent?.id);
    if (resShareCountIncrease?.data?.data?.status || resShareCountIncrease?.status) {
      refetchDetails(type && type!=='exams'?shareContent?.id:type && type=='exams'?shareContent?.slug:'');
    }
  }

  const handleShareSuccess = () => {
    
    if (shareContent?.url.includes(modulesUrls.tuteehubQuestionBank)) {
      shareCountIncrease(`${modulesUrls.tuteehubQuestionBank}/api/v3/qb`, null);
      return;
    }
    if (shareContent?.url.includes(modulesUrls.tuteehubFeeds)) {
      shareCountIncrease(`${FEEDS_API_Basepath}feed/share`, null);
      return;
    }
    if (shareContent?.url.includes(modulesUrls.tuteehubInterviewQuestions)) {
      shareCountIncrease(`${modulesUrls.tuteehubInterviewQuestions}/api/v3/iq`, 'iq');
      return;
    }
    if (shareContent?.url.includes(modulesUrls.tuteehubFullForm)) {
      shareCountIncrease(`${modulesUrls.tuteehubFullForm}/api/v3/ff`, null);
      return;
    }
    if (shareContent?.url.includes(modulesUrls.tuteehubDictionary)) {
      shareCountIncrease(`${modulesUrls.tuteehubDictionary}/api/v3/dc`, null);
      return;
    }
    if (shareContent?.url.includes(modulesUrls.tuteehubExams)) {
      shareCountIncrease(`${modulesUrls.tuteehubExams}/api/v3/exams`, 'exams');
      return;
    }
    if (shareContent?.url.includes(`/career-news/`)) {
      shareCountIncrease(`${modulesUrls.tuteehubNews}/api/v3/cn`, 'cn');
      return;
    }
    if (shareContent?.url.includes(modulesUrls.tuteehubQuotes)) {
      shareCountIncrease(`${modulesUrls.tuteehubQuotes}/api/v3/sht`, 'quotes');
      return;
    }
    if (shareContent?.url.includes(modulesUrls.tuteehubLyrics)) {
      shareCountIncrease(`${modulesUrls.tuteehubLyrics}/api/v3/lyr`, 'lyrics');
      return;
    }
    if (shareContent?.url.includes(modulesUrls.tuteehubBlogs)) {
      shareCountIncrease(`${modulesUrls.tuteehubBlogs}/api/v3/blogs`,'blogs');
      return;
    }
    if (shareContent?.url.includes(modulesUrls.tuteehubForum)) {
      shareCountIncrease(`${modulesUrls.tuteehubForum}/api/v3/forum`, 'forum');
      return;
    }
    if (shareContent?.url.includes(modulesUrls.tuteehubTutorial)) {
      shareCountIncrease(`${modulesUrls.tuteehubTutorial}/api/v3/tutorials`, 'tutorials');
      return;
    }
    if (shareContent?.url.includes(modulesUrls.tuteehubCorporates)) {
      shareCountIncrease(`${modulesUrls.tuteehubCorporates}/api/v3/corporates`, 'corporates');
      return;
    }
    if (shareContent?.url.includes(modulesUrls.tuteehubEducators)) {
      shareCountIncrease(`${modulesUrls.tuteehubEducators}/api/v3/educators`, 'educators');
      return;
    }
  }
  return (
    <div className={`col share-container ${isCustomPos ? 'share-q' : ''}`}>
      <FacebookShareButton
        url={shareContent?.url}
        quote={shareContent?.quote}
        hashtag={shareContent?.hashtag}
        onShareWindowClose={handleShareSuccess}
      >
        <FacebookIcon size={32} round />
      </FacebookShareButton>
      <TwitterShareButton
        url={shareContent?.url}
        title={shareContent?.title}
        onShareWindowClose={handleShareSuccess}

      >
        <TwitterIcon size={32} round />
      </TwitterShareButton>
      <LinkedinShareButton url={shareContent?.url}
        onShareWindowClose={handleShareSuccess}>
        <LinkedinIcon size={32} round

        />
      </LinkedinShareButton>
      <WhatsappShareButton
        url={shareContent?.url}
        title={shareContent?.title}
        separator=":: "
        onShareWindowClose={handleShareSuccess}

      >
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>
      <TelegramShareButton
        url={shareContent?.url}
        title={shareContent?.title}
        onShareWindowClose={handleShareSuccess}

      >
        <TelegramIcon size={32} round />
      </TelegramShareButton>
    </div>
  );
}
