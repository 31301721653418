import React, { useEffect, useRef, useState } from 'react';
import { Link, NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import { useAuth } from '../../../../Context/AuthContext';
import * as Yup from "yup";
import {
    convertDateFormat, nFormatter, slugToNormal, modulesUrls,
    ERROR_SOMETHING_WENT_WRONG,
    placeholderImages,
    CDN_BASE_URL,
    convertDateFormatOnly,
    slugify,
    emailNotVerified,
    fetchUserInfo,
    convertRating,
    viewRecentVisits
} from '../../../../utils/utils';

import Share from '../../../common/share';
import Modal from '../../../common/Modal';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from "../../../../assets/css/styles/Home.module.css";
import { Loadingicon } from '../../../../AppIcons';
import StarRatings from 'react-star-ratings';
import { Formik } from 'formik';
import CorporateComments from '../../../common/comments/corporatesComments';
import { bookMarkEducator, claimAccountEducator, educatorCommentAndReply, followEducator, getEducatorDetail, likeUnlikeDislike, reportAgainstEducator, writeReviewEducator } from '../../../../api/educator';
import ReactPlayer from 'react-player';
import { LightBox } from 'react-lightbox-pack';
import EducatorComment from '../../../common/comments/educatorsComments';
import NotFound from '../../../common/notfound';

function EducatorDetail() {
    const { globalToken, user, setIsEmailVerifyCover, setFinance, setUser } = useAuth();
    const [pageNo, setPageNo] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [loading, setLoading] = useState(true);    
    
    const playerRef = useRef(null);
  
    const [isPlaying, setIsPlaying] = useState(false);
    const [showControls, setShowControls] = useState(true);
    const [isSeekbar, setIsSeekbar] = useState(false);
    const [played, setPlayed] = useState(0);
    const [isLocationChange, setIsLocationChange] = useState(false);
    const[nextPrevLoader, setNextPrevLoader] = useState(false);

    const [buffering, setBuffering] = useState(false);

    const [btnLoading, setBtnLoading] = useState(false);
    const [isNotFound, setIsNotFound] = useState(false);
    const [upvoteBtnLoader, setUpvoteBtnLoader] = useState(false);
    const [downvoteBtnLoader, setDownvoteBtnLoader] = useState(false);
    const [loadingLoadMore, setLoadingLoadMore] = useState(false);
    const [educatorId, setEducatorId] = useState();
    const [isShareActive, setIsShareActive] = useState(false);
    const navigation = useNavigate();
    const params = useParams();
    const location = useLocation();
    const currentPath = location.pathname;
    const [introLoad, setIntroLoad] = useState(false);
    const [educatorDetail, setEducatorDetail] = useState();
    const [sIndex, setSIndex] =  React.useState(0);
    const [toggle, setToggle] =  React.useState(false);
    const [isOpenRatingModal, setIsOpenRatingModal] = useState(false);
    const [isOpenReportModal, setIsOpenReportModal] = useState(false);
    const [isOpenClaimAccountModal, setIsOpenClaimAccountModal] = useState(false);
    const [ratingVal, setRatingVal] = useState();
    const [choosenGallery, setChoosenGallery] = useState();
    const shareRef = useRef(null);
    const iconShareRef = useRef(null);
    const DisplayingErrorMessagesSchemaRating = Yup.object().shape({
        rating: Yup.string().required("Rating is required"),
        reviews: Yup.string().required("Review is required")

    });
    const DisplayingErrorMessagesSchemaReport = Yup.object().shape({
        report: Yup.string().required("Report message is required")

    });
    const DisplayingErrorMessagesSchemaClaimAccount = Yup.object().shape({
        description: Yup.string().required("Message is required")

    });
    // Fetches educator details when component mounts or params change
    // useEffect(() => {
    //     if (params?.educatorSlug) {
    //         const  cId = (params?.educatorSlug).split('-').pop();
    //         setEducatorId(cId);
    //         handleFetchEducatorDetail(cId);
    //     }

    // }, []);

    // useEffect(() => {
    //     if (params?.educatorSlug) {
    //         const  cId = (params?.educatorSlug).split('-').pop();
    //         setEducatorId(cId);
    //         handleFetchEducatorDetail(cId);
    //     }
    // }, [isLocationChange]);

    useEffect(() => {
        if (params?.educatorSlug) {
            const slugSplit = params.educatorSlug.split('-');
            let cId = slugSplit.pop();
            if (isNaN(cId) || !cId) {
                setLoading(false);
                setIsNotFound(true);
                return;
                // cId = 'default'; 
            }
    
            setEducatorId(cId);
            handleFetchEducatorDetail(cId);
            viewRecentVisits(globalToken);
        }
    }, []);
    
    useEffect(() => {
        if (params?.educatorSlug) {
            const slugSplit = params.educatorSlug.split('-');
            let cId = slugSplit.pop();
    
            if (isNaN(cId) || !cId) {
                setLoading(false);
                setIsNotFound(true);
                return;
            }
    
            setEducatorId(cId);
            handleFetchEducatorDetail(cId);
        }
    }, [isLocationChange]);

    const  lightBoxHandler  = (state, sIndex) => {       
		setToggle(state);
		setSIndex(sIndex);
	};

    const changeRating = (newRating) => {
        setRatingVal(newRating);
      };

    // Handles fetching educator detail
    const handleFetchEducatorDetail = async (eId) => {
        setIntroLoad(true);
        const resEducatorDetail = await getEducatorDetail({ token:globalToken, educatorId: eId });
        setLoading(false);
        setLoadingLoadMore(false);
        setUpvoteBtnLoader(false);
        setDownvoteBtnLoader(false)
        if (resEducatorDetail?.success) {
            setIsNotFound(false);
            if (resEducatorDetail) {
                setEducatorDetail(resEducatorDetail);
            } else {
                setEducatorDetail({});
                setIsNotFound(true);
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG);
        }
    }

    // Handles adding/removing educator from favorites
    const FavouriteButton = ({ is_fav_camp }) => {
        const [favBtnLoading, setFavBtnLoading] = useState(false);

        const handleStartTask = async () => {
            setFavBtnLoading(true);
            const token = globalToken;
            const res = await bookMarkEducator({ token: token, educatorId: educatorDetail?.educator?.id, favourite: is_fav_camp ? 'unfav' : 'fav' });
            if (res?.success) {
                handleFetchEducatorDetail(educatorDetail?.educator?.id);
            }
            setFavBtnLoading(false);
        }

        return (
            <button
                type="button"
                disabled={favBtnLoading}
                onClick={handleStartTask}
                className="btn bg-white p-0 d-flex btn-like-circular"
                style={{ border: 'none', boxShadow: 'none' }}
            >
                {favBtnLoading ? (
                    <div className="h-5 w-5">
                        <Loadingicon />
                    </div>
                ) : (
                    <>
                        {is_fav_camp ? <i className="fa fa-heart heart active"></i> : <i className="fa heart fa-heart"></i>}
                    </>
                )}
            </button>
        );
    }

    // Handles sharing the tutorial
    const InlineShare = ({ eduData, isShareActive, setIsShareActive }) => {
        const handleShareClick = () => {
            setIsShareActive(!isShareActive);
        };
        return (
            <small className="position-relative" title="Share">
                {isShareActive ? (
                    <span ref={shareRef}><Share
                        shareContent={{
                            url: `${modulesUrls?.tuteehubEducators}/university/${eduData?.slug}`,
                            quote: `${eduData?.metaDescription}`,
                            hashing: `#tuteehub`,
                            title: `${eduData?.listingName}`,
                            id: eduData?.id
                        }}
                        isCustomPos={true}
                        isShare={isShareActive} 
                        setIsShare={setIsShareActive} 
                        iconShareRef={iconShareRef} 
                        shareRef={shareRef} 
                        refetchDetails={handleFetchEducatorDetail}
                    /></span>
                ) : null}
                <span
                    ref={iconShareRef}
                    className={`${styles.icons} ${styles.shareIco}`}
                    style={{ display: 'inline-block' }}
                    role="button"
                    onClick={handleShareClick}
                ></span>
            </small>
        );
    }

    // Handles upvoting or downvoting the lyrics
    // Handles upvoting or downvoting the corporate
    const handleLikeUnlikeDislike = async (voteType, type) => {
        if (type === 'dLike') setDownvoteBtnLoader(true);
        else setUpvoteBtnLoader(true);
        const resLikeUnlike = await likeUnlikeDislike({ token: globalToken, educatorId: educatorDetail?.educator?.id, voteType });
        if (resLikeUnlike?.success) {
            handleFetchEducatorDetail(educatorDetail?.educator?.id);
            toast.success(resLikeUnlike?.data?.message);
            fetchUserInfo(user?.tuteehub_id, globalToken, setUser, setFinance, user);
        }
    }

    // Handles submitting comments and replies
    const handleSubmitComment = async (commentId, setComment, comment, isReply, commentLoader, setIsReplyShow) => {
        if (!emailNotVerified(user, setIsEmailVerifyCover)) return;
        
        commentLoader(true);
        try {
            const resCommentCommit = await educatorCommentAndReply({
                token: globalToken,
                educator_id: educatorDetail?.educator?.id,
                commentId: isReply ? commentId : 0,
                comment,
            });
            if (resCommentCommit?.success) {
                setIsReplyShow(false);
                toast.success(resCommentCommit?.message);
                handleFetchEducatorDetail(educatorId);
                fetchUserInfo(user?.tuteehub_id, globalToken, setUser, setFinance, user);
            }
            setComment('');
        } catch (error) {
            toast.error('Error sending comment');
        } finally {
            commentLoader(false);
        }
    }

    // Modifies anchor tags to open in a new tab
    const modifyAnchorTags = (htmlContent) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        const anchorTags = doc.querySelectorAll('a');
        anchorTags.forEach(tag => {
            tag.setAttribute('target', '_blank');
        });
        return doc.documentElement.innerHTML;
    };



    

    const openHideRatingModal = () =>{
        setIsOpenRatingModal(!isOpenRatingModal);
    }

    const openHideReportModal = () =>{
        setIsOpenReportModal(!isOpenReportModal);
    }

    const openHideClaimAccountModal = () =>{
        setIsOpenClaimAccountModal(!isOpenClaimAccountModal);
    }

    const handleReviewRating = async (values) =>{
        setBtnLoading(true);
        const resReviewRating = await writeReviewEducator({token:globalToken, educator_id:educatorDetail?.educator?.id, reviews:values?.reviews, rating:values?.rating});
        if(resReviewRating?.success){
            fetchUserInfo(user?.tuteehub_id, globalToken, setUser, setFinance, user);
            handleFetchEducatorDetail(educatorId);
            toast.success('Thanks for Feedback!');
            openHideRatingModal(false);
        }else{
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }
        setBtnLoading(false); 
    }

    const handleReport = async (values) =>{
        setBtnLoading(true);
        const resReviewRating = await reportAgainstEducator({name:user?.name, email:user?.email, token:globalToken, educator_id:educatorDetail?.educator?.id, report:values?.report});
        
        if(resReviewRating?.success){
            handleFetchEducatorDetail(educatorId);
            toast.success('Report registered');
            openHideReportModal(false);
        }else{
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }
        setBtnLoading(false); 
    }

    const handleFollow = async (fStatus) =>{
        setBtnLoading(true);
        const resFollow = await followEducator({token:globalToken, educator_id:educatorDetail?.educator?.id, status:fStatus});
        // alert(JSON.stringify(resReviewRating))
        if(resFollow?.status==1){
            handleFetchEducatorDetail(educatorId);
            toast.success(resFollow?.data?.message)
        }else{
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }
        setBtnLoading(false); 
    }


    const handleClaimAccount= async (cValues) =>{
        setBtnLoading(true);
        const resClaimAccount = await claimAccountEducator({token:globalToken, educator_id:educatorDetail?.educator?.id, name:user?.name, email:user?.email, phone:user?.phone, description:cValues?.description});
        // alert(JSON.stringify(resClaimAccount))
        if(resClaimAccount?.success){
            openHideClaimAccountModal();
            handleFetchEducatorDetail(educatorId);
            toast.success('You claim to account submitted');
            
        }else{
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }
        setBtnLoading(false); 
    }

    const tabItems = [{
            name:'Ranking',
            value:'ranking'
        },{
            name:'Affiliations',
            value:'affiliations'
        },{
            name:'Courses',
            value:'courses'
        },{
            name:'Exams',
            value:'exams'
        },{
            name:'Base Courses',
            value:'base-courses'
        },{
            name:'Gallery',
            value:'gallery'
        },{
            name:'Scholorships',
            value:'scholorships'
        },{
            name:'Event Details',
            value:'event-details'
        },{
            name:'Address',
            value:'address'
        }
    ]
    return (
        <>
            {loading ? (
                <div className="w-full flex items-center justify-center py-4 text-themecolor min-h-[400px] group-card card-body">
                    <div className="h-10 w-10 theme-color">
                        <Loadingicon />
                    </div>
                </div>
            ) : (
                <>
                    <nav aria-label="breadcrumb" className="pt-0 px-0 pb-0 breadcrumb-card">
                        <ol className="breadcrumb px-0 pt-0 breadcrumb-main-mobilea" style={{ display: 'flex', flexWrap: 'nowrap' }}>
                            <li className={`breadcrumb-item capitalize rest-bread`}>
                                <NavLink to='/' style={{ fontSize: 14, textDecoration: 'none' }}>Home</NavLink>
                            </li>
                            <li className={`breadcrumb-item capitalize rest-bread`}>
                                <NavLink to='/educators' style={{ fontSize: 14, textDecoration: 'none' }}>Educators</NavLink>
                            </li>
                            <li className={`breadcrumb-item capitalize rest-bread`}>
                                <NavLink to={`/educators/states/${educatorDetail?.educator?.state?.slug}`} style={{ fontSize: 14, textDecoration: 'none' }}>{educatorDetail?.educator?.state_name}</NavLink>
                            </li>
                            <li className={`breadcrumb-item capitalize rest-bread`}>
                                <NavLink to={`/educators/cities/${educatorDetail?.educator?.city?.slug}`} style={{ fontSize: 14, textDecoration: 'none' }}>{educatorDetail?.educator?.city_name}</NavLink>
                            </li>
                            {
                                educatorDetail?.educator?.locality_name? <li className={`breadcrumb-item capitalize rest-bread`}>
                                <NavLink to={`/educators/localities/${educatorDetail?.educator?.locality?.slug}`} style={{ fontSize: 14, textDecoration: 'none' }}>{educatorDetail?.educator?.locality_name}</NavLink>
                            </li>:null
                            }
                           
                            <li
                                className={`breadcrumb-item capitalize active clamping ellipsis`}
                            >
                                {slugToNormal(params.educatorSlug)}
                            </li>
                        </ol>
                    </nav>
                    {
              isNotFound ? 
              <div className="py-4 px-md-4 px-2 grid grid-cols-1 group-card card-body gap-4">
          <NotFound/></div>:<>
          <div className="row">
                        <div className={`col-md-12`}>
                            <div className="py-4 px-md-4 px-2 grid grid-cols-1 group-card card-body gap-4">
                                <div className="px-0">
                                    <div className="flex-column d-flex flex-md-row justify-content-between">

                                        <div className="mr-md-5 pr-md-5 flex-1">
                                            <h1 className="title-job d-flex align-items-center justify-content-between text-capitalize">
                                                <strong>{educatorDetail?.educator?.listingName}</strong>
                                                <span className="d-flex gap-3 align-items-center">
                                                <FavouriteButton is_fav_camp={educatorDetail?.isFavourite}/>
                                                    <InlineShare eduData={educatorDetail?.educator} isShareActive={isShareActive} setIsShareActive={setIsShareActive} />
                                                </span>
                                            </h1>
                                            <div className="d-flex mb-4 flex-md-row flex-column justify-content-between align-md-items-center">

                                                <span className="text-black-50 gig-table">
                                                    <small style={{ marginTop: 1, float: 'left' }}>

                                                        <span className='star-container me-1'><span><i className='fa fa-star'></i>{educatorDetail?.reviews?.avgRating || 0}</span> based on <strong>{educatorDetail?.reviews?.rating || 0} Reviews</strong></span>
                                                        </small> 
                                                        <span className="big-dots"></span>
                                                        <small>{educatorDetail?.educator?.likes} up votes</small>
                                                            {
                                                                upvoteBtnLoader?<span className="custom-loader-small ms-2 theme-color">
                                                                <Loadingicon />
                                                            </span>:<><i
                                                                className={`fas fa-thumbs-up cursor-pointer ms-2 ${educatorDetail?.educator?.isLiked && "text-themecolor"
                                                                }`}
                                                                onClick={() => !emailNotVerified(user, setIsEmailVerifyCover) || handleLikeUnlikeDislike(educatorDetail?.educator?.isLiked ? "unlike" : "like",'lke')}
                                                            ></i>{" "}</>
                                                            }
                                                        <span className="big-dots"></span>
                                                        <small>{educatorDetail?.educator?.dislikes} down votes</small>
                                                            {
                                                                downvoteBtnLoader?<span className="custom-loader-small ms-2 theme-color">
                                                                <Loadingicon />
                                                            </span>:<><i
                                                                className={`fas fa-thumbs-down cursor-pointer ms-2 ${educatorDetail?.educator?.isDisliked && "text-themecolor"
                                                                }`}
                                                                onClick={() => !emailNotVerified(user, setIsEmailVerifyCover) || handleLikeUnlikeDislike(educatorDetail?.educator?.isDisliked ? "rmdislike" : "dislike", 'dLike')
                                                                                                                                 
                                                                }
                                                            ></i>{" "}</>
                                                            }
                                                        <span className="big-dots"></span>
                                                        <small>{educatorDetail?.educator?.share_count} shares</small>
                                                        
                                                        <span className="big-dots"></span>
                                                    <small>Establishment Year: {convertDateFormatOnly(educatorDetail?.estbYear)}</small>
                                                    {/* <span className="big-dots"></span>
                                                    <small className={`${educatorDetail?.company_status=='Active'?'text-success':'text-danger'}`}><strong>{educatorDetail?.company_status}</strong></small> */}
                                                </span>
                                              
                                            </div>
                                        <div id="questAns" className="w-100 rounded text-black-60 my-4 leads cn-desc" >
                                            <div dangerouslySetInnerHTML={{
                                                __html: modifyAnchorTags(`${educatorDetail?.educator?.metaDescription}`)
                                            }}></div>
                                        </div>
                                        <div className="col-span-12 question-box question-box-card py-2 mt-3 mb-3 mb-1">

                                                <button
                                                    className="btn-soft mr-2 cursor-pointer hover:bg-themecolor hover:text-white"
                                                    onClick={() =>  !emailNotVerified(user, setIsEmailVerifyCover) || openHideRatingModal()}
                                                >
                                                    Write A Review
                                                </button>
                                                <button
                                                    className="btn-soft mr-2 cursor-pointer hover:bg-themecolor hover:text-white"
                                                    onClick={() =>  !emailNotVerified(user, setIsEmailVerifyCover) || openHideReportModal()}
                                                >
                                                    Report
                                                </button>
                                                <button
                                                    className={`btn-soft ${educatorDetail?.is_follower?'bg-themecolor text-white':''} mr-2 cursor-pointer hover:bg-themecolor hover:text-white`}
                                                    onClick={() => !emailNotVerified(user, setIsEmailVerifyCover) || handleFollow(educatorDetail?.is_follower?'unfollow':'follow')}
                                                >
                                                    {educatorDetail?.is_follower?'Following':'Follow'} ({educatorDetail?.followers_count || 0})
                                                </button>
                                                <button
                                                    className="btn-soft claim-acc-btn mr-2 cursor-pointer hover:bg-themecolor hover:text-white float-right"
                                                    onClick={() =>  !emailNotVerified(user, setIsEmailVerifyCover) ||openHideClaimAccountModal()
                                                    }
                                                >
                                                    Is this your account ? <span className='ms-1'> Claim Account</span>
                                                </button>
                                            </div>
                                        <div className="w-100 rounded text-black-60 mt-2 border p-3 cn-desc" style={{background:'none'}}>
                                            
                                            <div dangerouslySetInnerHTML={{
                                                __html: modifyAnchorTags(`${educatorDetail?.educator?.aboutCollege}`)
                                            }}></div>
                                        </div>
                                            
                                        <div className="w-100 rounded text-black-60 mt-2 border p-3 cn-desc" style={{background:'none'}}>
                                          
                                        <ul className="nav nav-pills mb-3 tab-custom" id="pills-tab" role="tablist">
                                                {
                                                    tabItems?.map((tab, itab)=>(
                                                        <li className="nav-item" style={{marginTop:8}} key={itab+'tab'} role="presentation">
                                                        <button className={`nav-link ${itab==0?'active':''}`} id={`pills-${tab?.value}-tab`} data-bs-toggle="pill" data-bs-target={`#pills-${tab?.value}`} type="button" role="tab" aria-controls={`pills-${tab?.value}`} aria-selected="true">
                                                            {tab?.name}
                                                        </button>
                                                        </li>
                                                    ))
                                                }
                                                
                                            </ul>
                                            <div class="tab-content" id="pills-tabContent">
                                                <div class="tab-pane fade show active" id="pills-ranking" role="tabpanel" aria-labelledby="pills-ranking-tab">
                                                {
                                                    educatorDetail?.educator?.rankings?.length?(<div className='table-responsive'>
                                                        <table className='table table-bordered table-striped'>
                                                            <thead>
                                                                <tr>
                                                                    <th><strong>Publisher Name</strong></th>
                                                                    <th><strong>Rank</strong></th>
                                                                    <th><strong>Description</strong></th>
                                                                    <th><strong>Year</strong></th>
                                                                </tr>
                                                            </thead>
                                                            {}
                                                            <tbody>
                                                                {
                                                                    educatorDetail?.educator?.rankings?.map((ranking, iRanking)=>(
                                                                        <tr key={iRanking+'iRank'}>
                                                                    <td>{ranking?.publisherName}</td>
                                                                    <td>{ranking?.rank}</td>
                                                                    <td>{ranking?.rankingPageText}</td>
                                                                    <td>{ranking?.year}</td>
                                                                </tr>
                                                                    ))
                                                                }
                                                                
                                                            </tbody>
                                                        </table>
                                                    </div>):<h4 className='text-center py-5 color-gray'>Rankings not available</h4>
                                                }
                                                </div>
                                                <div class="tab-pane fade" id="pills-affiliations" role="tabpanel" aria-labelledby="pills-affiliations-tab">
                                                {
                                                    educatorDetail?.educator?.affiliations?.length?(<div className='table-responsive'>
                                                        <table className='table table-bordered table-striped'>
                                                            <thead>
                                                                <tr>
                                                                    <th><strong>Name</strong></th>
                                                                    <th><strong>Type</strong></th>
                                                                </tr>
                                                            </thead>
                                                            {}
                                                            <tbody>
                                                                {
                                                                    educatorDetail?.educator?.affiliations?.map((affiliate, iAffiliate)=>(
                                                                        <tr key={iAffiliate+'affi'}>
                                                                    <td>{affiliate?.name}</td>
                                                                    <td className='text-capitalize'>{affiliate?.type}</td>
                                                                </tr>
                                                                    ))
                                                                }
                                                                
                                                            </tbody>
                                                        </table>
                                                    </div>):<h4 className='text-center py-5 color-gray'>Affiliations not available</h4>
                                                }
                                                </div>
                                                <div class="tab-pane fade" id="pills-courses" role="tabpanel" aria-labelledby="pills-courses-tab">
                                                    {
                                                        educatorDetail?.educator?.courses?.length?(<div className='table-responsive'>
                                                            <table className='table table-bordered table-striped'>
                                                                <thead>
                                                                    <tr>
                                                                        <th><strong>Course Name</strong></th>
                                                                        <th><strong>Time</strong></th>
                                                                    </tr>
                                                                </thead>
                                                                {/* <Link onClick={()=>{navigation(`/educators/course/${course?.slug}`);window.location.reload()}} className='theme-color-hover'></Link> */}
                                                                <tbody>
                                                                    {
                                                                        educatorDetail?.educator?.courses?.map((course, iCourse)=>(
                                                                            <tr key={iCourse+'course'}>
                                                                        <td>{course?.name}</td>
                                                                        <td className='text-capitalize'>{course?.duration_value} {course?.duration_unit}</td>
                                                                    </tr>
                                                                        ))
                                                                    }
                                                                    
                                                                </tbody>
                                                            </table>
                                                        </div>):<h4 className='text-center py-5 color-gray'>Courses not available</h4>
                                                    }
                                                </div>
                                                <div class="tab-pane fade" id="pills-exams" role="tabpanel" aria-labelledby="pills-exams-tab">
                                                    {
                                                            educatorDetail?.educator?.exams?.length?(<div className='table-responsive'>
                                                                <table className='table table-bordered table-striped'>
                                                                    <thead>
                                                                        <tr>
                                                                            <th><strong>Exam Name</strong></th>
                                                                            <th><strong>Time</strong></th>
                                                                        </tr>
                                                                    </thead>
                                                                    {}
                                                                    <tbody>
                                                                        {
                                                                            educatorDetail?.educator?.exams?.map((exam, iExam)=>(
                                                                                <tr key={iExam+'exam'}>
                                                                            <td><Link to={'/'} className='theme-color-hover'>{exam?.name}</Link></td>
                                                                            <td className='text-capitalize'>{exam?.duration_value} {exam?.duration_unit}</td>
                                                                        </tr>
                                                                            ))
                                                                        }
                                                                        
                                                                    </tbody>
                                                                </table>
                                                            </div>):<h4 className='text-center py-5 color-gray'>Exams not available</h4>
                                                        }
                                                </div>
                                                {/* <div class="tab-pane fade" id="pills-admissions" role="tabpanel" aria-labelledby="pills-admissions-tab">
                                                    {
                                                            educatorDetail?.educator?.admissionDetails?(<div className='table-responsive'>
                                                                {educatorDetail?.educator?.admissionDetails}</div>):<h4 className='text-center py-5 color-gray'>Admissions not available</h4>
                                                        }
                                                </div> */}
                                                <div class="tab-pane fade" id="pills-base-courses" role="tabpanel" aria-labelledby="pills-base-courses-tab">
                                                    {
                                                            educatorDetail?.educator?.base_courses?.length?(<div className='table-responsive'>
                                                                <table className='table table-bordered table-striped'>
                                                                    <thead>
                                                                        <tr>
                                                                            <th><strong>Course Name</strong></th>
                                                                            <th><strong>Time</strong></th>
                                                                        </tr>
                                                                    </thead>
                                                                    {}
                                                                    <tbody>
                                                                        {
                                                                            educatorDetail?.educator?.base_courses?.map((course, iCourse)=>(
                                                                                <tr key={iCourse+'course'}>
                                                                            <td><Link to={'/'} className='theme-color-hover'>{course?.name}</Link></td>
                                                                            <td className='text-capitalize'>{course?.maxDuration} {course?.maxDurationUnit}</td>
                                                                        </tr>
                                                                            ))
                                                                        }
                                                                        
                                                                    </tbody>
                                                                </table>
                                                            </div>):<h4 className='text-center py-5 color-gray'>Base courses not available</h4>
                                                        }
                                                </div>
                                                <div class="tab-pane fade" id="pills-gallery" role="tabpanel" aria-labelledby="pills-gallery-tab">
                                                <div className='row'>
                                                {
                                                            educatorDetail?.educator?.medias?.length?educatorDetail?.educator?.medias?.map((media, iMedia)=>(<>
                                                               
                                                                    <div className='col-md-2 mb-3'  key={iMedia+'media'}>
                                                                        <div className='gallery-img-container rounded'>
                                                                            {
                                                                                media?.type=='videos'?<div className='position-relative'><ReactPlayer
                                                                                ref={playerRef}
                                                                                url={media?.mediaUrl}
                                                                                playing={isPlaying}
                                                                                controls={false}
                                                                                width="100%"
                                                                                
                                                                              />
                                                                                <a href={media?.mediaUrl} target='_blank' className="play-pause-button d-flex align-items-center justify-content-center">
                                                                                  {isPlaying ? <PauseIcon style={{fontSize:40}}/> : <PlayArrowIcon  style={{fontSize:40}}/> }
                                                                                </a>
                                                                              </div>:<img onClick={()=>{setChoosenGallery(media?.mediaUrl);lightBoxHandler(true, iMedia)}} className='cursor-pointer' src={media?.mediaUrl} />
                                                                            }
                                                                            
                                                                            <span className='gallery-location'>{media?.mediaTitle}</span>
                                                                        </div>
                                                                    </div>
                                                            </>)):<h4 className='text-center py-5 color-gray'>Gallery not available</h4>

                                                }
                                                </div>
                                                </div>
                                                <div class="tab-pane fade" id="pills-scholorships" role="tabpanel" aria-labelledby="pills-scholorships-tab">
                                                        {
                                                            educatorDetail?.educator?.scholarships?.length?(<div className='table-responsive'>
                                                                <table className='table table-bordered table-striped'>
                                                                    <thead>
                                                                        <tr>
                                                                            <th><strong>Description</strong></th>
                                                                            <th><strong>Type</strong></th>
                                                                        </tr>
                                                                    </thead>
                                                                    {}
                                                                    <tbody>
                                                                        {
                                                                            educatorDetail?.educator?.scholarships?.map((scholarship, iShip)=>(
                                                                                <tr key={iShip+'sship'}>
                                                                            <td>{scholarship?.description}</td>
                                                                            <td className='text-capitalize'>{scholarship?.scholarship_type_name}</td>
                                                                        </tr>
                                                                            ))
                                                                        }
                                                                        
                                                                    </tbody>
                                                                </table>
                                                            </div>):<h4 className='text-center py-5 color-gray'>Scholarships not available</h4>
                                                        }
                                                </div>
                                                <div class="tab-pane fade" id="pills-event-details" role="tabpanel" aria-labelledby="pills-event-details-tab">
                                                        {
                                                            educatorDetail?.educator?.events?.length?(<div className='table-responsive'>
                                                                <table className='table table-bordered table-striped'>
                                                                    <thead>
                                                                        <tr>
                                                                            <th><strong>Description</strong></th>
                                                                            <th><strong>Type</strong></th>
                                                                        </tr>
                                                                    </thead>
                                                                    {}
                                                                    <tbody>
                                                                        {
                                                                            educatorDetail?.educator?.events?.map((event, iEvent)=>(
                                                                                <tr key={iEvent+'event'}>
                                                                            <td>{event?.name}</td>
                                                                            <td className='text-capitalize'>Events</td>
                                                                        </tr>
                                                                            ))
                                                                        }
                                                                        
                                                                    </tbody>
                                                                </table>
                                                            </div>):<h4 className='text-center py-5 color-gray'>No events available</h4>
                                                        }
                                                </div>
                                                <div className="tab-pane fade" id="pills-address" role="tabpanel" aria-labelledby="pills-address-tab">
                                                    {
                                                        educatorDetail?.educator?.address?<div className='card'>
                                                        <div className="card-body shadow"><strong><i className='fa fa-map-marker theme-color me-2'></i> {educatorDetail?.educator?.address}</strong></div>
                                                        </div>:<h4 className='text-center py-5 color-gray'>Address not available</h4>
                                                    }
                                                    
                                                </div>
                                            </div>
                                           
                                        </div>

                                            
                                           
                                            
                                        </div>

                                    </div>


                                </div>
                            </div>
                            <div className="flex justify-between mt-4">
                                <button id="nextprevbtn"
                                    className={` text-white px-5 py-3 bg-opacity-100 rounded disabled:bg-gray-200 font-medium disabled:text-black`}
                                    style={{ backgroundColor: !educatorDetail?.educator?.prevUrl ? '#ccc' : '#ff7200' }}
                                    disabled={educatorDetail?.educator?.prevUrl?false:true}
                                    onClick={() => {navigation(`/educators/university/${educatorDetail?.educator?.prevUrl?.slug}`);setIsLocationChange(!isLocationChange)}}
                                >
                                    {
                                    nextPrevLoader?<div className="h-5 w-5 mr-2">
                                    <Loadingicon />
                                    </div>:<>Previous</>
                                    }
                                
                                </button>
                                <button id="nextprevbtn"
                                    className={` text-white px-5 py-3 bg-opacity-100 rounded disabled:bg-gray-200 font-medium disabled:text-black`}
                                    style={{ backgroundColor:!educatorDetail?.educator?.nextUrl ? '#ccc' : '#ff7200' }}
                                    disabled={educatorDetail?.educator?.nextUrl?false:true}
                                    onClick={() => {navigation(`/educators/university/${educatorDetail?.educator?.nextUrl?.slug}`); setIsLocationChange(!isLocationChange)}}
                                >
                                    {
                                    nextPrevLoader?<div className="h-5 w-5 mr-2">
                                    <Loadingicon />
                                    </div>:<>Next</>
                                    }
                                </button>
                            </div>
                            {
                                educatorDetail?.reviews?.reviews?.length ?
                                <div className="py-4 px-md-4 px-2 grid grid-cols-1 group-card card-body gap-4 mt-4">
                                            <h5 className="mb-0"><strong>Reviews & Rating</strong></h5>
                                            <hr className="m-0" style={{ borderColor: '#ccc' }} />

                                            {
                                                educatorDetail?.reviews?.reviews?.map((review, index) => (
                                                    <div key={'rating' + index} className="review-container mb-0">
                                                        <div className="d-flex mb-2 align-items-center justify-content-between w-100">
                                                            <div className="d-flex gap-1 align-items-center"><StarRatings
                                                                rating={convertRating(review?.rating)}
                                                                starRatedColor="#F08645"
                                                                changeRating={null}
                                                                numberOfStars={5}
                                                                starDimension="15px"
                                                                starSpacing="0"
                                                                name="rating"
                                                                className="me-2"
                                                            />({review?.rating})
                                                            </div>
                                                            <small className="text-black-50">{convertDateFormat(review?.created_at)}</small>
                                                        </div>
                                                        <div className="w-100">
                                                            {review?.reviews}
                                                        </div>
                                                        <div className="d-flex  mt-2 w-100 gap-2 align-items-center mw-200">
                                                            
                                                                <div className="user-pic">
                                                                    {
                                                                        review?.user?.profile_details?.profileImage && review?.user?.profile_details?.profileImage !== '' ? <img src={CDN_BASE_URL+review?.user?.profile_details?.profileImage} width={25} height={25} style={{borderRadius:100}} />
                                                                           
                                                                            :
                                                                            <img src={require('../../../../assets/images/profile_placeholder.png')} width={25} height={25} />
                                                                    }
                                                                </div>
                                                            <div className='flex-1'>
                                                                    <small className="m-0 hover-theme text-black-50"><em>by </em><strong className="text-capitalize ">{review?.user?.name}</strong></small>
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }

                                        </div> 
                                        : null
                                           
                                        }
                        <div className="py-4 px-md-4 px-2 grid grid-cols-1 group-card card-body gap-4 mt-4">
                            <div className="heading-main position-relative my-3 overflow-hidden">
                                <h5 className="text-left bg-white pr-3 b"><strong>Comments({educatorDetail?.commentCount || 0})</strong></h5>
                                <hr className="heading-hr mb-0" />
                            </div>
                            <EducatorComment cData={educatorDetail} handleSubmitComment={handleSubmitComment}/>
                        </div>
                        </div>


                    </div>
          </>
}
                   
                </>
            )}


            <Modal
                closeModal={openHideRatingModal}
                setIsOpen={setIsOpenRatingModal}
                isOpen={isOpenRatingModal}
                callBack={openHideRatingModal}
                title={'Write A Review'}
                modalFooter={true}
            >
              <Formik
                    enableReinitialize={true}
                    initialValues={{
                        reviews: null,
                        rating:null
                    }}
                    validationSchema={DisplayingErrorMessagesSchemaRating}
                    onSubmit={(values) => {
                        if (values){
                            handleReviewRating(values);
                        }
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        handleSubmit,
                    }) => (
                        <form className="" onSubmit={handleSubmit}>
                           
                            <div className="mb-2">
                            <textarea
                                disabled={false}
                                type={'text'}
                                name={'reviews'}
                                autoComplete={'off'}
                                id={'reviews'}
                                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                placeholder={'Write A Review'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                rows={5}
                                
                            // value={}
                            ></textarea>
                            <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                {touched['reviews'] && errors['reviews'] && errors['reviews']}
                            </div>
                            </div>
                            <div className="mb-3 text-center">
                            
                            <StarRatings
                                rating={ratingVal}
                                starRatedColor="#F08645"
                                starHoverColor="rgb(253 160 104)"
                                changeRating={(newRating)=>{changeRating(newRating);setFieldValue('rating', newRating)}}
                                numberOfStars={5}
                                starDimension="35px"
                                starSpacing="5px"
                                name="rating"
                                />
                                <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                {touched['rating'] && errors['rating'] && errors['rating']}
                            </div>
                            </div>
                            <div className="footer-bottom-modal w-100 d-flex align-items-center justify-content-end">
                                    {/* <button
                                    type="button"
                                    className="bg-tranparent border py-2 px-8 mr-3 rounded-lg border-themecolor text-themecolor text-lg w-[119px]"
                                    onClick={()=>{openHideRatingModal()}}
                                    >
                                    Cancel
                                    </button> */}
                                    <button
                                    type="submit"
                                    disabled={btnLoading}
                                    className="bg-themecolor text-white border py-2 px-8 rounded-lg border-themecolor text-themecolor text-lg disabled:opacity-50 w-[119px] flex justify-center items-center"
                                    // onClick={()=>handleAcceptRejectCancel()}
                                    
                                    >
                                    {btnLoading ? (
                <div className="h-5 w-5">
                    <Loadingicon />
                </div>
            ) : 'Submit'
        }
                                    </button>  
                            </div>
                        </form>
                    )}
                </Formik>
             
            </Modal>


            {/* modal for report */}


            <Modal
                closeModal={openHideReportModal}
                setIsOpen={setIsOpenReportModal}
                isOpen={isOpenReportModal}
                callBack={openHideReportModal}
                title={'Report Against Educator'}
                modalFooter={true}
            >
              <Formik
                    enableReinitialize={true}
                    initialValues={{
                        report:null
                    }}
                    validationSchema={DisplayingErrorMessagesSchemaReport}
                    onSubmit={(values) => {
                        if (values){
                            handleReport(values);
                        }
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        handleSubmit,
                    }) => (
                        <form className="" onSubmit={handleSubmit}>
                           
                            <div className="mb-2">
                            <textarea
                                disabled={false}
                                type={'text'}
                                name={'report'}
                                autoComplete={'off'}
                                id={'report'}
                                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                placeholder={'Write message'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                rows={5}
                                
                            // value={}
                            ></textarea>
                            <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                {touched['report'] && errors['report'] && errors['report']}
                            </div>
                            </div>
                            
                            <div className="footer-bottom-modal w-100 d-flex align-items-center justify-content-end">
                                    {/* <button
                                    type="button"
                                    className="bg-tranparent border py-2 px-8 mr-3 rounded-lg border-themecolor text-themecolor text-lg w-[119px]"
                                    onClick={()=>{openHideReportModal()}}
                                    >
                                    Cancel
                                    </button> */}
                                    <button
                                    type="submit"
                                    disabled={btnLoading}
                                    className="bg-themecolor text-white border py-2 px-8 rounded-lg border-themecolor text-themecolor text-lg disabled:opacity-50 w-[119px] flex justify-center items-center"
                                    // onClick={()=>handleAcceptRejectCancel()}
                                    
                                    >
                                    {btnLoading ? (
                <div className="h-5 w-5">
                    <Loadingicon />
                </div>
            ) : 'Submit'
        }
                                    </button>  
                            </div>
                        </form>
                    )}
                </Formik>
             
            </Modal>

            {/* modal for claim account */}
            <Modal
                closeModal={openHideClaimAccountModal}
                setIsOpen={setIsOpenClaimAccountModal}
                isOpen={isOpenClaimAccountModal}
                callBack={openHideClaimAccountModal}
                title={'Claim Account?'}
                modalFooter={true}
            >
              <Formik
                    enableReinitialize={true}
                    initialValues={{
                        report:null
                    }}
                    validationSchema={DisplayingErrorMessagesSchemaClaimAccount}
                    onSubmit={(values) => {
                        if (values){
                            handleClaimAccount(values);
                        }
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        handleSubmit,
                    }) => (
                        <form className="" onSubmit={handleSubmit}>
                           
                            <div className="mb-2">
                            <textarea
                                disabled={false}
                                type={'text'}
                                name={'description'}
                                autoComplete={'off'}
                                id={'description'}
                                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                placeholder={'Describe your claim here'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                rows={5}
                                
                            // value={}
                            ></textarea>
                            <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                {touched['description'] && errors['description'] && errors['description']}
                            </div>
                            </div>
                            
                            <div className="footer-bottom-modal w-100 d-flex align-items-center justify-content-end">
                                    {/* <button
                                    type="button"
                                    className="bg-tranparent border py-2 px-8 mr-3 rounded-lg border-themecolor text-themecolor text-lg w-[119px]"
                                    onClick={()=>{openHideReportModal()}}
                                    >
                                    Cancel
                                    </button> */}
                                    <button
                                    type="submit"
                                    disabled={btnLoading}
                                    className="bg-themecolor text-white border py-2 px-8 rounded-lg border-themecolor text-themecolor text-lg disabled:opacity-50 w-[119px] flex justify-center items-center"
                                    // onClick={()=>handleAcceptRejectCancel()}
                                    
                                    >
                                    {btnLoading ? (
                <div className="h-5 w-5">
                    <Loadingicon />
                </div>
            ) : 'Claim'
        }
                                    </button>  
                            </div>
                        </form>
                    )}
                </Formik>
             
            </Modal>
                
            <LightBox
				state={toggle}
            event={lightBoxHandler}
            data={educatorDetail?.educator?.medias?.filter(fm=>fm?.type=='photos').map(m=>{return {image:m?.mediaUrl, title:''}})}
            imageWidth="60vw"
            imageHeight="70vh"
            thumbnailHeight={50}
            thumbnailWidth={50}
            setImageIndex={setSIndex}
            imageIndex={sIndex}
            
                />
        </>
    );
}

export default EducatorDetail;
