import React, { useEffect, useState } from 'react';
import SectionDescription from '../../../common/sectionDescription';
import { ERROR_SOMETHING_WENT_WRONG, slugToNormal } from '../../../../utils/utils';
import { toast } from "react-toastify";
import { Loadingicon } from '../../../../AppIcons';
import ViewAllButton from '../../../common/viewallbutton';
import PopularCareerCategories from '../popularcategories';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { getQuotesByAuthor, getQuotesByCategory } from '../../../../api/quotes';
import { useAuth } from '../../../../Context/AuthContext';
import QList from '../common/lyricsList';
import { getAllLyricsByAlbum, getAllLyricsByArtist, getSearchedData } from '../../../../api/lyrics';
import LList from '../common/lyricsList';
import NotFound from '../../../common/notfound';


function LyricsListByArtist() {
    const { globalToken } = useAuth();
    const [pageNo, setPageNo] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isNotFound, setIsNotFound] = useState(false);
    const [loadingLoadMore, setLoadingLoadMore] = useState(false);
    const [allLyrics, setAllLyrics] = useState([]);
    const [artistId, setArtistId] = useState();
    const [artistSlug, setArtistSlug] = useState();
    const [isSearch, setIsSearch] = useState(false);
    const [searchTerm, setSearchTerm] = useState();
    const [totalRecordFound, setTotalRecordFound] = useState(0);
    const params = useParams();
    const navigation = useNavigate();   
    useEffect(() => {
        if(params?.artistId){
            setArtistId(params?.artistId);
            setArtistSlug(params?.albumSlug);
            handleFetchAllLyrics(1, params?.artistId);
        }        
    }, [])

    // fetch all lyrics by artist
    const handleFetchAllLyrics = async (pageNo, aId) => {
        const resAllLyricsByArtist = await getAllLyricsByArtist({ pageNo: pageNo, artistId: aId});
        if (resAllLyricsByArtist?.success) {
            console.log('lyricslisting', resAllLyricsByArtist?.data);
            setIsNotFound(false)
            resAllLyricsByArtist?.next_page_url ? setShowLoadMore(true) : setShowLoadMore(false);
            if (resAllLyricsByArtist?.data?.length && pageNo == 1) {
                setAllLyrics(resAllLyricsByArtist?.data);
            } else if (resAllLyricsByArtist?.data?.length && pageNo !== 1) {
                setAllLyrics([...allLyrics, ...resAllLyricsByArtist?.data]);
            } else {
                if (pageNo == 1) {
                    setAllLyrics([]);
                    setIsNotFound(true);
                }
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }

        setLoading(false);
        setLoadingLoadMore(false);
    }

    // load more lyrics data
    const loadMore = () => {
        setLoadingLoadMore(true);
        const nxtPage = pageNo + 1;
        
        if (searchTerm?.length) {
            searchLyric(nxtPage, searchTerm, 'loadMore');
        }else{
            if(artistId)
                handleFetchAllLyrics(nxtPage, artistId);
        }
        setPageNo(nxtPage);
    }


    const handleSearchLyric = (e) => {
        const searchTxt = e.target.value;
        setPageNo(1);
        setSearchTerm(searchTxt);
       
        if (searchTxt == '') {   
            setIsSearch(false);           
            setLoading(true);          
            handleFetchAllLyrics(1, artistId);
            return;
        }
        if (searchTxt !== '' && e.key === "Enter") {
            setIsSearch(false);
            searchLyric(1, searchTxt);
        }
    }

    const searchLyric = async (pNo, keyword, loadMore=null) => {
        !loadMore && setLoading(true);
        const resSearchLyric = await getSearchedData({ pageNo: pNo, keyword });
        setIsSearch(true);

        if (resSearchLyric?.success) {
            setTotalRecordFound(resSearchLyric?.total || 0)
            setIsNotFound(false)
            resSearchLyric?.next_page_url ? setShowLoadMore(true) : setShowLoadMore(false);
            if (resSearchLyric?.data?.length && pNo == 1) {
                setAllLyrics(resSearchLyric?.data);
            } else if (resSearchLyric?.data?.length && pNo !== 1) {
                setAllLyrics([...allLyrics, ...resSearchLyric?.data]);
            } else {
                if (pageNo == 1) {
                    setAllLyrics([]);
                    setIsNotFound(true);
                }
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }
        setLoading(false);
        setLoadingLoadMore(false);
    }

    const popularCareerCats = [
        {
            name: "Joyce Meyer",
            value: "Joyce Meyer",
            redirectUrl: "#"
        },
        {
            name: "Henry Rollins",
            value: "Henry Rollins",
            redirectUrl: "#"
        },
        {
            name: "Helen Mirren",
            value: "Helen Mirren",
            redirectUrl: "#"
        },
        {
            name: "Justin Trudeau",
            value: "Justin Trudeau",
            redirectUrl: "#"
        },
        {
            name: "Helen Fisher",
            value: "Helen Fisher",
            redirectUrl: "#"
        },
        {
            name: "Maya Angelou",
            value: "Maya Angelou",
            redirectUrl: "#"
        },
        {
            name: "Helen Keller",
            value: "Helen Keller",
            redirectUrl: "#"
        },
        {
            name: "Julie Sweet",
            value: "Julie Sweet",
            redirectUrl: "#"
        },
        {
            name: "More ...",
            value: "More ...",
            redirectUrl: "#"
        }
    ];
    


    return (<>
        {/* <BreadCrumbs /> */}
        <div className='d-flex align-items-center justify-content-between'>
        <nav aria-label="breadcrumb" className="pt-0 px-0 pb-0 breadcrumb-card">
                <ol className="breadcrumb px-0 pt-0 breadcrumb-main-mobilea" style={{ display: 'flex', flexWrap: 'nowrap' }}>
                    <li className={`breadcrumb-item capitalize rest-bread`}>
                    <NavLink to='/' style={{ fontSize: 14, textDecoration: 'none' }}>Home</NavLink>
                    </li>    
                    <li className={`breadcrumb-item capitalize rest-bread`}>
                    <NavLink to='/lyrics/artists' style={{ fontSize: 14, textDecoration: 'none' }}>Artists</NavLink>
                    </li> 
                
                    <li 
                        className={`breadcrumb-item capitalize active clamping ellipsis`}
                        >
                        {slugToNormal(params?.artistSlug)}
                    </li>
                    
      </ol>
    </nav>
            {/* <div className="search-boxs px-2 d-flex  align-items-center border rounded bg-white d-flex justify-content-between" style={{ marginBottom: '1rem' }}><input type="text" autocomplete="chrome-off" onKeyUp={handleSearchLyric} className="searchInput px-md-3 px-0 form-control border-0 bg-transparent outline-0" placeholder="Search Lyrics" />
                <i className="fa fa-search opacity-1 cursor-pointer" style={{ color: '#ff7200' }} onClick={() => searchLyric(1, searchTerm)}></i>
            </div> */}
        </div>
        
        <div className='row'>
            <div className='col-md-12'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1">
                    {
                        !isSearch?<> <div className="d-flex align-items-center justify-content-between">
                        <SectionDescription title={`<strong>Top 10 ${slugToNormal(params?.artistSlug)} Lyrics</strong>`} subTitle={`
<p class="mb-0">Explore popular lyrics from  ${slugToNormal(params?.artistSlug)}. Every song on this list is full of meaning and well worth checking out!</p>`} />
                    </div>

                    <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-4" /></>:null
                    }
                   
                   {
                        isSearch ? <><h6 className="text-black-50s label label-default w-100 py-2">Search Result For '{searchTerm}' : {totalRecordFound} Result Found</h6><hr style={{ borderColor: '#f0f0f0', marginTop: 0 }} /></> : null
                    }
                    {loading ? (
                        <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                            <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                                <div className="h-10 w-10">
                                    <Loadingicon />
                                </div>
                            </div>
                        </div>) : isNotFound ? (
                            <NotFound/>) : <>
                        {
                            allLyrics?.map((lyric, sIndex) => (
                                <LList lyric={lyric} key={sIndex} type="lByArtist" redirectionFn={(e)=>{e.stopPropagation();e.preventDefault(); navigation(`/lyrics/detail/${lyric?.id}/${lyric?.slug}`)}}/>
                            ))
                        }
                    </>
                    }
                </div>
                {
                    showLoadMore ? <ViewAllButton
                        disabled={false}
                        redirectionUrl={'/'}
                        handleClickFn={loadMore}
                        btnText={'Load More'}
                        type={'button'}
                        btnClass={'loadButton card-btn-1'}
                        loading={loadingLoadMore}
                    /> : null
                }

            </div>
            {/* <div className='col-md-3'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1 position-sticky" style={{ top: 80 }}>
                    <PopularCareerCategories catsList={popularCareerCats} title={`Popular Categories`}/>
                </div>
            </div> */}
        </div>

    </>)
}

export default LyricsListByArtist;
