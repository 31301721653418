import React, { useEffect, useState } from 'react';
import SectionDescription from '../../../common/sectionDescription';
import BreadCrumbs from '../../../common/BreadCrumbs';
import { CDN_BASE_URL, ERROR_SOMETHING_WENT_WRONG, nFormatter, slugToNormal, yearSatra } from '../../../../utils/utils';
import { getAllCityListing, getAllPatternListing, getAllStreams } from '../../../../api/careernews';
import { toast } from "react-toastify";
import { Loadingicon } from '../../../../AppIcons';
import ViewAllButton from '../../../common/viewallbutton';
import PopularCareerCategories from '../popularcategories';
import StreamBranchList from '../common/streambranchlist';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import NotFound from '../../../common/notfound';


function NewsCityJobsList() {
    const [pageNo, setPageNo] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isNotFound, setIsNotFound] = useState(false);
    const [loadingLoadMore, setLoadingLoadMore] = useState(false);
    const [allStreams, setAllStreams] = useState([]);

    const navigation = useNavigate();
    const params = useParams();

    useEffect(() => {
        if(params?.stateId){           
            handleNewsCityList(1, params?.stateId);
        }        
    }, [])

    // fetch all pattern list
    const handleNewsCityList = async (pageNo, stateId) => {
        const resCityList = await getAllCityListing({ pageNo: pageNo, stateId:stateId, type:'jobs' });
       
        if (resCityList?.success) {
            setIsNotFound(false)
            resCityList?.next_page_url ? setShowLoadMore(true) : setShowLoadMore(false);
            if (resCityList?.data?.length && pageNo == 1) {
                setAllStreams(resCityList?.data);
            } else if (resCityList?.data?.length && pageNo !== 1) {
                setAllStreams([...allStreams, ...resCityList?.data]);
            } else {
                if (pageNo == 1) {
                    setAllStreams([]);
                    setIsNotFound(true);
                }
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }

        setLoading(false);
        setLoadingLoadMore(false);
    }

    // load more streams data
    const loadMore = () => {
        setLoadingLoadMore(true);
        const nxtPage = pageNo + 1;
        handleNewsCityList(nxtPage);
        setPageNo(nxtPage);
    }

    const popularCareerCats = [
        {
            name: "Latest Govt Jobs",
            value: "Latest Govt Jobs",
            redirectUrl: "#"
        },
        {
            name: "Govt Jobs By Qualification",
            value: "Govt Jobs By Qualification",
            redirectUrl: "#"
        },
        {
            name: "Govt Jobs By Location",
            value: "Govt Jobs By Location",
            redirectUrl: "#"
        },
        {
            name: "Latest Private Jobs",
            value: "Latest Private Jobs",
            redirectUrl: "#"
        },
        {
            name: "Private Jobs By Qualification",
            value: "Private Jobs By Qualification",
            redirectUrl: "#"
        },
        {
            name: "Private Jobs By Location",
            value: "Private Jobs By Location",
            redirectUrl: "#"
        },
        {
            name: "Latest Internships",
            value: "Latest Internships",
            redirectUrl: "#"
        },
        {
            name: "Internships By Education",
            value: "Internships By Education",
            redirectUrl: "#"
        },
        {
            name: "Internships By Location",
            value: "Internships By Location",
            redirectUrl: "#"
            
        }
    ];


    return (<>
        {/* <BreadCrumbs /> */}
        <nav aria-label="breadcrumb" className="pt-0 px-0 pb-0 breadcrumb-card">
                <ol className="breadcrumb px-0 pt-0 breadcrumb-main-mobilea" style={{ display: 'flex', flexWrap: 'nowrap' }}>
                    <li className={`breadcrumb-item capitalize rest-bread`}>
                    <NavLink to='/' style={{ fontSize: 14, textDecoration: 'none' }}>Home</NavLink>
                    </li>  
                    <li className={`breadcrumb-item capitalize rest-bread`}>
                    <NavLink to='/career-news' style={{ fontSize: 14, textDecoration: 'none' }}>Career News</NavLink>
                    </li>                      
                    <li 
                        className={`breadcrumb-item capitalize rest-bread`}
                        >                        
                            <NavLink
                            to={'/career-news/latest-exams-employment-news-today'} 
                            style={{ fontSize: 14, textDecoration: 'none' }}                          
                            dangerouslySetInnerHTML={{ __html: `top 10 trending branches exams` }}
                            />                       
                    </li>
                    <li 
                        className={`breadcrumb-item capitalize rest-bread`}
                        >                        
                            <NavLink
                            to={`/career-news/latest-exams-employment-news-today/jobs-location`} 
                            style={{ fontSize: 14, textDecoration: 'none' }}                          
                            dangerouslySetInnerHTML={{ __html: 'jobs by location'}}
                            />                       
                    </li>
                    <li 
                        className={`breadcrumb-item capitalize active clamping ellipsis`}
                        >
                        {slugToNormal(params?.stateSlug)}
                    </li>
                    
      </ol>
    </nav>
        <div className='row'>
            <div className='col-md-9'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1">
                    <div className="d-flex align-items-center justify-content-between">
                        <SectionDescription title={`<strong>Latest Government, Public and Private Sector Jobs in ${slugToNormal(params?.stateSlug)} for Session ${yearSatra()}</strong>`} subTitle={`Get alerts for all Government, Public and Private Sector jobs in ${slugToNormal(params?.stateSlug)}! Practice for Banking, Railways, Defense, Civil Services entrance and more in Question Bank. Prepare for any job interview with Interview Questions`} />
                    </div>

                    <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-4" />

                    {loading ? (
                        <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                            <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                                <div className="h-10 w-10">
                                    <Loadingicon />
                                </div>
                            </div>
                        </div>) : isNotFound ? (
                            <NotFound/>) : <>
                        {
                            allStreams.filter(aData=>aData.city_name!=null)?.map((stream, sIndex) => (<>
                            
                                <StreamBranchList stream={stream} thumbPrefix={`thcn/city/thumb/`} key={sIndex} type="cityList" redirectionFn={()=>navigation(`/career-news/jobs/city-list/${params?.stateId}/${stream?.city_id}/${params?.stateSlug}/${stream?.city_slug}/news`)}/>
                                </>
                            ))
                        }
                    </>
                    }
                </div>
                {
                    showLoadMore ? <ViewAllButton
                        disabled={false}
                        redirectionUrl={'/'}
                        handleClickFn={loadMore}
                        btnText={'Load More'}
                        type={'button'}
                        btnClass={'loadButton card-btn-1'}
                        loading={loadingLoadMore}
                    /> : null
                }

            </div>
            <div className='col-md-3'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1 position-sticky" style={{ top: 80 }}>
                  
                    {/* <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-4" /> */}
                    <PopularCareerCategories catsList={popularCareerCats} title={`Popular Categories`}/>
                </div>
            </div>
        </div>

    </>)
}

export default NewsCityJobsList;
