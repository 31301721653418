import React, { useEffect, useState } from 'react';
import SectionDescription from '../../../common/sectionDescription';
import BreadCrumbs from '../../../common/BreadCrumbs';
import { CDN_BASE_URL, ERROR_SOMETHING_WENT_WRONG, nFormatter, slugToNormal, yearSatra } from '../../../../utils/utils';
import { getAllStreams } from '../../../../api/careernews';
import { toast } from "react-toastify";
import notFound from '../../../../assets/images/not_found_new.png';
import { Loadingicon } from '../../../../AppIcons';
import ViewAllButton from '../../../common/viewallbutton';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteBlog, getBlogCategory, getBlogListing, getBlogSubCategory, getMyBlogsList } from '../../../../api/blog';
import BlogCatSubCatList from '../common/catsubcat';
import BlogListItem from '../common/blogslist';
import { useAuth } from '../../../../Context/AuthContext';
import Modal from "react-bootstrap/Modal";
import NotFound from '../../../common/notfound';
function MyBlogsList() {

    const [pageNo, setPageNo] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [btnLoading, setBtnLoading] = useState(false);
    const [isNotFound, setIsNotFound] = useState(false);
    const [loadingLoadMore, setLoadingLoadMore] = useState(false);
    const [tabLoading, setTabLoading] = useState(true);
    const [allContent, setAllContent] = useState([]);
    const [blogSubCatId, setBlogSubCatId] = useState();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [choosenBlog, setChoosenBlog] = useState();
    const navigation = useNavigate();
    const { globalToken } = useAuth();
    const [filterType, setFilterType] = useState(0);

    const params = useParams();

    useEffect(() => {
        handleMyBlogs(0, 1);
    }, [])

    const handleDeleteModal = (cBlog) => {
        setChoosenBlog(cBlog)
        setShowDeleteModal(!showDeleteModal);
    };

    // fetch all blogs by user
    const handleMyBlogs = async (filterType, pageNo) => {
        const resAllBlogSubCategory = await getMyBlogsList({ pageNo: pageNo, token: globalToken, filterType: filterType });
        if (resAllBlogSubCategory?.success) {
            setIsNotFound(false)
            resAllBlogSubCategory?.data?.next_page_url ? setShowLoadMore(true) : setShowLoadMore(false);
            // console.log(resAllBlogSubCategory);
            if (resAllBlogSubCategory?.data?.data?.length && pageNo == 1) {
                setAllContent(resAllBlogSubCategory?.data?.data);
            } else if (resAllBlogSubCategory?.data?.data?.length && pageNo !== 1) {
                setAllContent([...allContent, ...resAllBlogSubCategory?.data?.data]);
            } else {
                if (pageNo == 1) {
                    setAllContent([]);
                    setIsNotFound(true);
                }
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }

        setLoading(false);
        setLoadingLoadMore(false);
        setTabLoading(false)
    }


    //load more blog by logged user
    const loadMore = () => {
        setLoadingLoadMore(true);
        const nxtPage = pageNo + 1;
        handleMyBlogs(filterType, nxtPage);
        setPageNo(nxtPage);
    }


    const handleDeleteBlog = async () => {
        setBtnLoading(true);
        const resDeleteBlog = await deleteBlog({ token: globalToken, articleId: choosenBlog?.id })
        if (resDeleteBlog.success) {
            toast.success(resDeleteBlog?.data)
            handleMyBlogs(filterType, pageNo);
            handleDeleteModal();
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }
        setBtnLoading(false);
    }

    const handleFilterChange = (v) => {
        setTabLoading(true)
        setAllContent([])
        const fValue = v;
        setFilterType(fValue);
        handleMyBlogs(fValue, pageNo);
    }

    const tabItems = [{
        name: 'Pending',
        value: 0 
    }, {
        name: 'Drafted',
        value: 3
    }, {
        name: 'Approved',
        value: 1
    }
    ]


    
    return (<>
        <BreadCrumbs />
        <div className='row'>
            <div className='col-md-12'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1">
                    <div className="d-md-flex align-items-center justify-content-between">
                        <SectionDescription title={`<strong> My Blogs</strong>`} subTitle={`Dive into a world of knowledge and inspiration`} />
                        <div className='btn-group question-box question-box-card mw-220 d-flex justify-content-end'>
                            {/* <div className='filter-container me-2' style={{ minWidth: 200 }}>
                                <select className='form-control mw-100' onChange={handleFilterChange}>
                                    <option value={0}>Pending</option>
                                    <option value={1}>Approved</option>
                                </select>
                            </div> */}
                            <button
                                className="btn-soft cursor-pointer hover:bg-themecolor text-white" style={{ backgroundColor: '#ff7200' }}
                                onClick={() => navigation('/blogs/post-a-blog')}
                            >
                                Post A Blog
                            </button>
                        </div>
                    </div>

                    <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-4" />

                    {loading ? (
                        <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                            <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                                <div className="h-10 w-10">
                                    <Loadingicon />
                                </div>
                            </div>
                        </div>) 
                        // : isNotFound ? (<>
                        //     <ul className="nav nav-pills mb-3 tab-custom" id="pills-tab" role="tablist">
                        //     {
                        //         tabItems?.map((tab, itab) => (
                        //             <li className="nav-item" key={itab + 'tab'} role="presentation">
                        //                 <button className={`nav-link ${itab == 0 ? 'active' : ''}`} id={`pills-${tab?.value}-tab`} data-bs-toggle="pill" data-bs-target={`#pills-${tab?.value}`} type="button" role="tab" aria-controls={`pills-${tab?.value}`} aria-selected="true" onClick={()=>handleFilterChange(tab?.value)}>
                        //                     {tab?.name}
                        //                 </button>
                        //             </li>
                        //         ))
                        //     }

                        // </ul>
                        // <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-4" />
                        //     <div className="flex-column not-found d-flex text-center" >
                        //         <img src={notFound} className="notFoundImg" />
                        //         <span style={{ opacity: 0.7 }}>No data found</span>
                        //     </div>
                        //     </>)
                            : <div className='row'>
                            <ul className="nav nav-pills mb-3 tab-custom" id="pills-tab" role="tablist">
                            {
                                tabItems?.map((tab, itab) => (
                                    <li className="nav-item" key={itab + 'tab'} role="presentation">
                                        <button className={`nav-link ${itab == 0 ? 'active' : ''}`} id={`pills-${tab?.value}-tab`} data-bs-toggle="pill" data-bs-target={`#pills-${tab?.value}`} type="button" role="tab" aria-controls={`pills-${tab?.value}`} aria-selected="true" onClick={()=>handleFilterChange(tab?.value)}>
                                            {tab?.name}
                                        </button>
                                    </li>
                                ))
                            }

                        </ul>
                        <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-4" />
                        <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-0" role="tabpanel" aria-labelledby="pills-ranking-tab">
                                {
                                    (!tabLoading && !allContent?.length)?<NotFound message={'No data found'} />:tabLoading? (
                                        <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                                            <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[200px]">
                                                <div className="h-10 w-10">
                                                    <Loadingicon />
                                                </div>
                                            </div>
                                        </div>) :allContent?.map((content, sIndex) => (<>
                                        <BlogListItem handleDeleteModal={handleDeleteModal} isEdit={true} blogData={content} redirectionFn={() => navigation(`/blogs/detail/${content?.slug}-${content?.id}`)} key={sIndex} />
                                    </>
                                    ))
                                }
                            </div>
                            <div class="tab-pane fade" id="pills-1" role="tabpanel" aria-labelledby="pills-ranking-tab">
                                {
                                   (!tabLoading && !allContent?.length)?<NotFound message={'No data found'} />:tabLoading? (
                                    <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                                        <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[200px]">
                                            <div className="h-10 w-10">
                                                <Loadingicon />
                                            </div>
                                        </div>
                                    </div>):allContent?.map((content, sIndex) => (<>
                                    <BlogListItem handleDeleteModal={handleDeleteModal} isEdit={true} blogData={content} redirectionFn={() => navigation(`/blogs/detail/${content?.slug}-${content?.id}`)} key={sIndex} />
                                </>
                                ))
                                }
                            </div>
                            <div class="tab-pane fade" id="pills-3" role="tabpanel" aria-labelledby="pills-ranking-tab">
                                {
                                    (!tabLoading && !allContent?.length)?<NotFound message={'No data found'} />:tabLoading? (
                                        <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                                            <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[200px]">
                                                <div className="h-10 w-10">
                                                    <Loadingicon />
                                                </div>
                                            </div>
                                        </div>):allContent?.map((content, sIndex) => (<>
                                        <BlogListItem handleDeleteModal={handleDeleteModal} isEdit={true} blogData={content} redirectionFn={() => navigation(`/blogs/detail/${content?.slug}-${content?.id}`)} key={sIndex} />
                                    </>
                                    ))
                                }
                            </div>
                        </div>

                    </div>
                    }
                </div>
                {
                    showLoadMore ? <ViewAllButton
                        disabled={false}
                        redirectionUrl={'/'}
                        handleClickFn={loadMore}
                        btnText={'Load More'}
                        type={'button'}
                        btnClass={'loadButton card-btn-1'}
                        loading={loadingLoadMore}
                    /> : null
                }

            </div>

        </div>

        {/* delete alert START  */}
        <Modal
            show={showDeleteModal}
            onHide={handleDeleteModal}

            centered
        >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <strong>Confirm : </strong> Do you wish to permanently remove this blog?
                <div className="card mt-4">
                    <div className="card-body bg-gray running">
                        <h6><strong>Blog: <span className="color-gray">{choosenBlog?.title}</span></strong></h6>
                    </div>
                </div>

            </Modal.Body>
            <Modal.Footer style={{ border: "none" }}>
                <button
                    type="button"
                    disabled={btnLoading}
                    className="btn btn-warning custom-theme-button d-flex align-items-center"
                    onClick={() => handleDeleteBlog()}
                >
                    {btnLoading ? (
                        <div className="h-5 w-5">
                            <Loadingicon />
                        </div>
                    ) : (
                        "Yes"
                    )}

                </button>
                <button
                    type="button"
                    className="btn btn-light d-flex align-items-center ms-2"
                    onClick={() => handleDeleteModal()}
                >
                    No{" "}
                </button>
            </Modal.Footer>
        </Modal>
        {/* delete alert popup END  */}
    </>)
}

export default MyBlogsList;