import React from 'react'
import { Link } from 'react-router-dom';
import { nFormatter } from '../../../../utils/utils';
import qbPlaceHolder from '../../../../assets/images/qb_placeholder.png';

export default function QBRectangleBox({nData=null, type=null, mH=null, boxSize=null, redirectionFn=null, thumbBaseUrl=null}) {
    return (
        <div className={`${boxSize} mb-2 cursor-pointer hover-c-change`} onClick={()=>redirectionFn()} title={nData.subject_title || nData.name}>
            <div className={`col-md-12 col-12 py-0 col-sm-12 left-angle-border card_box ${mH?'mh-119':'mh-93'}`}>
                <div className='d-flex align-items-center'>
                <span className="books_icon bg-transparents me-2 px-3 ms-1 d-flex align-items-center" style={{minWidth:50, minHeight:50}}>                    
                    {
                        // `${thumbBaseUrl}/${nData.image}`
                        nData?.image?<img alt={nData.subject_title || nData.name} className="thumbnail rounded" width="40" height="40" src={qbPlaceHolder}                          
                            />:<img src={qbPlaceHolder}  width="40" height="40" className="feature_images rounded  overflow-hidden" alt={nData.subject_title || nData.name} />
                    }              

                </span>
                <span className="w-100 d-flex flex-column">
                    <h6 className="m-0 pr-3 ellipsis-text lquestion" title={nData.subject_title || nData.name}>
                        {nData.subject_title || nData.name}
                    </h6>
                        <span className="span px-1 pb-1 color-gray text-capitalize fsize-11 c-a5">                       
                            {nData.topic_count ? `${nFormatter(nData.topic_count)} Topics` : null}
                           
                            {nData.question_count >= 0 && (
                                <>
                                 <span className="big-dots"></span>
                                 {`${nFormatter(type === 'fullforms' ? nData.fullform_count : type === 'dictionary' ? nData.dictionary_count : type === 'interview-questions' ? nData.interviewq_count : nData.question_count)} ${type === 'fullforms' ? 'Full Forms' : type === 'dictionary' ? 'Word Meanings' : 'Questions'}`}
                                </>                         
                            )}
                        </span>
                    
                </span>
                </div>
                <i className='fa-solid fa-arrow-right-long me-2 text-black-50' style={{opacity:0.5}}></i>
            </div>
        </div>
    )
}
