import React, { useEffect, useState } from 'react';
import SectionDescription from '../../../common/sectionDescription';
import BreadCrumbs from '../../../common/BreadCrumbs';
import { ERROR_SOMETHING_WENT_WRONG, slugToNormal, yearSatra } from '../../../../utils/utils';
import { getBranchDetail, getNewsByCity, getNewsByEducation, getNewsByPattern } from '../../../../api/careernews';
import { toast } from "react-toastify";
import { Loadingicon } from '../../../../AppIcons';
import ViewAllButton from '../../../common/viewallbutton';
import PopularCareerCategories from '../popularcategories';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../../Context/AuthContext';
import BranchDetailList from '../common/branchDetailList';
import NewsListItem from '../common/newsList';
import NotFound from '../../../common/notfound';


function PatternNews() {
    const { globalToken } = useAuth();
    const [pageNo, setPageNo] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isNotFound, setIsNotFound] = useState(false);
    const [loadingLoadMore, setLoadingLoadMore] = useState(false);
    const [newsList, setNewsList] = useState([]);
    const [educationId, setEducationId] = useState();
    const [educationSlug, setEducationSlug] = useState();
    const [cityId, setCityId] = useState();
    const navigation = useNavigate();
    const params = useParams();

    useEffect(() => {
        if(params?.cityId && params?.citySlug && params?.stateId && params?.stateSlug){           
            setCityId(params?.cityid);
            handleFetchAllNewsByPattern(1, params?.cityId, null);
        }else if(params?.educationId && params?.educationSlug){            
            setEducationId(params?.educationId)
            setEducationSlug(params?.educationSlug)
            handleFetchAllNewsByPattern(1, null, params?.educationId);
        }else if(!params?.cityId && !params?.educationId && params.pattern){
            handleFetchAllNewsByPattern(1, null, null);
        }
        else{
            // handleFetchAllNewsByPattern(1, null);
        }
        
    }, [])

    // fetch all news by pattern
    const handleFetchAllNewsByPattern = async (pageNo, cityId, educationId) => {
        const resNewsByCity = params?.pattern=='admissions-education'?await getNewsByEducation({ token:globalToken, pageNo, educationId, type:'exams'}):params?.pattern=='jobs-education'?await getNewsByEducation({ token:globalToken, pageNo, educationId, type:'jobs'}):params?.pattern=='jobs'? await getNewsByCity({ token:globalToken, pageNo, cityId, type:'jobs'}):await getNewsByCity({ token:globalToken, pageNo, cityId, type:'exams'});
        if (resNewsByCity?.success) {
            setIsNotFound(false)
            resNewsByCity?.next_page_url ? setShowLoadMore(true) : setShowLoadMore(false);
            if (resNewsByCity?.data?.length && pageNo == 1) {
                setNewsList(resNewsByCity?.data);
            } else if (resNewsByCity?.data?.length && pageNo !== 1) {
                setNewsList([...newsList, ...resNewsByCity?.data]);
            } else {
                if (pageNo == 1) {
                    setNewsList([]);
                    setIsNotFound(true);
                }
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }

        setLoading(false);
        setLoadingLoadMore(false);
    }

    // load more news data
    const loadMore = () => {
        setLoadingLoadMore(true);
        const nxtPage = pageNo + 1;
        handleFetchAllNewsByPattern(nxtPage, cityId, educationId);
        setPageNo(nxtPage);
    }

    
    const popularCareerCats = [
        {
            name: "Latest Govt Jobs",
            value: "Latest Govt Jobs",
            redirectUrl: "#"
        },
        {
            name: "Govt Jobs By Qualification",
            value: "Govt Jobs By Qualification",
            redirectUrl: "#"
        },
        {
            name: "Govt Jobs By Location",
            value: "Govt Jobs By Location",
            redirectUrl: "#"
        },
        {
            name: "Latest Private Jobs",
            value: "Latest Private Jobs",
            redirectUrl: "#"
        },
        {
            name: "Private Jobs By Qualification",
            value: "Private Jobs By Qualification",
            redirectUrl: "#"
        },
        {
            name: "Private Jobs By Location",
            value: "Private Jobs By Location",
            redirectUrl: "#"
        },
        {
            name: "Latest Internships",
            value: "Latest Internships",
            redirectUrl: "#"
        },
        {
            name: "Internships By Education",
            value: "Internships By Education",
            redirectUrl: "#"
        },
        {
            name: "Internships By Location",
            value: "Internships By Location",
            redirectUrl: "#"
        }
    ];

    return (<>
        {/* <BreadCrumbs /> */}
        <nav aria-label="breadcrumb" className="pt-0 px-0 pb-0 breadcrumb-card">
                <ol className="breadcrumb px-0 pt-0 breadcrumb-main-mobilea" style={{ display: 'flex', flexWrap: 'nowrap' }}>
                    <li className={`breadcrumb-item capitalize rest-bread`}>
                    <NavLink to='/' style={{ fontSize: 14, textDecoration: 'none' }}>Home</NavLink>
                    </li>    
                    <li className={`breadcrumb-item capitalize rest-bread`}>
                    <NavLink to='/career-news' style={{ fontSize: 14, textDecoration: 'none' }}>Career News</NavLink>
                    </li>
                    {
                        params?.pattern=='jobs-location'?<>
                        <li 
                        className={`breadcrumb-item capitalize rest-bread`}
                        >                        
                            <NavLink
                            to={`/career-news/latest-exams-employment-news-today/jobs-location`} 
                            style={{ fontSize: 14, textDecoration: 'none' }}                          
                            dangerouslySetInnerHTML={{ __html: `Jobs by Location` }}
                            />                       
                    </li>               
                    <li 
                        className={`breadcrumb-item capitalize rest-bread`}
                        >                        
                            <NavLink
                            to={`/career-news/jobs/city-list/${params?.stateId}/${params?.stateSlug}`} 
                            style={{ fontSize: 14, textDecoration: 'none' }}                          
                            dangerouslySetInnerHTML={{ __html: slugToNormal(params?.stateSlug) }}
                            />                       
                    </li></>:
                    params?.pattern=='admissions-location'?<>
                    <li 
                    className={`breadcrumb-item capitalize rest-bread`}
                    >                        
                        <NavLink
                        to={`/career-news/latest-exams-employment-news-today/admissions-location`} 
                        style={{ fontSize: 14, textDecoration: 'none' }}                          
                        dangerouslySetInnerHTML={{ __html: `Admissions by Location` }}
                        />                       
                </li>               
                <li 
                    className={`breadcrumb-item capitalize rest-bread`}
                    >                        
                        <NavLink
                        to={`/career-news/admissions/city-list/${params?.stateId}/${params?.stateSlug}`} 
                        style={{ fontSize: 14, textDecoration: 'none' }}                          
                        dangerouslySetInnerHTML={{ __html: slugToNormal(params?.stateSlug) }}
                        />                       
                </li></>:params?.pattern=='jobs-education'?<>
                        <li 
                        className={`breadcrumb-item capitalize rest-bread`}
                        >                        
                            <NavLink
                            to={`/career-news/latest-exams-employment-news-today/jobs-education`} 
                            style={{ fontSize: 14, textDecoration: 'none' }}                          
                            dangerouslySetInnerHTML={{ __html: `Jobs by Education` }}
                            />                       
                    </li> 
                    <li 
                        className={`breadcrumb-item capitalize active clamping ellipsis`}
                        >                        
                           {slugToNormal(`${params.educationSlug} News`)}                    
                    </li>              
                    </>:params?.pattern=='admissions-education'?<>
                        <li 
                        className={`breadcrumb-item capitalize rest-bread`}
                        >                        
                            <NavLink
                            to={`/career-news/latest-exams-employment-news-today/admissions-education`} 
                            style={{ fontSize: 14, textDecoration: 'none' }}                          
                            dangerouslySetInnerHTML={{ __html: `Admissions by Education` }}
                            />                       
                    </li>               
                    <li 
                        className={`breadcrumb-item capitalize active clamping ellipsis`}
                        >                        
                           {slugToNormal(`${params.educationSlug} News`)}                    
                    </li></>:<li 
                        className={`breadcrumb-item capitalize active clamping ellipsis`}
                        >
                        {slugToNormal(`${params?.citySlug || ''}${params?.pattern=='admissions' && cityId?' Admissions':params?.pattern=='jobs' && cityId?' Jobs':' All India'} News`)}
                    </li>
                    }  
                   
                    
                    
                    
      </ol>
    </nav>
        <div className='row'>
            <div className='col-md-9'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1">
                    <div className="d-flex align-items-center justify-content-between">
                        <SectionDescription title={`Latest Jobs for ${slugToNormal(params?.citySlug || params?.educationSlug)} candidates for Session ${yearSatra()}`} subTitle={`Get alerts for all Government, Public and Private Sector jobs requiring ${slugToNormal(params?.citySlug || params?.educationSlug)} candidates in ${yearSatra()} ! Practice for Banking, Railways, Defense, Civil Services entrance and more in Question Bank. Prepare for any job interview with Interview Questions`} />
                    </div>

                    <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-4" />

                    {loading ? (
                        <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                            <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                                <div className="h-10 w-10">
                                    <Loadingicon />
                                </div>
                            </div>
                        </div>) : isNotFound ? (
                            <NotFound/>) : <>
                        {
                            newsList?.map((newsData, sIndex) => (                             
                                <NewsListItem
                                key={sIndex} 
                                newsData={newsData}  
                                type="newsbypattern" 
                                redirectionFn={(e)=>{e.preventDefault();e.stopPropagation();navigation(`/career-news/news/${newsData?.id}/${newsData?.slug}`)}}
                                />
                            ))
                        }

                    </>
                    }
                </div>
                
                {
                    showLoadMore ? <ViewAllButton
                        disabled={false}
                        redirectionUrl={'/'}
                        handleClickFn={loadMore}
                        btnText={'Load More'}
                        type={'button'}
                        btnClass={'loadButton card-btn-1'}
                        loading={loadingLoadMore}
                    /> : null
                }

            </div>
            {/* <div className='col-md-3'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1 position-sticky" style={{ top: 80 }}>
                   
                    <PopularCareerCategories catsList={popularCareerCats} title={`Popular Categories`}/>
                </div>
            </div> */}
        </div>

    </>)
}

export default PatternNews;
