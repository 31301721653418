import React, { useEffect, useState } from "react";
import SectionDescription from "../../../common/sectionDescription";
import {
  ERROR_SOMETHING_WENT_WRONG,
  slugToNormal,
  yearSatra,
} from "../../../../utils/utils";
import { toast } from "react-toastify";
import { Loadingicon } from "../../../../AppIcons";
import ViewAllButton from "../../../common/viewallbutton";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import ForumListItem from "../common/forumlistitem";
import { getForumList, getForumListByTagId, getSearchedData } from "../../../../api/forum";
import BreadCrumbs from "../../../common/BreadCrumbs";
import { useSearch } from "../../../../Context/SearchContext";
import NotFound from "../../../common/notfound";
function ForumList() {
  const [pageNo, setPageNo] = useState(1);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isNotFound, setIsNotFound] = useState(false);
  const [loadingLoadMore, setLoadingLoadMore] = useState(false);
  const [allContent, setAllContent] = useState([]);
  const [forumSubCatSlug, setForumSubCatSlug] = useState();
  const [forumTagId, setForumTagId] = useState();
  
  const [searchTerm, setSearchTerm] = useState();
  const [isSearch, setIsSearch] = useState(false);
  const [totalRecordFound, setTotalRecordFound] = useState(0);
  const navigation = useNavigate();
  const params = useParams();
  const location = useLocation();
  const paramDatas = new URLSearchParams(location.search);
  const { searchValue } = useSearch();

  useEffect(() => {
    const searchTerm = paramDatas?.get('searchTerm');
    if (searchTerm) {
      searchBlog(1, searchTerm);
      setSearchTerm(searchTerm);
    } else if (params?.forumCatSlug && params?.forumSubCatSlug && !params?.tagId) {
      setForumSubCatSlug(params?.forumSubCatSlug);
      handleForumListBySCat(1, params?.forumSubCatSlug);
    }
    else if (params?.forumCatSlug && params?.forumSubCatSlug && params?.tagId) {
      setForumSubCatSlug(params?.forumSubCatSlug);
      handleForumListBySCat(1, params?.tagId);
      setForumTagId(params?.tagId);
    }
  }, []);
  useEffect(() => {
    const searchTerm = paramDatas?.get('searchTerm');
    if (searchTerm) {
      searchBlog(1, searchTerm);
      setSearchTerm(searchTerm);
    } else {
      setSearchTerm('');
      setIsSearch(false)
      if (forumSubCatSlug && !params.tagId) {
        handleForumListBySCat(1, forumSubCatSlug);
      }else{
        handleForumListBySCat(1, params?.tagId);
      }
    }

  }, [searchValue])

  // fetch all forum list by sub category;
  // const handleForumListBySCat = async (pageNo, fSCatSlug) => {
  //   const resForumList = params?.tagId?await getForumListByTagId({
  //     pageNo: pageNo,
  //     forumTagId: fSCatSlug,
  //   }):await getForumList({
  //     pageNo: pageNo,
  //     forumSubCatSlug: fSCatSlug,
  //   });
  //   if (resForumList?.success) {
  //     setIsNotFound(false);
  //     resForumList?.data?.next_page_url
  //       ? setShowLoadMore(true)
  //       : setShowLoadMore(false);
  //     if (resForumList?.data?.data?.length && pageNo == 1) {
  //       setAllContent(resForumList?.data?.data);
  //     } else if (resForumList?.data?.data?.length && pageNo !== 1) {
  //       setAllContent([...allContent, ...resForumList?.data?.data]);
  //     } else {
  //       if (pageNo == 1) {
  //         setAllContent([]);
  //         setIsNotFound(true);
  //       }
  //     }
  //   } else {
  //     toast.error(ERROR_SOMETHING_WENT_WRONG);
  //   }

  //   setLoading(false);
  //   setLoadingLoadMore(false);
  // };

  const handleForumListBySCat = async (pageNo, fSCatSlug) => {
    const resForumList = params?.tagId
      ? await getForumListByTagId({
          pageNo: pageNo,
          forumTagId: fSCatSlug,
        })
      : await getForumList({
          pageNo: pageNo,
          forumSubCatSlug: fSCatSlug,
        });
  
    if (resForumList?.success) {
      setIsNotFound(false);
      const nextPageUrl =
            resForumList?.data?.next_page_url || resForumList?.data?.questions?.next_page_url;

        nextPageUrl ? setShowLoadMore(true) : setShowLoadMore(false);
  
      // Dynamically check for the data path: either `data` or `questions.data`
      const forumData = resForumList?.data?.data || resForumList?.data?.questions?.data;
  
      if (forumData?.length && pageNo == 1) {
        setAllContent(forumData);
      } else if (forumData?.length && pageNo !== 1) {
        setAllContent([...allContent, ...forumData]);
      } else {
        if (pageNo == 1) {
          setAllContent([]);
          setIsNotFound(true);
        }
      }
    } else {
      toast.error(ERROR_SOMETHING_WENT_WRONG);
    }
  
    setLoading(false);
    setLoadingLoadMore(false);
  };
  

  //load more forum by sub category
  const loadMore = () => {
    setLoadingLoadMore(true);
    const nxtPage = pageNo + 1;
    if (searchTerm?.length) {
      searchBlog(nxtPage, searchTerm, 'loadMore');
    } else {
      handleForumListBySCat(nxtPage, forumSubCatSlug);
    }
    setPageNo(nxtPage);
  };

  const handleSearchBlog = (e) => {
    const searchTxt = e.target.value;
    setPageNo(1);
    setSearchTerm(searchTxt);

    if (searchTxt == "") {
      setIsSearch(false);
      setLoading(true);
      handleForumListBySCat(1, forumSubCatSlug);
      return;
    }
    if (searchTxt !== "" && e.key === "Enter") {
      setIsSearch(false);
      searchBlog(1, searchTxt);
    }
  };

  const searchBlog = async (pNo, keyword, loadMore = null) => {
    !loadMore && setLoading(true);
    console.log(pNo);
    const resSearchForums = await getSearchedData({ keyword });
    console.log(resSearchForums);
    setIsSearch(true);
    if (resSearchForums?.success) {
      setTotalRecordFound(resSearchForums?.data?.questions?.total || 0);
      setIsNotFound(false);
      resSearchForums?.data?.questions?.next_page_url
        ? setShowLoadMore(true)
        : setShowLoadMore(false);
      if (resSearchForums?.data?.questions?.data?.length && pNo == 1) {
        setAllContent(resSearchForums?.data?.questions?.data);
      } else if (resSearchForums?.data?.questions?.data?.length && pNo !== 1) {
        setAllContent([
          ...allContent,
          ...resSearchForums?.data?.questions.data,
        ]);
      } else {
        if (pageNo == 1) {
          setAllContent([]);
          setIsNotFound(true);
        }
      }
    } else {
      toast.error(ERROR_SOMETHING_WENT_WRONG);
    }
    setLoading(false);
    setLoadingLoadMore(false);
  };

  return (
    <>
      {/* <BreadCrumbs /> */}
      <div className="d-flex align-items-center justify-content-between">
        {/* <BreadCrumbs /> */}
        <nav
                      aria-label="breadcrumb"
                      className="pt-0 px-0 pb-0 breadcrumb-card mb-3"
                    >
                      <ol
                        className="breadcrumb px-0 pt-0 breadcrumb-main-mobilea mb-0"
                        style={{ display: "flex", flexWrap: "nowrap" }}
                      >
                        <li className={`breadcrumb-item capitalize rest-bread`}>
                          <NavLink
                            to="/"
                            style={{ fontSize: 14, textDecoration: "none" }}
                          >
                            Home
                          </NavLink>
                        </li>
                        <li className={`breadcrumb-item capitalize rest-bread`}>
                          <NavLink
                            to="/forum"
                            style={{ fontSize: 14, textDecoration: "none" }}
                          >
                            Forum
                          </NavLink>
                        </li>
                        <li className={`breadcrumb-item capitalize rest-bread`}>
                            <NavLink
                              to={`/forum/${forumSubCatSlug}`}
                              style={{ fontSize: 14, textDecoration: "none" }}
                            >
                              {slugToNormal(params?.forumCatSlug)}
                            </NavLink>
                        </li>
                        <li
                          className={`breadcrumb-item capitalize active clamping ellipsis`}
                        >
                          {slugToNormal(params?.forumSubCatSlug)}
                        </li>
                      </ol>
                    </nav>
        {/* <div
          className="search-boxs px-2 d-flex  align-items-center border rounded bg-white d-flex justify-content-between"
          style={{ marginBottom: "1rem" }}
        >
          <input
            type="text"
            autocomplete="chrome-off"
            onKeyUp={handleSearchBlog}
            className="searchInput px-md-3 px-0 form-control border-0 bg-transparent outline-0"
            placeholder="Search Blog"
          />
          <i
            className="fa fa-search opacity-1 cursor-pointer"
            style={{ color: "#ff7200" }}
            onClick={() => searchBlog(1, searchTerm)}
          ></i>
        </div> */}
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1">
            <div className="d-md-flex align-items-center justify-content-between">
              <SectionDescription
                title={`<strong>Top ${slugToNormal(
                  forumSubCatSlug
                )} Forum Discussions in ${yearSatra()} : Connect, Learn and Collaborate</strong>`}
                subTitle={`Our Top 10 ${slugToNormal(
                  forumSubCatSlug
                )} Forum Discussion Listing offers a wide array of topics within the ${slugToNormal(
                  params?.forumCatSlug
                )} domain, for diverse interests and expertise levels. From beginners seeking guidance to seasoned professionals looking for advanced insights, you'll find a wealth of discussions to learn and expand your understanding.`}
              />
              <div className="btn-group question-box question-box-card mw-220">
                <button
                  className="btn-soft mr-2 cursor-pointer hover:bg-themecolor text-white"
                  style={{ backgroundColor: "#ff7200" }}
                  onClick={() => navigation("/forum/my-forum")}
                >
                  My Forum
                </button>
                <button
                  className="btn-soft cursor-pointer hover:bg-themecolor text-white"
                  style={{ backgroundColor: "#ff7200" }}
                  onClick={() => navigation("/forum/post-a-question")}
                >
                  Post A Question
                </button>
              </div>
            </div>

            <hr
              style={{ borderColor: "#ccc", marginBottom: 0 }}
              className="mb-4"
            />
            {isSearch ? (
              <>
                <h6 className="text-black-50s label label-default w-100 py-2">
                  Search Result For '{searchTerm}' : {totalRecordFound} Result
                  Found
                </h6>
                <hr style={{ borderColor: "#f0f0f0", marginTop: 0 }} />
              </>
            ) : null}
            {loading ? (
              <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                  <div className="h-10 w-10">
                    <Loadingicon />
                  </div>
                </div>
              </div>
            ) : isNotFound ? (
              <NotFound/>
            ) : (
              <div className="row row-mob">
                {allContent?.map((content, sIndex) => (
                  <ForumListItem
                    forumData={content}
                    redirectionFn={() =>
                      navigation(
                        `/forum/${params?.forumCatSlug}/${params?.forumSubCatSlug}/${content?.slug}-${content?.id}`
                      )
                    }
                    key={sIndex}
                  />
                ))}
              </div>
            )}
          </div>
          {showLoadMore ? (
            <ViewAllButton
              disabled={false}
              redirectionUrl={"/"}
              handleClickFn={loadMore}
              btnText={"Load More"}
              type={"button"}
              btnClass={"loadButton card-btn-1"}
              loading={loadingLoadMore}
            />
          ) : null}
        </div>
      </div>
    </>
  );
}

export default ForumList;
