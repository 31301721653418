import React, { useEffect, useState } from 'react';
import SectionDescription from '../../../common/sectionDescription';
import BreadCrumbs from '../../../common/BreadCrumbs';
import {  convertDateFormat, DASHBOARD_URL, ERROR_SOMETHING_WENT_WRONG, fetchUserInfo, modulesUrls, nFormatter, placeholderImages, slugify, slugToNormal, viewRecentVisits, yearSatra } from '../../../../utils/utils';
// import { bookMarklyricsData, getBranchDetail, getlyricsDataByPattern, getlyricsDataDetail, likeUnlikeDislike } from '../../../../api/careerlyricsData';
import { toast } from "react-toastify";
import { Loadingicon } from '../../../../AppIcons';
import ViewAllButton from '../../../common/viewallbutton';
import PopularCareerCategories from '../popularcategories';
import { Link, NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../../Context/AuthContext';
import CommonComments from '../../../common/comments/commonComments';
import Share from '../../../common/share';
import styles from "../../../../assets/css/styles/Home.module.css";
import { bookMarkLyrics, getLyricsDetail, giveRatingToLyric, likeUnlikeDislike, lyricsCommentAndReply, reportToLyric } from '../../../../api/lyrics';
import StarRatings from 'react-star-ratings';
import LyricsComments from '../../../common/comments/lyricsComments';
import Modal from '../../../common/Modal';



function LyricsDetail() {
    const { globalToken, user, setUser, setFinance, setIsEmailVerifyCover } = useAuth();
    const [pageNo, setPageNo] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const[upvoteBtnLoader, setUpvoteBtnLoader] = useState(false);
    const[downvoteBtnLoader, setDownvoteBtnLoader] = useState(false);
    const [isLocationChange, setIsLocationChange] = useState(false);
    const[nextPrevLoader, setNextPrevLoader] = useState(false);
    const[ratingLoading, setRatingLoading] = useState(false);
    const[reportLoading, setReportLoading] = useState(false);
    const [isRatingOpen, setIsRatingOpen] = useState(false);
    const [isReportOpen, setIsReportOpen] = useState(false);
    const [reportVal, setReportVal] = useState(0);
    const [ratingVal, setRatingVal] = useState(0);
    const [isNotFound, setIsNotFound] = useState(false);
    const [loadingLoadMore, setLoadingLoadMore] = useState(false);
    const [lyricsDetailData, setLyricsDetailData] = useState([]);
    const [posterImageBaseURL, setPosterImageBaseURL] = useState();
    const [lyricsId, setLyricsId] = useState();
    const [lyricsSlug, setLyricsSlug] = useState();
    const [isShareActive, setIsShareActive] = useState(false);
    const navigation = useNavigate();
    const iconShareRef = React.createRef(null);
    const shareRef = React.createRef(null);

    const submitRating = async () => {
        
        setRatingLoading(true);
        const resLyricsRating = await giveRatingToLyric({token:globalToken, lyricId:lyricsDetailData?.id, rating:ratingVal});
        if(resLyricsRating?.success){ 
         toast.success('Rating successfully given!');
         handleFetchLyricsDetail(lyricsDetailData?.id);
        }

        setRatingLoading(false);
        setIsRatingOpen(false);
        setRatingVal(0);
        
    }

    const submitReport = async (msg, lyricId) => {
        setReportLoading(true);
        const resReportToLyric = await reportToLyric({token:globalToken, lyricId:lyricsDetailData?.id, report_message:msg});
        console.log(resReportToLyric)
        if(resReportToLyric?.success){
            toast.success(resReportToLyric?.message);
            handleFetchLyricsDetail(lyricsDetailData?.id);
        }
        setReportLoading(false);
        setIsReportOpen(false);
        setReportVal(null);
    }

    const changeRating = (newRating) => {
        setRatingVal(newRating);
      };

    const [relatedSearches, setRelatedSearches] = useState([{
        name: "NIELIT CCC Admit Card April 2022 Details",
        value: "NIELIT CCC Admit Card April 2022 Details",
        redirectUrl: "#"
    },
    {
        name: "NIELIT CCC Admit Card April 2022",
        value: "NIELIT CCC Admit Card April 2022",
        redirectUrl: "#"
    },
    {
        name: "NIELIT Jobs Notification 2022 – 98 Posts, Salary, Application Form",
        value: "NIELIT Jobs Notification 2022 – 98 Posts, Salary, Application Form",
        redirectUrl: "#"
    },
    {
        name: "Student NIELIT CCC Admit Card May 2022 Exam",
        value: "Student NIELIT CCC Admit Card May 2022 Exam",
        redirectUrl: "#"
    },
    {
        name: "NIELIT",
        value: "NIELIT",
        redirectUrl: "#"
    },
    {
        name: "Nielit 2022",
        value: "Nielit 2022",
        redirectUrl: "#"
    }]);

    const params = useParams();
    const location = useLocation();
    const currentPath = location.pathname;

   
    useEffect(() => {
        if (params?.lyricsId && params?.lyricsSlug) {
            setLyricsId(params?.lyricsId);
            setLyricsSlug(params?.lyricsSlug)
            handleFetchLyricsDetail(params?.lyricsId);
            viewRecentVisits(globalToken);
        }
    }, [])

    useEffect(() => {
        if (params?.lyricsId && params?.lyricsSlug) {
            setLyricsId(params?.lyricsId);
            setLyricsSlug(params?.lyricsSlug)
            handleFetchLyricsDetail(params?.lyricsId);
        }
    }, [isLocationChange]);


    // close open rating modal
    const closeRatingModal =() =>{
        setIsRatingOpen(false);
      }
    
    // close open report modal
      const closeReportModal = () => {
        setReportVal(null);
        setIsReportOpen(false);
      }

    // fetch lyrics detail
    const handleFetchLyricsDetail = async (lyricsId) => {        
        const resLyricsDetail = await getLyricsDetail({ token: globalToken, lyricsId});
            console.log('lyrics detail', resLyricsDetail);
            setLoading(false);
            setLoadingLoadMore(false);
        if (resLyricsDetail?.success) {
            // setPosterImageBaseURL(resLyricsDetail?.image_base_url);
            setIsNotFound(false)
            if (resLyricsDetail?.data) {
                setLyricsDetailData(resLyricsDetail?.data);
            } else {
                setLyricsDetailData({});
                setIsNotFound(true);
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }
        setUpvoteBtnLoader(false);
        setDownvoteBtnLoader(false)
    }

    const FavouriteButton = ({
        is_fav_camp
    }) => {
        const [favBtnLoading, setFavBtnLoading] = useState(false);
        const handleStartTask = async () => {
            setFavBtnLoading(true)
            const token = globalToken;
            const res = await bookMarkLyrics({ token: token, lyricId: lyricsDetailData.id, favourite:is_fav_camp?0:1 });
            if (res?.success) {
                handleFetchLyricsDetail(lyricsDetailData.id)
            } else {
                
            }
            setFavBtnLoading(false)

        }
        return (
            <button type="button" disabled={favBtnLoading} onClick={handleStartTask} className="btn bg-white p-0 d-flex btn-like-circular" style={{ border: 'none', boxShadow: 'none' }}>
                {favBtnLoading ? (
                    <div className="h-5 w-5">
                        <Loadingicon />
                    </div>
                ) : <>{
                    is_fav_camp ? <i className="fa fa-heart heart active"></i> : <i className="fa heart fa-heart"></i>
                }</>}
            </button>
        )
    }


    const InlineShare = ({lyricsData, isShareActive, setIsShareActive}) =>{
        const isShare = isShareActive;

        const handleShareClick = () => {
            setIsShareActive(!isShareActive);
        };
        
        return <small className="position-relative" title="Share">
        {
        isShare ? <span ref={shareRef}><Share shareContent={{
            url:`${modulesUrls?.tuteehubLyrics}/song/${lyricsData?.slug}`,
            quote: `${lyricsData?.description}`,
            hashing: `#tuteehub`,
            title: `${lyricsData?.title}`,
            id: lyricsData?.id
        }} isCustomPos={true} refetchDetails={handleFetchLyricsDetail} isShare={isShare} setIsShare={setIsShareActive} iconShareRef={iconShareRef} shareRef={shareRef} /></span> : null
        }
        <span
        ref={iconShareRef}
        className={`${styles.icons} ${styles.shareIco}`}
        style={{display:'inline-block'}}
        role="button"
        onClick={() => handleShareClick()}
        ></span>{" "}
    </small>
    }


    const handleLikeUnlikeDislike = async (voteType, type) => {
       if(type=='dLike')setDownvoteBtnLoader(true)
       else setUpvoteBtnLoader(true)
        const resLikeUnlike = await likeUnlikeDislike({token:globalToken, lyricId:lyricsDetailData.id, voteType});
        if(resLikeUnlike?.success){
            fetchUserInfo(user?.tuteehub_id, globalToken, setUser, setFinance, user);
            handleFetchLyricsDetail(lyricsDetailData.id)
            toast.success(resLikeUnlike?.data?.message);
        }
        
    }


    const handleSubmitComment = async (commentId, setComment, comment, isReply, commentLoader, setIsReplyShow) =>{
      
            if(user?.email_verified==0){
              toast.error('Please verify your email');
              setTimeout(() => { setIsEmailVerifyCover(true) }, 200);
              return;
            }                               
          
        commentLoader(true);
            try {
                const resCommentCommit = await lyricsCommentAndReply({ token: globalToken, lyricId: lyricsDetailData?.id, commentId: isReply?commentId:0, comment });
                // Optionally clear comment input after sending
                if(resCommentCommit?.success){
                    fetchUserInfo(user?.tuteehub_id, globalToken, setUser, setFinance, user);
                    setIsReplyShow(false)
                    toast.success(resCommentCommit?.message || `${Number(resCommentCommit?.data?.creditPoints)>0?`Comment posted successfully, received ${resCommentCommit?.data?.creditPoints} credits!`:`Comment posted successfully`}`);
                    handleFetchLyricsDetail(lyricsDetailData?.id)
                }
                setComment('');
                
            } catch (error) {
                toast.error('Error sending comment');
            } finally {
                commentLoader(false);
            }
    }
    const modifyAnchorTags = (htmlContent) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        // Select all anchor tags
        const anchorTags = doc.querySelectorAll('a');
        // Add target="_blank" to each anchor tag
        anchorTags.forEach(tag => {
            tag.setAttribute('target', '_blank');
        });

        // Return modified HTML content
        return doc.documentElement.innerHTML;
    };


    
    return (<>
        {/* <BreadCrumbs /> */}
        
        {
            loading ? (

                <div className="w-full flex items-center justify-center py-4 text-themecolor min-h-[400px] group-card card-body">
                    <div className="h-10 w-10 theme-color">
                            <Loadingicon />
                        </div>
                </div>
            ) :
            <>
            <nav aria-label="breadcrumb" className="pt-0 px-0 pb-0 breadcrumb-card">
            <ol className="breadcrumb px-0 pt-0 breadcrumb-main-mobilea" style={{ display: 'flex', flexWrap: 'nowrap' }}>
                <li className={`breadcrumb-item capitalize rest-bread`}>
                    <NavLink to='/' style={{ fontSize: 14, textDecoration: 'none' }}>Home</NavLink>
                </li>
                <li className={`breadcrumb-item capitalize rest-bread`}>
                    <NavLink to='/lyrics' style={{ fontSize: 14, textDecoration: 'none' }}>Lyrics</NavLink>
                </li>
                <li
                    className={`breadcrumb-item capitalize rest-bread`}
                >
                    <NavLink
                        to={`/lyrics/album/${lyricsDetailData?.album?.id}/${lyricsDetailData?.album?.slug}`}
                        style={{ fontSize: 14, textDecoration: 'none' }}
                        dangerouslySetInnerHTML={{ __html: `${lyricsDetailData.album?.name}` }}
                    />
                </li>
                <li
                    className={`breadcrumb-item capitalize active clamping ellipsis`}
                >
                    {slugToNormal(lyricsDetailData.slug)}
                </li>

            </ol>
        </nav>
                <div className="row">
                    <div className="col-md-12">
                        <div className="py-4 px-md-4 px-2 grid grid-cols-1 group-card card-body gap-4">
                            <div className="px-0">
                                <div className="flex-column d-flex flex-md-row justify-content-between">

                                    <div className="mr-md-5 pr-md-5 flex-1">
                                        <h1 className="title-job d-flex align-items-center justify-content-between text-capitalize">
                                            <strong>{lyricsDetailData.title}</strong>
                                            <span className="d-flex gap-3 align-items-center">
                                                 <FavouriteButton  is_fav_camp={lyricsDetailData.is_favourite==0?false:true}/>
                                               
                                               <InlineShare lyricsData={lyricsDetailData} isShareActive={isShareActive} setIsShareActive={setIsShareActive}/>  
                        </span>
                                        </h1>
                                        <div className="d-md-flex mb-4 flex-md-row flex-column justify-content-between align-md-items-center">
                                        
                                            <span className="text-black-50 gig-table">
                                            <small style={{marginTop:1,float:'left'}}>
                                                    <StarRatings
                                                        rating={lyricsDetailData?.avg_rating || 0}
                                                        starRatedColor="#F08645"
                                                        changeRating={null}
                                                        numberOfStars={5}
                                                        starDimension="15px"
                                                        starSpacing="0"
                                                        name="rating"
                                                    /></small>
                                                {/* <a className="a-hover cursor-pointer text-black-50" onClick={()=>alert(1)}>{lyricsDetailData.album?.name}</a> */}
                                            <span className="big-dots"></span>
                                             {/* <a className="a-hover cursor-pointer text-black-50" onClick={()=>alert(1)}>{lyricsDetailData.writer?.name}</a>
                                             <span className="big-dots"></span> */}
                                             <span>{nFormatter(lyricsDetailData?.views)} views</span>
                                             <span className="big-dots"></span>
                                             <span>{lyricsDetailData.comments_count} comments</span>
                                             <span className="big-dots"></span>
                                             <span>{lyricsDetailData.likes_count} up votes</span>
                                             {
                                                    upvoteBtnLoader?<span className="custom-loader-small ms-2 theme-color">
                                                    <Loadingicon />
                                                </span>:<><i
                                                    className={`fas fa-thumbs-up cursor-pointer ms-2 ${lyricsDetailData.userlikes_count==1 && "text-themecolor"
                                                    }`}
                                                    onClick={() =>{
                                                                                            if(user?.email_verified==1){
                                                                                                handleLikeUnlikeDislike(lyricsDetailData.userlikes_count==1 ? "remove" : "like",'lke')
                                                                                            }else{
                                                                                              toast.error('Please verify your email');
                                                                                              setTimeout(() => { setIsEmailVerifyCover(true) }, 200)
                                                                                            }                               
                                                                                          }
                                                        
                                                    }
                                                ></i>{" "}</>
                                                }
                                             <span className="big-dots"></span>
                                             <span>{lyricsDetailData.dislikes_count} down votes</span>
                                             {
                                                    downvoteBtnLoader?<span className="custom-loader-small ms-2 theme-color">
                                                    <Loadingicon />
                                                </span>:<><i
                                                    className={`fas fa-thumbs-down cursor-pointer ms-2 ${lyricsDetailData.userdislikes_count==1 && "text-themecolor"
                                                    }`}
                                                    onClick={() =>{
                                                        if(user?.email_verified==1){
                                                            handleLikeUnlikeDislike(lyricsDetailData.userdislikes_count==1 ? "remove" : "dislike", 'dLike')
                                                        }else{
                                                          toast.error('Please verify your email');
                                                          setTimeout(() => { setIsEmailVerifyCover(true) }, 200)
                                                        }                               
                                                      }
                                                        
                                                    }
                                                ></i>{" "}</>
                                                }
                                             <span className="big-dots"></span>
                                             <span>{lyricsDetailData?.share_count} shares</span>
                                             <span className='big-dots'></span>
                                             <span>{convertDateFormat(lyricsDetailData.created_at)}</span>
                                             </span>
                                            {/* <span className="text-black-50">Published on {convertDateFormat(lyricsDetailData.posting_date)}</span> */}

                                        </div>
                                        <div className='tabbed-circle-container d-flex flex-md-row flex-column gap-2 align-items-md-center'>
                                        <div className='tabbed-circle'><strong>Album :</strong> <Link className='a-hover color-over-gray' to={`/lyrics/album/${lyricsDetailData?.album?.id}/${lyricsDetailData?.album?.slug}`}>{lyricsDetailData?.album?.name}</Link></div>
                                        <div className='tabbed-circle'><strong>Artist :</strong> <Link className='a-hover color-over-gray'  to={`/lyrics/artist/${lyricsDetailData?.artist?.id}/${lyricsDetailData?.artist?.slug}`}>{lyricsDetailData?.artist?.name}</Link></div>
                                        <div className='tabbed-circle'><strong>Lyricist :</strong> <Link className='a-hover color-over-gray'  to={`/lyrics/lyricist/${lyricsDetailData?.writer?.id}/${lyricsDetailData?.writer?.slug}`}>{lyricsDetailData?.writer?.name}</Link></div>
                                        </div>
                                        
                                        <div id="questAns" className="w-100 rounded text-black-60 mt-4 leads cn-desc" dangerouslySetInnerHTML={{
                                            __html: modifyAnchorTags(`${lyricsDetailData.lyrics}`)
                                        }}></div>
                                        <div className="col-span-12 px-0 question-box question-box-card py-2 mt-3 mb-3 mb-1">
                                           
                                           <button
                                               className="btn-soft mr-2 cursor-pointer hover:bg-themecolor hover:text-white"
                                               onClick={() => {
                                                if(user?.email_verified==1){
                                                    setIsRatingOpen(true)
                                                }else{
                                                  toast.error('Please verify your email');
                                                  setTimeout(() => { setIsEmailVerifyCover(true) }, 200)
                                                }                               
                                              }}
                                           >
                                               Give Rating
                                           </button>
                                           <button
                                               className="btn-soft mr-2 cursor-pointer hover:bg-themecolor hover:text-white"
                                               onClick={() => {
                                                if(user?.email_verified==1){
                                                    setIsReportOpen(true)
                                                }else{
                                                  toast.error('Please verify your email');
                                                  setTimeout(() => { setIsEmailVerifyCover(true) }, 200)
                                                }                               
                                              }}
                                           >
                                               Report
                                           </button>
                                       </div>
                                    </div>

                                </div>


                            </div>
                        </div>
                        <div className="flex justify-between mt-4">
                                <button id="nextprevbtn"
                                    className={` text-white px-5 py-3 bg-opacity-100 rounded disabled:bg-gray-200 font-medium disabled:text-black`}
                                    style={{ backgroundColor: !lyricsDetailData?.previous_question_slug ? '#ccc' : '#ff7200' }}
                                    disabled={lyricsDetailData?.previous_question_slug?false:true}
                                    onClick={() => {navigation(`/lyrics/detail/${lyricsDetailData?.previous_question_id}/${lyricsDetailData?.previous_question_slug}`);setIsLocationChange(!isLocationChange);viewRecentVisits(globalToken);}}
                                >
                                    {
                                    nextPrevLoader?<div className="h-5 w-5 mr-2">
                                    <Loadingicon />
                                    </div>:<>Previous</>
                                    }
                                
                                </button>
                                <button id="nextprevbtn"
                                    className={` text-white px-5 py-3 bg-opacity-100 rounded disabled:bg-gray-200 font-medium disabled:text-black`}
                                    style={{ backgroundColor:!lyricsDetailData?.next_question_slug ? '#ccc' : '#ff7200' }}
                                    disabled={lyricsDetailData?.next_question_slug?false:true}
                                    onClick={() => {navigation(`/lyrics/detail/${lyricsDetailData?.next_question_id}/${lyricsDetailData?.next_question_slug}`); setIsLocationChange(!isLocationChange);viewRecentVisits(globalToken);}}
                                >
                                    {
                                    nextPrevLoader?<div className="h-5 w-5 mr-2">
                                    <Loadingicon />
                                    </div>:<>Next</>
                                    }
                                </button>
                            </div>

                        <div className="py-4 px-md-4 px-2 grid grid-cols-1 group-card card-body gap-4 mt-4">
                            <div className="heading-main position-relative my-3 overflow-hidden">
                                <h5 className="text-left bg-white pr-3 b"><strong>Comments({lyricsDetailData.comments_count})</strong></h5>
                                <hr className="heading-hr mb-0" />
                            </div>
                            <LyricsComments cData={lyricsDetailData} handleSubmitComment={handleSubmitComment}/>
                        </div>
                    </div>
                    {/* <div className="col">
                        <div className="position-sticky" style={{ top: 80 }}>
                            <div className="py-4 px-md-4 px-2 grid grid-cols-1 group-card card-body gap-1">
                                
                                <PopularCareerCategories catsList={popularCareerCats} showArrow={true} title={'Important Computer Science Engineering Links'}/>
                            </div>
                            <div className="py-4 mt-4 px-md-4 px-2 grid grid-cols-1 group-card card-body gap-4">
                                <div className="d-flex align-items-center w-100 py-md-2 py-2 px-0 w-100 w-md-auto max-left-contents justify-content-between">
                                    <div className="thumbnail-containers mr-3">

                                        <img src={require(`../../../../assets/images/logo_other_platform.webp`)} width={50} height={50} className="bg-gray border" style={{ borderRadius: 100 }} />
                                    </div>
                                    <div className="flex-1">
                                        <h5 className="mb-0 mt-0 text-capitalize title-task color-333">
                                            <strong>{'TuteeHUB'}</strong> </h5>
                                        <p className="text-black-50 text-capitalize mb-0 d-flex align-items-center gap-2">
                                            <strong className="text-blacks d-flex align-items-center gap-1">1L+ Subscribers
                                            </strong>
                                        </p>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div> */}
                </div></>
        }

            {isRatingOpen ? (
              <Modal
                closeModal={closeRatingModal}
                setIsOpen={setIsRatingOpen}
                isOpen={isRatingOpen}
                callBack={submitRating}
                title="Rate the Lyric"
                disabled={ratingVal > 0 ? false : true}
                loading={ratingLoading}
              >
                <div className="card  border-0">
                  <div className="card-body shadow">
                    <StarRatings
                      rating={ratingVal}
                      starRatedColor="#F08645"
                      starHoverColor="rgb(253 160 104)"
                      changeRating={changeRating}
                      numberOfStars={5}
                      starDimension="30px"
                      starSpacing="0"
                      name="rating"
                    />
                  </div>
                </div>

              </Modal>
            ) : null}
            {isReportOpen ? (
              <Modal
                closeModal={closeReportModal}
                setIsOpen={setIsReportOpen}
                isOpen={isReportOpen}
                callBack={submitReport}
                title="Report"
                disabled={!reportVal || reportVal?.length<=0?true:false}
                loading={reportLoading}
              >
                <input
                  type="text"
                  placeholder="Enter Report Message"
                  className="w-full p-3 border rounded-lg text-base"
                  onChange={(e) => setReportVal(e.target.value)}
                />
              </Modal>
            ) : null}
    </>)
}

export default LyricsDetail;
