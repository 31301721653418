import React, { useEffect, useRef, useState } from 'react';
import SectionDescription from '../../../common/sectionDescription';
import BreadCrumbs from '../../../common/BreadCrumbs';
import { CDN_BASE_URL, ERROR_SOMETHING_WENT_WRONG, nFormatter, yearSatra } from '../../../../utils/utils';
import { getallQuotesTopics } from '../../../../api/careernews';
import { toast } from "react-toastify";
import { Loadingicon } from '../../../../AppIcons';
import ViewAllButton from '../../../common/viewallbutton';
import PopularCareerCategories from '../popularcategories';
import { NavLink, useNavigate } from 'react-router-dom';
import { getAllQuotesTopics } from '../../../../api/quotes';
import TopicList from '../common/topicList';
import InfiniteScroll from 'react-infinite-scroll-component';
import { isArray } from 'lodash';
import NotFound from '../../../common/notfound';


function QuotesTopics() {
    const [pageNo, setPageNo] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [scrollLoading, setScrollLoading] = useState(false);
    const [isNotFound, setIsNotFound] = useState(false);
    const [loadingLoadMore, setLoadingLoadMore] = useState(false);
    const [allQuotesTopics, setAllQuotesTopics] = useState([]);
    const [filteredQuotesTopics, setFilteredQuotesTopics] = useState([]);
    const [filterType, setFilterType] = useState('topics');
    const [isSearch, setIsSearch] = useState(false);
    const [searchTerm, setSearchTerm] = useState();
    const [totalRecordFound, setTotalRecordFound] = useState(0);
    // const searchBox = useRef(null);
    const navigation = useNavigate();   
    useEffect(() => {
        handleFetchAllQuotesTopics(1);
    }, [])

    // fetch all quotes topics
    const handleFetchAllQuotesTopics = async (pageNo) => {
        const resAllQuotesTopics = await getAllQuotesTopics({ pageNo: pageNo });
        if (resAllQuotesTopics?.success) {
            // console.log('topics', resAllQuotesTopics?.data);
            setIsNotFound(false)
            resAllQuotesTopics?.next_page_url ? setShowLoadMore(true) : setShowLoadMore(false);
            if (resAllQuotesTopics?.data?.length && pageNo == 1) {
                setAllQuotesTopics(resAllQuotesTopics?.data);
                setFilteredQuotesTopics(resAllQuotesTopics?.data);
            } else if (resAllQuotesTopics?.data?.length && pageNo !== 1) {
                setAllQuotesTopics([...allQuotesTopics, ...resAllQuotesTopics?.data]);
                setFilteredQuotesTopics([...allQuotesTopics, ...resAllQuotesTopics?.data]);
            } else {
                if (pageNo == 1) {
                    setAllQuotesTopics([]);
                    setIsNotFound(true);
                }
            }
            
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }

        setLoading(false);
        setLoadingLoadMore(false);
        setScrollLoading(false);
    }

    // load more quotes topics data
    const loadMore = () => {
        setScrollLoading(true);
        setLoadingLoadMore(true);
        const nxtPage = pageNo + 1;
        handleFetchAllQuotesTopics(nxtPage);
        setPageNo(nxtPage);
        // searchBox.current.value = '';
            setIsSearch(false);
        // setSearchTerm('')
    }

    const popularCareerCats = [
        {
            name: "Joyce Meyer",
            value: "Joyce Meyer",
            redirectUrl: "#"
        },
        {
            name: "Henry Rollins",
            value: "Henry Rollins",
            redirectUrl: "#"
        },
        {
            name: "Helen Mirren",
            value: "Helen Mirren",
            redirectUrl: "#"
        },
        {
            name: "Justin Trudeau",
            value: "Justin Trudeau",
            redirectUrl: "#"
        },
        {
            name: "Helen Fisher",
            value: "Helen Fisher",
            redirectUrl: "#"
        },
        {
            name: "Maya Angelou",
            value: "Maya Angelou",
            redirectUrl: "#"
        },
        {
            name: "Helen Keller",
            value: "Helen Keller",
            redirectUrl: "#"
        },
        {
            name: "Julie Sweet",
            value: "Julie Sweet",
            redirectUrl: "#"
        },
        {
            name: "More ...",
            value: "More ...",
            redirectUrl: "#"
        }
    ];
    

    const handleSearchTopic = (e) => {
        const searchTxt = e.target.value;
        setSearchTerm(searchTxt);
        if (searchTxt == '') {
            setIsSearch(false);
            handleFetchAllQuotesTopics(1);
            return;
        }
        if (searchTxt !== '' && e.key === "Enter") {
            setIsSearch(true);
            searchTopic(searchTxt);
        }
    }

    const searchTopic = (searchTxt) =>{            
            const fTopics = allQuotesTopics?.filter((t, index)=>t.name.toLowerCase().includes(searchTxt.toLowerCase()) );
            setFilteredQuotesTopics(fTopics);
            setTotalRecordFound(fTopics?.length);
    }   

    const filterTypeData = [
        {
            name:'Topics',
            value:'topics',
        },{
            name:'Authors',
            value:'authors'
        }
    ]


    // handle filter change 
    const handleFilterChange = (e) =>{
        setPageNo(1);
        const fValue = e.target.value;
        if(fValue=='authors'){
            navigation('/quotes/authors')
        }
    }

    return (
    <>
       
        <div className='d-flex align-items-center justify-content-between'>
            {/* <BreadCrumbs /> */}
             <nav
                aria-label="breadcrumb"
                className="pt-0 px-0 pb-0 breadcrumb-card"
            >
                    <ol
                    className="breadcrumb px-0 pt-0 breadcrumb-main-mobilea mb-0"
                    style={{ display: "flex", flexWrap: "nowrap" }}
                    >
                    <li className={`breadcrumb-item capitalize rest-bread`}>
                        <NavLink
                        to="/"
                        style={{ fontSize: 14, textDecoration: "none" }}
                        >
                        Home
                        </NavLink>
                    </li>
                    {/* <li className={`breadcrumb-item capitalize rest-bread`}>
                        <NavLink
                        to="/quotes"
                        style={{ fontSize: 14, textDecoration: "none" }}
                        >
                        Quotes
                        </NavLink>
                    </li> */}
                    <li
                        className={`breadcrumb-item capitalize active clamping ellipsis`}
                    >
                        Quotes
                    </li>
                </ol>
            </nav>
            {/* <div className="search-boxs px-2 d-flex  align-items-center border rounded bg-white d-flex justify-content-between" style={{ marginBottom: '1rem' }}><input type="text" autocomplete="chrome-off" onKeyUp={handleSearchTopic} className="searchInput px-md-3 px-0 form-control border-0 bg-transparent outline-0" ref={searchBox} placeholder="Search Topics in List" />
                <i className="fa fa-search opacity-1 cursor-pointer" style={{ color: '#ff7200' }} onClick={() => searchTopic(searchTerm)}></i>
            </div> */}
        </div>
        <div className='row'>
            <div className='col-md-12'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1">
                    
                    {
                        !isSearch?<>
                        <div className="d-md-flex align-items-center justify-content-between">
                        <SectionDescription title={`<strong>Top 10 Popular Topics</strong>`} subTitle={`Quotes About Change In Your Life and In The World. Encouraging Quotes to Help Keep You Going Celebrating Success, Love & Life across popular topics.`} />
                        <div className='filter-container ms-4' style={{minWidth:200}}>
                                <select className='form-control mw-100 mt-md-0 mt-4'  onChange={handleFilterChange}  value={filterType}>
                                    {
                                        filterTypeData?.map((fData, i)=><option key={i+'ftype'} value={fData?.value}>{fData?.name}</option>)
                                    }
                                    
                                </select>
                        </div>
                    </div>
                    <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-4" /></>:null
                    }
                    
                    {
                        isSearch ? <><h6 className="text-black-50s label label-default w-100 py-2">Search Result For '{searchTerm}' : {totalRecordFound} Result Found</h6><hr style={{ borderColor: '#f0f0f0', marginTop: 0 }} /></> : null
                    }
                    {loading ? (
                        <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                            <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                                <div className="h-10 w-10">
                                    <Loadingicon />
                                </div>
                            </div>
                        </div>) : isNotFound ? (
                            <NotFound/>) : <div className='row'>
                                <InfiniteScroll
                                    dataLength={filteredQuotesTopics?.length}
                                    next={() =>loadMore()}
                                    hasMore={showLoadMore}
                                    loader={scrollLoading && (
                                    <div className="w-full flex items-center justify-center mb-20 text-themecolor min-h-[400px] absolute top-0 left-0 bg-white h-full bg-opacity-50">
                                        <div className="h-10 w-10">
                                        <Loadingicon />
                                        </div>
                                    </div>
                                    )}
                                    pullDownToRefresh
                                    pullDownToRefreshThreshold={90}
                                    refreshFunction={() => {
                                    setPageNo(1);
                                    setFilteredQuotesTopics([]);
                                    }}
                                    scrollableTarget={"qBank"}
                                    className={"relative"}
                                >
                                    <div className="py-8 grid pr-2 p-md-4 px-3 pr-md-2 gap-4 multi-card lg:mt-0 mb-0 grid-cols-4 scroll-container-custom" style={{ overflow: "auto" }} id="qBank">
                                    {isArray(filteredQuotesTopics) && filteredQuotesTopics.map((topic, sIndex) => (<>
                                        <TopicList topic={topic} key={sIndex} type="topic" mH={false} boxSize={'col-md-12'} redirectionFn={()=>navigation(`/quotes/topics/${topic?.id}/${topic?.slug}`)}/>
                                    </>
                                    ))}
                                    </div>
                                </InfiniteScroll>
                        {/* {
                            filteredQuotesTopics?.map((topic, sIndex) => (
                                <TopicList topic={topic} key={sIndex} type="topic" mH={false} boxSize={'col-md-3'} redirectionFn={()=>navigation(`/quotes/topics/${topic?.id}/${topic?.slug}`)}/>
                            ))
                        } */}
                    </div>
                    }
                </div>
                {/* {
                    showLoadMore ? <ViewAllButton
                        disabled={false}
                        redirectionUrl={'/'}
                        handleClickFn={loadMore}
                        btnText={'Load More'}
                        type={'button'}
                        btnClass={'loadButton card-btn-1'}
                        loading={loadingLoadMore}
                    /> : null
                } */}

            </div>
            {/* <div className='col-md-3'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1 position-sticky" style={{ top: 80 }}>
                    <PopularCareerCategories catsList={popularCareerCats} title={`Popular Categories`}/>
                </div>
            </div> */}
        </div>

    </>)
}

export default QuotesTopics;
