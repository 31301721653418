import React, { useEffect, useRef, useState } from "react";
import SectionDescription from "../../../common/sectionDescription";
import BreadCrumbs from "../../../common/BreadCrumbs";
import {
  CDN_BASE_URL,
  ERROR_SOMETHING_WENT_WRONG,
  nFormatter,
  slugToNormal,
  yearSatra,
} from "../../../../utils/utils";
import { getAllStreams } from "../../../../api/careernews";
import { toast } from "react-toastify";
import { Loadingicon } from "../../../../AppIcons";
import ViewAllButton from "../../../common/viewallbutton";
import { useNavigate, useParams } from "react-router-dom";
import { getBlogCategory, getBlogSubCategory } from "../../../../api/blog";
import { getExamSubCategory } from "../../../../api/exam";
import ExamCatSubCatList from "../common/catsubcat";
import InfiniteScroll from "react-infinite-scroll-component";
import { isArray } from "lodash";
import NotFound from "../../../common/notfound";
function ExamsSubCategory() {
  const [pageNo, setPageNo] = useState(1);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isNotFound, setIsNotFound] = useState(false);
  const [scrollLoading, setScrollLoading] = useState(false);
  const [loadingLoadMore, setLoadingLoadMore] = useState(false);
  const [allContent, setAllContent] = useState([]);
  const [allFilteredContent, setAllFilteredContent] = useState([]);
  const [examCatId, setExamCatId] = useState(null);
  const [isSearch, setIsSearch] = useState(false);
  const [searchTerm, setSearchTerm] = useState();
  const [totalRecordFound, setTotalRecordFound] = useState(0);
  // const searchBox = useRef(null);
  const navigation = useNavigate();

  const params = useParams();

  useEffect(() => {
    console.log(params?.examCatSlug)
    if (params?.examCatSlug) {
      const EId = params?.examCatSlug;
      setExamCatId(EId);
      handleFetchAllExamSubCategory(1, EId);
    } else {
      handleFetchAllExamSubCategory(1, null);
    }
  }, []);

  // fetch all sub category
  const handleFetchAllExamSubCategory = async (pageNo, cId) => {
    const resAllExamSubCategory = await getExamSubCategory({
      pageNo: pageNo,
      catId: cId,
    });
    if (resAllExamSubCategory?.isExam || resAllExamSubCategory?.success) {
      setIsNotFound(false);
      resAllExamSubCategory?.topics?.next_page_url
        ? setShowLoadMore(true)
        : setShowLoadMore(false);
      // console.log(resAllExamSubCategory);
      if (!cId) {
        if (resAllExamSubCategory?.Subject?.data?.length && pageNo == 1) {
          setAllContent(resAllExamSubCategory?.Subject?.data);
          setAllFilteredContent(resAllExamSubCategory?.Subject?.data);
        } else if (resAllExamSubCategory?.Subject?.data?.length && pageNo !== 1) {
          setAllContent([...allContent, ...resAllExamSubCategory?.Subject?.data]);
          setAllFilteredContent([
            ...allContent,
            ...resAllExamSubCategory?.Subject?.data,
          ]);
        } else {
          if (pageNo == 1) {
            setAllContent([]);
            setAllFilteredContent([]);
            setIsNotFound(true);
          }
        }
      } else {
        if (resAllExamSubCategory?.topics?.data?.length && pageNo == 1) {
          setAllContent(resAllExamSubCategory?.topics?.data);
          setAllFilteredContent(resAllExamSubCategory?.topics?.data);
        } else if (resAllExamSubCategory?.topics?.data?.length && pageNo !== 1) {
          setAllContent([...allContent, ...resAllExamSubCategory?.topics?.data]);
          setAllFilteredContent([
            ...allContent,
            ...resAllExamSubCategory?.topics?.data,
          ]);
        } else {
          if (pageNo == 1) {
            setAllContent([]);
            setAllFilteredContent([]);
            setIsNotFound(true);
          }
        }
      }
    } else {
      toast.error(ERROR_SOMETHING_WENT_WRONG);
    }

    setLoading(false);
    setLoadingLoadMore(false);
    setScrollLoading(false)
  };

  //load more blog subcat data
  const loadMore = () => {
    setScrollLoading(true)
    setLoadingLoadMore(true);
    const nxtPage = pageNo + 1;
    handleFetchAllExamSubCategory(nxtPage, examCatId);
    setPageNo(nxtPage);
    // searchBox.current.value = "";
    setIsSearch(false);
  };

  const handleSearchSubCategory = (e) => {
    const searchTxt = e.target.value;
    setSearchTerm(searchTxt);
    if (searchTxt == "") {
      setIsSearch(false);
      handleFetchAllExamSubCategory(1, examCatId);
      return;
    }
    if (searchTxt !== "" && e.key === "Enter") {
      setIsSearch(true);
      searchSubCategory(searchTxt);
    }
  };

  const searchSubCategory = (searchTxt) => {
    const fSubCategory = allContent?.filter((al, index) =>
      al.topic_name.toLowerCase().includes(searchTxt.toLowerCase())
    );
    setAllFilteredContent(fSubCategory);
    setTotalRecordFound(fSubCategory?.length);
    if (searchTerm?.length && !fSubCategory?.length) {
      setIsNotFound(true);
    } else {
      setIsNotFound(false);
    }
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
  <BreadCrumbs />
  
  {/* Right-side elements container */}
  <div className="d-flex align-items-center justify-content-end">
    {/* Search Box */}
    {/* <div
      className="search-boxs px-2 d-flex align-items-center border rounded bg-white"
      style={{ marginBottom: "1rem" }}
    >
      <input
        type="text"
        autoComplete="chrome-off"
        onKeyUp={handleSearchSubCategory}
        className="searchInput px-md-3 px-0 form-control border-0 bg-transparent outline-0"
        placeholder={`Search Sub Category`}
        ref={searchBox}
      />
      <i
        className="fa fa-search opacity-1 cursor-pointer"
        style={{ color: "#ff7200" }}
        onClick={() => searchSubCategory(1, searchTerm)}
      ></i>
    </div> */}
    
    {/* My Blogs Button */}
    <div className="btn-group question-box question-box-card ms-3 mb-3">
      <button
        className="btn-soft cursor-pointer hover:bg-themecolor text-white"
        style={{ backgroundColor: "#ff7200" }}
        onClick={() => navigation("/exams/myExams")}
      >
        My Exams
      </button>
    </div>
  </div>
</div>
      <div className="row">
        <div className="col-md-12">
          <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1">
            {!isSearch ? (
              <>
                <div className="d-flex align-items-center justify-content-between">
                  <SectionDescription
                    title={`<strong>${
                      params?.examCatSlug
                        ? slugToNormal(params?.examCatSlug)
                        : "All"
                    } Latest Exams Mock Test For Practice Session ${yearSatra()}</strong>`}
                    subTitle={`To help you prepare for any exam, we have compiled a huge collection of exhuastive mock tests subjectwise/topicwise that are typically included in any exam syllabus. There are free mock tests for beginners along with subscription based packages with unlimited test series of various difficulty levels. If you're preparing for any competition, employment, entrance exam that includes multiple-choice questions then TuteeHub can help you practice with confidence.`}
                  />
                </div>

                <hr
                  style={{ borderColor: "#ccc", marginBottom: 0 }}
                  className="mb-4"
                />
              </>
            ) : null}

            {isSearch ? (
              <>
                <h6 className="text-black-50s label label-default w-100 py-2">
                  Search Result For '{searchTerm}' : {totalRecordFound} Result
                  Found
                </h6>
                <hr style={{ borderColor: "#f0f0f0", marginTop: 0 }} />
              </>
            ) : null}
            {loading ? (
              <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                  <div className="h-10 w-10">
                    <Loadingicon />
                  </div>
                </div>
              </div>
            ) : isNotFound ? (
              <NotFound/>
            ) : (
              <div className="row">


                <InfiniteScroll
                          dataLength={allFilteredContent?.length}
                          next={() => loadMore()}
                          hasMore={showLoadMore}
                          loader={scrollLoading && (
                            <div className="w-full flex items-center justify-center mb-20 text-themecolor min-h-[400px] absolute top-0 left-0 bg-white h-full bg-opacity-50">
                              <div className="h-10 w-10">
                                <Loadingicon />
                              </div>
                            </div>
                          )}
                          pullDownToRefresh
                          pullDownToRefreshThreshold={90}
                          refreshFunction={() => {
                            setPageNo(1);
                            setAllFilteredContent([]);
                          }}
                          scrollableTarget={"qBank"}
                          className={"relative"}
                        >
                          <div className="py-8 grid pr-2 p-md-4 px-3 pr-md-2 gap-4 multi-card lg:mt-0 mb-0 grid-cols-4 scroll-container-custom" style={{ overflow: "auto" }} id="qBank">
                            {isArray(allFilteredContent) && allFilteredContent.map((content, sIndex) => (<>
                              <ExamCatSubCatList
                                  content={content}
                                  boxSize="col-md-12 mb-3"
                                  key={sIndex}
                                  type="content"
                                  redirectionFn={() =>
                                    navigation(`/exams/topic/${content?.slug}`)
                                  }
                                />
                            </>
                            ))}
                          </div>
                        </InfiniteScroll>

                {/* {allFilteredContent?.map((content, sIndex) => (
                  <ExamCatSubCatList
                    content={content}
                    boxSize="col-md-4 mb-3"
                    key={sIndex}
                    type="content"
                    redirectionFn={() =>
                      navigation(`/exams/topic/${content?.slug}`)
                    }
                  />
                ))} */}
              </div>
            )}
          </div>
          {/* {showLoadMore && !isNotFound ? (
            <ViewAllButton
              disabled={false}
              redirectionUrl={"/"}
              handleClickFn={loadMore}
              btnText={"Load More"}
              type={"button"}
              btnClass={"loadButton card-btn-1"}
              loading={loadingLoadMore}
            />
          ) : null} */}
        </div>
        {/* <div className='col-md-3'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1 position-sticky" style={{ top: 80 }}>
                  
                    <PopularCareerCategories catsList={popularCareerCats} title={`Popular Categories`}/>
                </div>
            </div> */}
      </div>
    </>
  );
}

export default ExamsSubCategory;
