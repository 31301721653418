import React, { useEffect, useState } from 'react';
import SectionDescription from '../../../common/sectionDescription';
import { ERROR_SOMETHING_WENT_WRONG, slugToNormal, yearSatra } from '../../../../utils/utils';
import { toast } from "react-toastify";
import { Loadingicon } from '../../../../AppIcons';
import ViewAllButton from '../../../common/viewallbutton';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import ForumListItem from '../common/forumlistitem';
import Modal from "react-bootstrap/Modal";
import { deleteForum, getForumList, getMyForumList } from '../../../../api/forum';
import { useAuth } from '../../../../Context/AuthContext';
import NotFound from '../../../common/notfound';
function MyForum() {
    const [pageNo, setPageNo] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [btnLoading, setBtnLoading] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [choosenForum, setChoosenForum] = useState();
    const [isNotFound, setIsNotFound] = useState(false);
    const [loadingLoadMore, setLoadingLoadMore] = useState(false);
    const [allContent, setAllContent] = useState([]);
    const [forumSubCatSlug, setForumSubCatSlug] = useState();
    const navigation = useNavigate();
    const params = useParams();
    const { globalToken } = useAuth();

    useEffect(() => {

        handleForumListBySCat(1);

    }, [])

    // fetch all forum list by user;
    const handleForumListBySCat = async (pageNo) => {
        const resForumList = await getMyForumList({ pageNo: pageNo, token: globalToken });
        console.log(resForumList);

        if (resForumList?.success) {
            setIsNotFound(false)
            resForumList?.data?.next_page_url ? setShowLoadMore(true) : setShowLoadMore(false);
            if (resForumList?.data?.data?.length && pageNo == 1) {

                setAllContent(resForumList?.data?.data);
            } else if (resForumList?.data?.data?.length && pageNo !== 1) {
                setAllContent([...allContent, ...resForumList?.data?.data]);
            } else {
                if (pageNo == 1) {
                    setAllContent([]);
                    setIsNotFound(true);
                }
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }

        setLoading(false);
        setLoadingLoadMore(false);
        setBtnLoading(false);
        setShowDeleteModal(false)
    }


    //load more forum by user
    const loadMore = () => {
        setLoadingLoadMore(true);
        const nxtPage = pageNo + 1;
        handleForumListBySCat(nxtPage);
        setPageNo(nxtPage);
    }

    const handleDeleteForum = async () =>{
        setBtnLoading(true);
        const resDeleteForum = await deleteForum({token: globalToken, forumId:choosenForum?.id})
        if(resDeleteForum.success){
            toast.success(resDeleteForum?.message)
            handleForumListBySCat(pageNo);    

        }else{
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }
        
    }

    const handleDeleteModal = (cForum) => {
        setChoosenForum(cForum)
        setShowDeleteModal(!showDeleteModal);
        };

    return (<>
        {/* <BreadCrumbs /> */}
       
        <nav aria-label="breadcrumb" className="pt-0 px-0 pb-0 breadcrumb-card">
            <ol className="breadcrumb px-0 pt-0 breadcrumb-main-mobilea" style={{ display: 'flex', flexWrap: 'nowrap' }}>
                <li className={`breadcrumb-item capitalize rest-bread`}>
                    <NavLink to='/' style={{ fontSize: 14, textDecoration: 'none' }}>Home</NavLink>
                </li>
                <li className={`breadcrumb-item capitalize rest-bread`}>
                    <NavLink to='/forum' style={{ fontSize: 14, textDecoration: 'none' }}>Forum</NavLink>
                </li>
                <li className={`breadcrumb-item capitalize active clamping ellipsis`}>
                    My Forum
                </li>
            </ol>
        </nav>
        
        <div className='row'>
            <div className='col-md-12'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1">
                    
                    <div className="d-flex align-items-center justify-content-between">
                        <SectionDescription title={`<strong>My Forum</strong>`} subTitle={`My Forum and Discussion List`} />
                        <div className='btn-group question-box question-box-card'>                       
                            <button
                                className="btn-soft cursor-pointer hover:bg-themecolor text-white" style={{backgroundColor:'#ff7200'}}
                                onClick={() => navigation('/forum/post-a-question')}
                            >
                            Post A Question
                            </button>                    
                        </div>
                    </div>

                    <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-4" />

                    {loading ? (
                        <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                            <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                                <div className="h-10 w-10">
                                    <Loadingicon />
                                </div>
                            </div>
                        </div>) : isNotFound ? (
                            <NotFound/>) : <div className='row'>
                        {
                            allContent.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))?.map((content, sIndex) => (
                              
                                <ForumListItem forumData={content} handleDeleteModal={handleDeleteModal} isEdit={true} isUser={false} redirectionFn={(e) => {e.preventDefault();e.stopPropagation();navigation(`/forum/${content?.cat?.slug}/${content?.scat?.slug}/${content?.slug}-${content?.id}`)}} key={sIndex} />
                                
                            ))
                        }

                    </div>
                    }
                </div>
                {
                    showLoadMore ? <ViewAllButton
                        disabled={false}
                        redirectionUrl={'/'}
                        handleClickFn={loadMore}
                        btnText={'Load More'}
                        type={'button'}
                        btnClass={'loadButton card-btn-1'}
                        loading={loadingLoadMore}
                    /> : null
                }

            </div>
        </div>
        {/* delete alert START  */}
      <Modal
        show={showDeleteModal}
        onHide={handleDeleteModal}
        
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
        <strong>Confirm : </strong>Do you wish to permanently remove this question?
        <div className="card mt-4">
            <div className="card-body bg-gray running">
                <h6><strong>Question: <span className="color-gray" dangerouslySetInnerHTML={{__html:choosenForum?.question}}></span></strong></h6>
            </div>
        </div>
         
        </Modal.Body>
        <Modal.Footer style={{ border: "none" }}>
          <button
            type="button"
            disabled={btnLoading}
            className="btn btn-warning custom-theme-button d-flex align-items-center"
            onClick={() => handleDeleteForum()}
          >
            {btnLoading ? (
                <div className="h-5 w-5">
                    <Loadingicon />
                </div>
            ) : (
                "Yes"
            )}
            
          </button>
          <button
            type="button"
            className="btn btn-light d-flex align-items-center ms-2"
            onClick={() => handleDeleteModal()}
          >
            No{" "}
          </button>
        </Modal.Footer>
      </Modal>
      {/* delete alert popup END  */}
    </>)
}

export default MyForum;