import React, { useState, useEffect, useCallback } from 'react';
import Select from 'react-select';

function CustomSelectAsync({
  options,
  handleCustomSelectOnChange,
  defaultOption,
  isSelectLoading,
  type,
  placeholder,
  isClearable,
  setFieldValue = () => {},
  isMultiple = false,
}) {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true); // Flag to track more data
  const [page, setPage] = useState(1); // Pagination state

  const PAGE_SIZE = 20; // Number of options per page

  // Debounced search input handler to reduce search frequency
  const debouncedSearch = useCallback(
    debounce((inputValue) => {
      setSearchTerm(inputValue);
    }, 500),
    [] // Empty dependency array to keep the debounce stable
  );

  // Filter options based on search term and paginate
  useEffect(() => {
    const filterOptions = () => {
      if (!searchTerm) {
        // If no search term, reset and show initial set of options
        setFilteredOptions(options.slice(0, PAGE_SIZE));
        setHasMore(options.length > PAGE_SIZE);
        return;
      }

      // Apply local filtering and pagination based on search term
      const filtered = options.filter(option =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase())
      );

      // Paginate filtered results
      setFilteredOptions(filtered.slice(0, page * PAGE_SIZE));
      setHasMore(filtered.length > page * PAGE_SIZE); // Determine if more options exist
    };

    filterOptions();
  }, [searchTerm, options, page]);

  // Handle input change (used by react-select's onInputChange)
  const handleInputChange = (inputValue) => {
    debouncedSearch(inputValue); // Debounced search
  };

  // Load more options when user scrolls to the bottom
  const loadMoreOptions = () => {
    if (hasMore && !isLoading) {
      setPage((prevPage) => prevPage + 1); // Load next page
    }
  };

  const formatOptionLabel = ({ label, flag }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img src={flag} alt="" style={{ width: 20, height: 20, marginRight: 10 }} />
      {label}
    </div>
  );

  return (
    <Select
      options={filteredOptions}  // Use filtered options here
      className="bg-gray-50"
      onChange={(selectedOption) =>
        handleCustomSelectOnChange(
          isMultiple ? selectedOption : selectedOption?.value,
          type,
          selectedOption?.label,
          setFieldValue
        )
      }
      isSearchable={true}
      isLoading={isLoading || isSelectLoading}
      isClearable={isClearable}
      defaultValue={defaultOption}
      placeholder={placeholder}
      isMulti={isMultiple}
      formatOptionLabel={type === 'country' ? formatOptionLabel : null}
      onInputChange={handleInputChange} // Handle search input change
      onMenuScrollToBottom={loadMoreOptions} // Trigger loading more options on scroll
    />
  );
}

// Helper function to debounce the search term
function debounce(func, wait) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
}

export default CustomSelectAsync;
