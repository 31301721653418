import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Loadingicon } from "../../../../AppIcons";
import BreadCrumbs from "../../../common/BreadCrumbs";
import { useAuth } from "../../../../Context/AuthContext";
import { Formik } from "formik";
import CustomSelect from "../../../common/customselect";
// import { getForumSubject, getForumTopic, postAForumQuestion } from "../../../../api/forum";
import { capitalizeLabel, EDITOR_KEY, ERROR_SOMETHING_WENT_WRONG, TINY_EDITOR_KEY } from "../../../../utils/utils";
import { useSearch } from "../../../../Context/SearchContext";
import { getBlogCategory, getBlogSubCategory, getBlogTags, postBlog } from "../../../../api/blog";
// import { Editor } from "@tinymce/tinymce-react";
import FAQItem from "../faq";
import { HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Table, Toolbar } from '@syncfusion/ej2-react-richtexteditor';
import { registerLicense } from '@syncfusion/ej2-base';
import Modal from "../../../common/Modal";
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import addImage from '../../../../assets/images/add-image.png';
import _ from "lodash";

// Register your Syncfusion license key
registerLicense(EDITOR_KEY);

function PostABlog() {
    const moderateEditorRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
    const [draftBtnLoading, setDraftBtnLoading] = useState(false);
    const [isTagLoading, setIsTagLoading] = useState(false);
    
    const [catId, setCatId] = useState(null);
    const [sCatId, setSCatId] = useState();
    const [isCategoryLoading, setIsCategoryLoading] = useState(false);
    const [isSubCategoryLoading, setIsSubCategoryLoading] = useState(false);
    const { globalToken, user, setIsEmailVerifyCover } = useAuth();
    const [categoryFormatted, setCategoryFormatted] = useState([]);
    const [subCategoryFormatted, setSubCategoryFormatted] = useState([]);
    const [defaultSCat, setDefaultSCat] = useState({});
    const [tagId, setTagId] = useState([]);
    
    const [tagFormatted, setTagFormatted] = useState([]);    
    const [sfValue, setSfValue] = useState(null)
    const [submitAction, setSubmitAction] = useState();
    const [faqContainers, setFaqContainers] = useState([{ question: '', answer: '' }]);

    const [isCoverPicCropModalOpen, setIsCoverPicCropModalOpen] = useState(false);
    const chooseCoverPicRef = useRef(null);
    const [coverImage, setCoverImage] = useState(null);
    const [image, setImage] = useState(null);
    const [croppedCoverImage, setCroppedCoverImage] = useState(null);
    const [imageLoading, setImageLoading] = useState(false);
    const cropperRef = useRef(null);

    const toolbarSettings = {
        items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
            'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
            'LowerCase', 'UpperCase', '|',
            'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
            'Outdent', 'Indent', '|',
            'CreateLink', 'Image', '|', 'ClearFormat', 'Print',
            'SourceCode', 'CreateTable', 'FullScreen', '|', 'Undo', 'Redo']
    };

    const quickToolbarSettings = {
        image: ['Replace', 'Align', 'Caption', 'Remove', 'InsertLink', 'OpenImageLink', '-', 'EditImageLink', 'RemoveImageLink', 'Display', 'AltText', 'Dimension'],
        link: ['Open', 'Edit', 'UnLink']
    };

    const DisplayingErrorMessagesSchema = Yup.object().shape({
        title: Yup.string().required("Title is required"),
        description: Yup.string().required("Description is required"),
        cat_id: Yup.string().required("Category is required"),
        scat_id: Yup.string().required("Sub Category is required"),
        tag: Yup.array().min(1, "Choose at least one tag").required("Tags are required")
    });
    const DisplayingErrorMessagesSchemaSec = Yup.object().shape({
        title: Yup.string().required("Title is required"),
        description: Yup.string().required("Description is required"),
        cat_id: Yup.string().required("Category is required"),
        tag: Yup.array().min(1, "Choose at least one tag").required("Tags are required")
    });

    const navigate = useNavigate();
    useEffect(() => {
        handleFetchAllBlogTags(0);
        handleFetchAllBlogCategory(0);
    }, [])


    const handlePlusClick = () => {
        // Add a new FAQ container and expand its size
        setFaqContainers([...faqContainers, { question: '', answer: '' }]);
    };


    const handleMinusClick = (index) => {
        // Remove the relevant FAQ container
        setFaqContainers(faqContainers.filter((_, i) => i !== index));
    };

    const handleFetchAllBlogCategory = async (pageNo) => {
        const resAllBlogCategory = await getBlogCategory({ pageNo: pageNo });
        if (resAllBlogCategory?.success) {
            const blogCat = resAllBlogCategory?.data;
            if (blogCat?.length) {
                const cData = blogCat?.map(option => ({
                    value: option.id,
                    label: option.name
                })) || [];


                setCategoryFormatted(cData);
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }


        setLoading(false);
    }


    // fetch all topic by categry
    const handleFetchAllBlogSubCategory = async (pageNo, cId) => {
        const resAllBlogSubCategory = await getBlogSubCategory({ pageNo: pageNo, catId: cId });
        if (resAllBlogSubCategory?.success) {
            const blogSCat = resAllBlogSubCategory?.data;
            if (blogSCat?.length) {
                const scData = blogSCat?.map(option => ({
                    value: option.id,
                    label: option.name
                })) || [];


                setSubCategoryFormatted(scData);
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }

        setLoading(false);
    }

    const formData = [
        {
            id: 'title',
            title: 'Title',
            type: 'text',
            name: 'title',
            placeholder: 'Blog Title',
            required: true,
            editable: true
        },
        {
            id: 'cat_id',
            title: 'Category',
            type: 'text',
            name: 'cat_id',
            placeholder: 'Pick one from the list',
            required: true,
            editable: true,
            isCategory: true
        }, {
            id: 'scat_id',
            title: 'Sub Category',
            type: 'text',
            name: 'scat_id',
            placeholder: '',
            required: true,
            editable: true,
            isSCategory: true

        },
        {
            id: 'tag',
            title: 'Tag',
            type: 'text',
            name: 'tag',
            placeholder: 'Pick from the list',
            required: true,
            editable: true,
            isTag: true
        },
        {
            id: 'description',
            title: 'Description',
            type: 'text',
            name: 'description',
            placeholder: 'Put your blog description here',
            required: true,
            editable: true,
            width: true
        }
    ]


    const handlePostBlog = async (values, type) => {
        type == 'post' ? setSubmitBtnLoading(true) : setDraftBtnLoading(true);
        const postParams = { token: globalToken, faq: faqContainers, title: values?.title, description: values?.description, cat_id: values?.cat_id, scat_id: values?.scat_id, tag:values.tag, type: type, image: image };
        const formData = new FormData();
        if(image){
            formData.append('image', image);
        }
        const resPostBlog = await postBlog(postParams, formData);
        if (resPostBlog?.success) {
            toast.success(resPostBlog?.data?.message || 'Blog posted successfully.');
            navigate('/blogs/my-blogs')
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }
        setSubmitBtnLoading(false);
        setDraftBtnLoading(false);
    }

    const handleCustomSelectOnChange = (value, type, label, setFieldValue) => {

        if (type == 'category') {
            setSfValue(setFieldValue);
            setSubCategoryFormatted([]);
            setDefaultSCat(null);
            setFieldValue('scat_id', null);

            if (value) {
                handleFetchAllBlogSubCategory(0, value)
                setCatId(value);
                setFieldValue('cat_id', value);
            } else {
                setCatId(null);
                setFieldValue('cat_id', null);
                // setCategoryFormatted([]);
            }
            setSCatId(null);

        }
        if (type == 'sCategory') {
            if (value) {
                findChoosenSubCat(value);
                setSCatId(value);
                setFieldValue('scat_id', value);
            } else {
                setSCatId(null);
                setDefaultSCat(null);
                setFieldValue('scat_id', null);
            }

        }
        if (type == 'tag') {
            if (value) {
                setTagId(value);
                setFieldValue('tag', value);
            } else {
                setTagId(null);
                setFieldValue('tag', null);
            }
        }

    }

    const findChoosenSubCat = (choosenSCat) => {
        const cSCat = subCategoryFormatted?.filter(sc => sc?.value == choosenSCat);
        setDefaultSCat(cSCat);
    }


    const closeCoverPicCropModal = () => {
        setIsCoverPicCropModalOpen(!isCoverPicCropModalOpen);
    }
    const handleCropProfilePic = () => {

    }

    const handleImageChange = (event) => {

        const file = event.target.files[0];
        if (file) {
            const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
            if (allowedTypes.includes(file.type)) {
                const reader = new FileReader();
                reader.onload = () => {
                    setCoverImage(reader.result);
                };
                reader.readAsDataURL(file);
                setIsCoverPicCropModalOpen(true);

            } else {
                alert('Please choose a valid image file.');
            }
        }
        event.target.value = '';
    };

    const handleUserPicChange = () => {
        chooseCoverPicRef.current.click();
    };

    const getOriginalImageSize = () => {
                    // Ensure coverImage is defined and valid
                    if (!coverImage) {
                        console.error("coverImage is not defined or invalid.");
                        return;
                    }
                
                    // Create a new Image object
                    const img = new window.Image();
                
                    // Set the src to the base64-encoded coverImage
                    img.src = coverImage;
                
                    // When the image loads, access its natural width and height
                    img.onload = () => {
                        const originalWidth = img.naturalWidth;
                        const originalHeight = img.naturalHeight;
                        console.log(`Original Size: ${originalWidth}x${originalHeight}`);
                
                        // Example of what you might want to do with the image
                        const timestamp = new Date().getTime();
                        const fileName = `original-image-${timestamp}.jpg`;
                
                        // You can skip the fetch if you don't need to reprocess the base64 image
                        // If you want to create a new File object from the base64 string:
                        const byteCharacters = atob(coverImage.split(',')[1]); // Decode the base64 string
                        const byteArrays = [];
                
                        for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
                            const slice = byteCharacters.slice(offset, offset + 1024);
                            const byteNumbers = new Array(slice.length);
                            for (let i = 0; i < slice.length; i++) {
                                byteNumbers[i] = slice.charCodeAt(i);
                            }
                            byteArrays.push(new Uint8Array(byteNumbers));
                        }
                
                        const blob = new Blob(byteArrays, { type: 'image/jpeg' });
                        const originalFile = new File([blob], fileName, { type: 'image/jpeg' });
                
                        setCroppedCoverImage(URL.createObjectURL(blob));
                        setImage(originalFile);
                        closeCoverPicCropModal();
                    };
                
                    // Handle any errors that occur while loading the image
                    img.onerror = (error) => {
                        console.error("Failed to load image:", error);
                    };
                };

    const getCroppedData = () => {
        if (typeof cropperRef.current?.cropper !== "undefined") {
            cropperRef.current?.cropper.getCroppedCanvas().toBlob((blob) => {
                const timestamp = new Date().getTime();
                const fileName = `cropped-image-${timestamp}.jpg`;
                const croppedFile = new File([blob], fileName, { type: 'image/jpeg' });
               setCroppedCoverImage(URL.createObjectURL(blob));
                setImage(croppedFile);
                closeCoverPicCropModal();
            }, 'image/jpeg', 0.5);
        }
    }

    const removeImg = () => {
        setCroppedCoverImage(null);
        setImage(null);
        setCoverImage(null);
    }

    const handleFetchAllBlogTags = async (pNo) => {
            const resAllBlogTag = await getBlogTags({ pageNo: pNo });
            if (resAllBlogTag?.success) {
                const blogTag = resAllBlogTag?.data;
                if (blogTag?.length) {
                    const cData = blogTag?.map(option => ({
                        value: option.id,
                        label: capitalizeLabel(option.name)
                    })) || [];
                    setTagFormatted(cData); 
                }
            } else {
                toast.error(ERROR_SOMETHING_WENT_WRONG)
            }
            setLoading(false);
        }

    return (
        <>
            <BreadCrumbs />
            {loading ? (
                <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                    <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                        <div className="h-10 w-10">
                            <Loadingicon />
                        </div>
                    </div>
                </div>) : (
                <div className="py-0 px-0 w-100 card-body gap-4s">

                    <div className="p-4 w-100 my-2">
                        <Formik
                            enableReinitialize={true}
                            initialValues={{
                                title: "",
                                description: "",
                                cat_id: null,
                                scat_id: null,
                                tag: null
                            }}
                            validationSchema={sCatId ? DisplayingErrorMessagesSchemaSec : DisplayingErrorMessagesSchema}
                            onSubmit={(values) => {
                                if (user?.email_verified == 1) {
                                    if (values) {
                                        if (submitAction === 'post') {
                                            handlePostBlog(values, 'post') // Call post function
                                        } else if (submitAction === 'draft') {
                                            handlePostBlog(values, 'draft') // Call draft function
                                        }
                                    }
                                } else {
                                    toast.error('Please verify your email');
                                    setTimeout(() => { setIsEmailVerifyCover(true) }, 200)
                                }
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                setFieldValue,
                                handleSubmit,
                            }) => (
                                <form className="" onSubmit={handleSubmit}>
                                    <div className="row">
                                        {
                                            formData?.map((fieldMeta, _i) => (
                                                <div key={"postBlogForm"+fieldMeta?.id} className={`position-relative  ${fieldMeta.width ? 'col-md-12' : 'col-md-12'}`}>
                                                    <label
                                                        htmlFor={fieldMeta?.id}
                                                        className={`block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500`}
                                                    >
                                                        {fieldMeta?.title}

                                                        {
                                                            fieldMeta?.required ? <span className="text-red-400 text-base">*</span> : null
                                                        }
                                                    </label>
                                                    <input type="password" name="fake-password" autoComplete="new-password" style={{ display: 'none' }} />
                                                    {
                                                        fieldMeta.width ?
                                                            <>
                                                                <RichTextEditorComponent height={350} toolbarSettings={toolbarSettings}
                                                                    quickToolbarSettings={quickToolbarSettings}
                                                                    insertImageSettings={{saveFormat: "Base64" }}
                                                                    id={'editorContent' + _i}
                                                                    change={(e) => {
                                                                        setFieldValue(fieldMeta?.id, e.value); // Update Formik value
                                                                    }}
                                                                    placeholder="Enter content here">
                                                                    <div></div>
                                                                    <Inject services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar, Table]} />
                                                                </RichTextEditorComponent>
                                                                {/* <Editor
                                                                    apiKey={TINY_EDITOR_KEY}
                                                                    onInit={(evt, editor) => moderateEditorRef.current = editor}
                                                                    init={{
                                                                        height: '200',
                                                                        auto_focus: true,
                                                                        menubar: false,
                                                                        plugins: 'hr lists table textcolor code link image',
                                                                        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic forecolor link image media | alignleft aligncenter alignright | hr bullist numlist table | subscript superscript | removeformat code',

                                                                        // allow custom url in link? nah just disabled useless dropdown..
                                                                        file_picker_types: 'image',
                                                                        file_picker_callback: function (cb, value, meta) {
                                                                            var input = document.createElement('input');
                                                                            input.setAttribute('type', 'file');
                                                                            input.setAttribute('accept', 'image/*');
                                                                            input.onchange = () => {
                                                                                const file = input.files[0];
                                                                                const reader = new FileReader();

                                                                                reader.onload = () => {
                                                                                    const base64 = reader.result.split(',')[1];
                                                                                    cb('data:image/jpeg;base64,' + base64);
                                                                                };

                                                                                reader.readAsDataURL(file);
                                                                            };

                                                                            input.click();
                                                                        },
                                                                        anchor_top: false,
                                                                        anchor_bottom: false,
                                                                        branding: false,
                                                                        loading: true
                                                                    }}
                                                                    onEditorChange={(content, editor) => {
                                                                        setFieldValue(fieldMeta?.id, content);

                                                                    }}
                                                                /> */}
                                                                {/* <textarea type={fieldMeta?.type}

                                                                    name={fieldMeta?.name}
                                                                    autoComplete={'off'}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    id={fieldMeta?.name} rows={6} placeholder={fieldMeta?.placeholder} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 w-100 focus:border-primary-600 block w-full p-2.5"></textarea> */}
                                                                <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                    {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                </div>
                                                            </>
                                                            : fieldMeta.isCategory ?
                                                                <div className="w-100" style={{ zIndex: 101, position: 'relative' }}>

                                                                    <div className="d-block w-100">
                                                                        {
                                                                            <CustomSelect options={categoryFormatted} isMultiple={false} isClearable={true} handleCustomSelectOnChange={handleCustomSelectOnChange}
                                                                                setFieldValue={setFieldValue}
                                                                                type={'category'} isSelectLoading={isCategoryLoading} placeholder={'Category'} />
                                                                        }

                                                                    </div>

                                                                    <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                        {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                    </div>
                                                                </div> : fieldMeta.isSCategory ?
                                                                    <div className="w-100" style={{ zIndex: 100, position: 'relative' }}>


                                                                        <div className="d-block w-100">
                                                                            <CustomSelect
                                                                                options={subCategoryFormatted}
                                                                                defaultOption={defaultSCat}
                                                                                isMultiple={false} isClearable={true} handleCustomSelectOnChange={handleCustomSelectOnChange}
                                                                                setFieldValue={setFieldValue}
                                                                                type={'sCategory'} isSelectLoading={isSubCategoryLoading} placeholder={'Sub Category'} />
                                                                        </div>

                                                                        <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                            {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                        </div>
                                                                    </div>
                                                                : fieldMeta.isTag ?
                                                                <div className="w-100" style={{ zIndex: 100, position: 'relative' }}>

                                                                    <div className="d-block w-100" >
                                                                        {
                                                                            <CustomSelect options={tagFormatted} isMultiple={true} isClearable={true} handleCustomSelectOnChange={handleCustomSelectOnChange}
                                                                                setFieldValue={setFieldValue}
                                                                                type={'tag'} isSelectLoading={isTagLoading} placeholder={'Choose among tags'} />
                                                                        }

                                                                    </div>

                                                                    <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                        {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                    </div>
                                                                </div> : <>
                                                                        <input
                                                                            disabled={!fieldMeta?.editable}
                                                                            type={fieldMeta?.type}
                                                                            name={fieldMeta?.id}
                                                                            autoComplete={'off'}
                                                                            id={fieldMeta?.name}
                                                                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"

                                                                            placeholder={fieldMeta?.title}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values[fieldMeta?.id] || ""}
                                                                        />
                                                                        <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                            {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                        </div>
                                                                    </>}

                                                </div>
                                            ))
                                        }


                                        <div className="col-md-12 mb-3">
                                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500" >Set Blog Image (Supported Formats JPG,PNG,BMP,WEBP)</label>
                                            {/* <input type="file" onChange={(event) => {
                                                setFieldValue('image', event.currentTarget.files[0]);
                                            }}
                                                onBlur={handleBlur}
                                                className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                                name="image" /> */}
                                            <input
                                                type="file"
                                                id="chooseProfilePic"
                                                ref={chooseCoverPicRef}
                                                style={{ display: 'none' }}
                                                accept="image/*"
                                                onChange={(e) => handleImageChange(e)}
                                            />
                                            <div className="relative inline-block">
                                                {
                                                    croppedCoverImage?<button className="btn btn-default remove-img-btn" onClick={()=>removeImg()} type="button"><i className="fa fa-times"></i></button>:null
                                                }
                                                
                                            <button onClick={() => handleUserPicChange()}  className="btn btn-outline" style={{border:'1px solid #f0f0f0'}} type="button">
                                                {
                                                    croppedCoverImage ? <img src={croppedCoverImage} alt="Cover Image" className="h-10 rounded" /> : <img src={addImage} alt="Add Image" className="h-10 w-10 add-img-placeholder" />
                                                }
                                                
                                            </button>
                                            </div>
                                            
                                        </div>

                                        <label htmlFor={'faq'}
                                            className={`block mb-2 text-sm font-medium position-relative text-gray-900 dark:text-gray-500`}
                                        >
                                            FAQ
                                            <button className="btn-plus" type="button" onClick={() => handlePlusClick()}><i className="fa fa-plus"></i></button>
                                        </label>
                                        <div className="col-md-12">                                            {
                                            faqContainers?.length ? faqContainers?.map((fq, index) => (
                                                <FAQItem key={fq.id} TINY_EDITOR_KEY={TINY_EDITOR_KEY} moderateEditorRef={moderateEditorRef} faq={faqContainers} fq={fq} setFaqContainers={setFaqContainers} faqIndex={index} handleMinusClick={handleMinusClick} setFieldValue={setFieldValue} />
                                            )) : null
                                        }
                                        </div>

                                    </div>
                                    <div className="col-md-4 d-flex gap-2 mt-3">
                                        <button
                                            type="submit"
                                            disabled={submitBtnLoading}
                                            onClick={() => setSubmitAction('post')}
                                            className="mt-2 w-fulls text-white bg-themecolor hover:bg-opacity-75 disabled:bg-opacity-50 focus:ring-1 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-md-5 px-3 py-2.5 text-center flex justify-center"
                                        >
                                            {submitBtnLoading ? (
                                                <div className="h-5 w-5">
                                                    <Loadingicon />
                                                </div>
                                            ) : (
                                                "Submit"
                                            )}
                                        </button>
                                        <button
                                            type="submit"
                                            disabled={draftBtnLoading}
                                            onClick={() => setSubmitAction('draft')}
                                            className="mt-2 w-fulls text-white bg-themecolor hover:bg-opacity-75 disabled:bg-opacity-50 focus:ring-1 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-md-5 px-3 py-2.5 text-center flex justify-center"
                                        >
                                            {draftBtnLoading ? (
                                                <div className="h-5 w-5">
                                                    <Loadingicon />
                                                </div>
                                            ) : (
                                                "Save As Draft"
                                            )}
                                        </button>
                                    </div>
                                </form>
                            )}

                        </Formik>
                    </div>
                </div>


            )
            }


            {/* modal for blog banner cropper */}
            <Modal
                closeModal={closeCoverPicCropModal}
                setIsOpen={setIsCoverPicCropModalOpen}
                isOpen={isCoverPicCropModalOpen}
                callBack={handleCropProfilePic}
                title="Crop Blog Pic"
                modalFooter={true}
                isMaxL={true}
            >
                <Cropper
                    src={coverImage}
                    style={{ height: 450, width: '100%' }}
                    initialAspectRatio={4 / 2}
                    aspectRatio={4 / 2}
                    guides={true}
                    ref={cropperRef}
                    // minCropBoxWidth={1200} 
                    // minCropBoxHeight={450} 
                    dragMode="move"
                />
                <div className="mt-4 modal-footer gap-2 mt-10 flex justify-end">
                    <button
                        type="button"
                        className="bg-tranparent border py-2 px-8 mr-3 rounded-lg border-themecolor text-themecolor text-lg w-[119px]"
                        onClick={() => getOriginalImageSize()}
                    >
                        {
                            imageLoading ? <div className="h-5 w-5 m-auto">
                                <Loadingicon />
                            </div> : <span>Skip</span>
                        }
                    </button>
                    <button
                        type="button"
                        className="bg-themecolor text-white border py-2 px-8 mr-3 rounded-lg border-themecolor text-themecolor text-lg disabled:opacity-50 w-[119px] flex justify-center items-center"
                        onClick={() => getCroppedData()}
                    >
                        {
                            imageLoading ? <div className="h-5 w-5 m-auto">
                                <Loadingicon />
                            </div> : <span>Crop</span>
                        }
                    </button>
                </div>

            </Modal>
        </>
    );
}
export default PostABlog;
