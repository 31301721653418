import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Loadingicon } from "../../../../AppIcons";
import BreadCrumbs from "../../../common/BreadCrumbs";
import { useAuth } from "../../../../Context/AuthContext";
import { Formik } from "formik";
// import { Editor } from "@tinymce/tinymce-react";
import { getTutorialCategory, getTutorialChaptersList, postEditChapter } from "../../../../api/tutorial";
import { EDITOR_KEY, ERROR_SOMETHING_WENT_WRONG, slugToNormal, TINY_EDITOR_KEY } from "../../../../utils/utils";

import { HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Table, Toolbar } from '@syncfusion/ej2-react-richtexteditor';
import { registerLicense } from '@syncfusion/ej2-base';
// Register your Syncfusion license key
registerLicense(EDITOR_KEY);


function EditChapter() {
    const moderateEditorRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const { globalToken } = useAuth();
    const [choosenTutorial, setChoosenTutorial] = useState(JSON.parse(localStorage.getItem('t__e') || '{}'));
    const [tutorialChapters, setTutorialChapters] = useState();
    const [isChapter, setIsChapter] = useState(false);

    const toolbarSettings = {
        items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
            'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
            'LowerCase', 'UpperCase', '|',
            'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
            'Outdent', 'Indent', '|',
            'CreateLink', 'Image', '|', 'ClearFormat', 'Print',
            'SourceCode','CreateTable', 'FullScreen', '|', 'Undo', 'Redo']
    };
    const quickToolbarSettings = {
        image: ['Replace', 'Align', 'Caption', 'Remove', 'InsertLink', 'OpenImageLink', '-', 'EditImageLink', 'RemoveImageLink', 'Display', 'AltText', 'Dimension'],
        link: ['Open', 'Edit', 'UnLink']
    };


    const DisplayingErrorMessagesSchema = Yup.object().shape({
        title: Yup.string().required("Chapter Title is required"),
        id: Yup.string().required("Chapter is required"),
        description: Yup.string().required("Description is required"),
    });

    const navigate = useNavigate();

    useEffect(() => {
        if (choosenTutorial) {
            handleChaptersList(choosenTutorial?.id)
        }
        return () => {
            localStorage.removeItem('t__e');
        }

    }, [])

    // get all chapters list
    const handleChaptersList = async (tId) => {
        const resChaptersList = await getTutorialChaptersList({ token: globalToken, tutorialId: tId })
        if (resChaptersList?.success) {
            setTutorialChapters(resChaptersList?.data)
        }
    }



    const handleAddChapter = async (values) => {
       
        setBtnLoading(true)
        const resPostEditChapter = await postEditChapter({ token: globalToken, description: values?.description, title: values?.title, tutorialId: choosenTutorial?.id, ordering: 1, parentId: 0, id:values?.id });

        if (resPostEditChapter?.success) {
            toast.success(resPostEditChapter?.message);
            setBtnLoading(false);
            handleChaptersList(choosenTutorial?.id);
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG);
            setBtnLoading(false)
        }


    }



    const formData = [
        {
            id: 'id',
            title: 'Chapter',
            type: 'text',
            name: 'id',
            placeholder: 'Choose chapter to edit',
            required: true,
            editable: true,
            isChapter:true
        },
        {
            id: 'title',
            title: 'Chapter Title',
            type: 'text',
            name: 'title',
            placeholder: 'Chapter Title',
            required: true,
            editable: true
        },
        {
            id: 'description',
            title: 'Description',
            type: 'text',
            name: 'description',
            placeholder: 'Description',
            required: true,
            editable: true,
            width: true
        }
    ]


    const handleChapterOnChange = (e, setFieldValue) =>{
       
        const selectedChapterId = e.target.value;
        const selectedChapter = tutorialChapters?.find(chapter => chapter.id == selectedChapterId);
        setFieldValue('title', selectedChapter?.title);
        setFieldValue('description', selectedChapter?.description);
        // moderateEditorRef?.current?.setContent(selectedChapter?.description || '');
        if (moderateEditorRef.current) {
            moderateEditorRef.current.value = selectedChapter?.description || ''; // Clear editor content
          }
        setIsChapter(true);
    }

    return (
        <>
            {/* <BreadCrumbs /> */}
            <nav aria-label="breadcrumb" className="pt-0 px-0 pb-0 breadcrumb-card">
                <ol className="breadcrumb px-0 pt-0 breadcrumb-main-mobilea" style={{ display: 'flex', flexWrap: 'nowrap' }}>
                    <li className={`breadcrumb-item capitalize rest-bread`}>
                        <NavLink to='/' style={{ fontSize: 14, textDecoration: 'none' }}>Home</NavLink>
                    </li>
                    <li className={`breadcrumb-item capitalize rest-bread`}>
                        <NavLink to='/tutorials' style={{ fontSize: 14, textDecoration: 'none' }}>Tutorials</NavLink>
                    </li>
                    <li className={`breadcrumb-item capitalize rest-bread`}>
                        <NavLink to='/tutorials/my-tutorials' style={{ fontSize: 14, textDecoration: 'none' }}>My Tutorials</NavLink>
                    </li>
                    <li
                        className={`breadcrumb-item capitalize active clamping ellipsis`}
                    >
                        {slugToNormal(choosenTutorial?.title)}
                    </li>
                    <li
                        className={`breadcrumb-item capitalize active clamping ellipsis`}
                    >
                        Edit Chapter
                    </li>
                </ol>
            </nav>
            {loading ? (
                <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                    <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                        <div className="h-10 w-10">
                            <Loadingicon />
                        </div>
                    </div>
                </div>) : (
                <div className="py-0 px-0 w-100 card-body gap-4s">

                    <div className="p-4 w-100 my-2">
                        <Formik
                            enableReinitialize={true}
                            initialValues={{
                                title: "",
                                description: "",
                                id:null
                            }}
                            validationSchema={DisplayingErrorMessagesSchema}
                            onSubmit={async (values, { resetForm }) => {
                                if (values) {
                                    handleAddChapter(values);
                                    // if (success) {
                                    //     resetForm(); // Reset the form after successful submission
                                    //     moderateEditorRef.current.setContent('');
                                    // }
                                }
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                setFieldValue,
                                handleSubmit,
                            }) => (
                                <form className="" onSubmit={handleSubmit}>
                                    <div className="row">
                                        {
                                            formData?.map((fieldMeta, _i) => (
                                                <div className={`position-relative  ${fieldMeta.width ? 'col-md-12' : 'col-md-12'}`}>
                                                    <label
                                                        htmlFor={fieldMeta?.id}
                                                        className={`block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500 ${isChapter || fieldMeta?.isChapter?'':'d-none'}`}
                                                    >
                                                        {fieldMeta?.title}

                                                        {
                                                            fieldMeta?.required ? <span className="text-red-400 text-base">*</span> : null
                                                        }
                                                    </label>
                                                    <input type="password" name="fake-password" autoComplete="new-password" style={{ display: 'none' }} />
                                                   
                                                    {   
                                                        fieldMeta?.isChapter && tutorialChapters?.length ? <>
                                                        <select
                                                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                                            name={fieldMeta?.name}
                                                            id={fieldMeta?.id}
                                                            onChange={(e)=>{handleChange(e);handleChapterOnChange(e, setFieldValue)}}
                                                            onBlur={handleBlur}
                                                        >
                                                            <option value={null} disabled selected style={{opacity:0.6}}>Choose chapter to edit</option>
                                                            {
                                                                tutorialChapters?.map((chapter, index)=>(
                                                                    <option value={chapter?.id}>{chapter?.title}</option>
                                                                ))
                                                            }
                                                            
                                                        </select>
                                                   
                                                            <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                            </div>
                                                        </>:
                                                        fieldMeta.width ?
                                                            <div className={`${isChapter?'':'h-0 o-0 overflow-hidden'}`}>
                                                                {/* <Editor
                                                                    apiKey={TINY_EDITOR_KEY}
                                                                    onInit={(evt, editor) => moderateEditorRef.current = editor}
                                                                    init={{
                                                                        height: '200',
                                                                        auto_focus: true,
                                                                        menubar: false,
                                                                        plugins: 'hr lists table textcolor code link image',
                                                                        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic forecolor link image media | alignleft aligncenter alignright | hr bullist numlist table | subscript superscript | removeformat code',

                                                                        // allow custom url in link? nah just disabled useless dropdown..
                                                                        file_picker_types: 'image',
                                                                        file_picker_callback: function (cb, value, meta) {
                                                                            var input = document.createElement('input');
                                                                            input.setAttribute('type', 'file');
                                                                            input.setAttribute('accept', 'image/*');
                                                                            input.onchange = () => {
                                                                                const file = input.files[0];
                                                                                const reader = new FileReader();

                                                                                reader.onload = () => {
                                                                                    const base64 = reader.result.split(',')[1];
                                                                                    cb('data:image/jpeg;base64,' + base64);
                                                                                };

                                                                                reader.readAsDataURL(file);
                                                                            };

                                                                            input.click();
                                                                        },
                                                                        anchor_top: false,
                                                                        anchor_bottom: false,
                                                                        branding: false,
                                                                        loading: true
                                                                    }}
                                                                    onEditorChange={(content, editor) => {
                                                                        setFieldValue(fieldMeta?.id, content);

                                                                    }}
                                                                /> */}
                                                                  <RichTextEditorComponent
                                                                  ref={moderateEditorRef} 
                                                                  height={250} toolbarSettings={toolbarSettings}
                                                                        quickToolbarSettings={quickToolbarSettings}
                                                                        insertImageSettings={{saveFormat: "Base64" }}
                                                                        id={'eChapter'+_i}
                                                                        // value={fq?.answer || ''}
                                                                        change={(content) => {
                                                                            setFieldValue(fieldMeta?.id, content.value); 
                                                                        }}
                                                                        placeholder="Enter answer here">
                                                                        {/* <span dangerouslySetInnerHTML={{__html:fq?.answer}}></span> */}
                                                                <Inject services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar, Table]}/>
                                                                </RichTextEditorComponent>
                                                                <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                    {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                </div>
                                                            </div>
                                                            : isChapter ? <>
                                                                <input
                                                                    disabled={!fieldMeta?.editable}
                                                                    type={fieldMeta?.type}
                                                                    name={fieldMeta?.id}
                                                                    autoComplete={'off'}
                                                                    id={fieldMeta?.name}
                                                                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"

                                                                    placeholder={fieldMeta?.title}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values[fieldMeta?.id] || ""}
                                                                />
                                                                <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                    {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                </div>
                                                            </>:<></>}

                                                </div>
                                            ))
                                        }
                                    </div>
                                    {
                                        isChapter?<div className="col-md-2 mt-3">
                                        <button
                                            type="submit"
                                            disabled={btnLoading}
                                            className="mt-2 w-full text-white bg-themecolor hover:bg-opacity-75 disabled:bg-opacity-50 focus:ring-1 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center flex justify-center"
                                        >
                                            {btnLoading ? (
                                                <div className="h-5 w-5">
                                                    <Loadingicon />
                                                </div>
                                            ) : (
                                                "Submit"
                                            )}
                                        </button>
                                    </div>:null
                                    }
                                    
                                </form>
                            )}

                        </Formik>
                    </div>
                </div>


            )
            }

        </>
    );
}
export default EditChapter;
