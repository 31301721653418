import React, { useEffect, useState } from 'react';
import { useVerifyUserStatus } from '../../../Context/VerifyStatusContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import { getShowtalentNotifications, getShowtalentToken, markedAsReadNotification } from '../../../api/OurService';
import { CDN_BASE_URL, SHOWTALENT_USER_DETAIL_BASE_LINK, formatDate } from '../../../utils/utils';
import { useAuth } from '../../../Context/AuthContext';
import BreadCrumbs from '../../common/BreadCrumbs';
import { Loadingicon } from '../../../AppIcons';
import NotFound from '../../common/notfound';
// import Users from '../../users';
//popularUsers
function Inbox() {
  const { isEmailVerified } = useVerifyUserStatus();
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const[loading, setLoading] = useState(true);
  const currentPath = location.pathname;
  const [allShowtalentNotifications, setAllShowtalentNotifications] = useState();
  useEffect(()=>{
    handleTabChange('notifications');
    fetchShowtalentToken();
  },[])


  const fetchShowtalentToken = async () =>{
      const showTalentTokenResponse = await getShowtalentToken({method:'get-api-token', tuteehubId:user?.tuteehub_id});
      
      if(showTalentTokenResponse?.response?.original?.status==1){
        return showTalentTokenResponse?.response?.original?.data?.token;
      }
  }

  const handleTabChange = async (key) => {
    if (key === "notifications") {
      const  sToken = await fetchShowtalentToken();
      
    if(sToken){
        let showtalentNotificationResponse = await getShowtalentNotifications({token: sToken, method:'get_notification', language:'en'});
        
        if(showtalentNotificationResponse?.response?.status == 1){
          setAllShowtalentNotifications(showtalentNotificationResponse?.response?.result);
        }
      }
    }
    setLoading(false)
  };


  const handleMarkAsReadNotification = async (nId) =>{
    const markedAsReadRes = await markedAsReadNotification({method:'update_notification', id:nId});
      // console.log(markedAsReadRes)
    // if(showTalentTokenResponse?.response?.original?.status==1){
    //   return showTalentTokenResponse?.response?.original?.data?.token;
    // }
  }

  return (
        <>
         <BreadCrumbs />
        <div className=" py-4 px-0 grid grid-cols-1 group-card card-body gap-4">
                {loading ? (
                    <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                        <div className="h-10 w-10">
                            <Loadingicon />
                        </div>
                    </div>
                ) : (
                    <>
                    <div className="px-3">
                    <div className="row" id="inbox-modal">
                <Tabs defaultActiveKey="messages" id="inbox-tab" variant="pills" justify  onSelect={handleTabChange}>
                  <Tab eventKey="messages" title="Messages">
                    <div className='content-tab my-4'>
                      <NotFound message={'No any messages'} />
                         
                    </div>
                  </Tab>
                  <Tab eventKey="notifications" title={`Notifications${allShowtalentNotifications?.unreadNotificationCount>0?'('+allShowtalentNotifications?.unreadNotificationCount+')':''}`}>
                  <div className='content-tab my-4'>
                    {
                      allShowtalentNotifications?.data?.length?allShowtalentNotifications?.data.map((notification, index)=>(
                        <div className="users-list-item px-3 w-100 rounded d-flex align-items-center py-2 justify-content-between">
                        <div className="d-flex w-100 gap-2 align-items-center">
                        <a href={`${SHOWTALENT_USER_DETAIL_BASE_LINK}${notification?.username}`} target='_blank'> 
                            <div className="user-pic">
                                {
                                    notification?.sender?.image!==''?<img src={`${CDN_BASE_URL}${notification?.sender?.image}`} style={{width:40,height:40,borderRadius:100}}/>:<img src={require('../../../assets/images/profile_placeholder.png')}/>
                                }
                                
                            </div>
                            </a>
                            <div className='flex-1'>
                              <a href={`${SHOWTALENT_USER_DETAIL_BASE_LINK}${notification?.username}`} target='_blank'>                              
                            <h6 className="m-0 text-capitalize hover-theme">{notification?.sender?.name}</h6>
                            </a>
                            <div className='d-flex justify-content-between'>
                              <div className='d-flex align-items-center'>
                                <small className="color-gray mb-0 small">{notification?.text}</small>
                                {/* {notification?.viewed==0?<><span className="big-dots"></span>
                               <small> <span className="label-warning cursor-pointer" onClick={()=>handleMarkAsReadNotification(notification?.id)}> mark as read</span></small></>:null} */}
                              </div>
                            <small className="color-gray mb-0 small">{formatDate(notification?.created_at)}</small>
                            </div>
                           
                            </div>
                        </div>
                        {/* {
                          publicUserDetails?.tuteehub_id == user?.tuteehub_id?
                        <div className="meta-right">
                            {
                                following?.status==0? <button className="btn justify-content-center px-2 py-1 d-flex align-items-center border-0 btn-primary w-100 btn-sm credit-btn" onClick={()=>handleFollowProfile(following?.id)}>FOLLOW <i className="fab fa-plus"></i></button>: <button className="btn justify-content-center px-2 py-1 d-flex align-items-center border-0 btn-primarys w-100 btn-sm credit-btns credit-btn-default" onClick={()=>handleUnFollowProfile(following?.usr_id)}>FOLLOWING</button>
                            }
                        </div>:null

} */}
                    </div>
                      )):
                      
                      <NotFound message={'No any notifcation'}/>
                    }
                         
                    </div>
                  </Tab>
                
                </Tabs>
            </div>
            </div>
            </>)
}
</div>

</>
  )
}

export default Inbox;