import { FORUM_BASE_URL } from "../../utils/utils";

export const getForumSubject = (params) => {
  const { pageNo } = params;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  let requestOptions = {
    method: "GET",
    headers: myHeaders,
  };
  return fetch(`${FORUM_BASE_URL}forum/category?page=${pageNo}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
};

export const getForumTopic = (params) => {
  const { pageNo, forumCatId } = params;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  let requestOptions = {
    method: "GET",
    headers: myHeaders,
  };
  return fetch(
    `${FORUM_BASE_URL}forum/sub-category/${forumCatId}?page=${pageNo}`,
    requestOptions
  )
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
};

export const getForumList = (params) => {
  const { pageNo, forumSubCatSlug } = params;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  let requestOptions = {
    method: "GET",
    headers: myHeaders,
  };
  return fetch(
    `${FORUM_BASE_URL}forum/qa/${forumSubCatSlug}?page=${pageNo}`,
    requestOptions
  )
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
};

export const getForumListByTagId = (params) => {
  const { pageNo, forumTagId } = params;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  let requestOptions = {
    method: "GET",
    headers: myHeaders,
  };
  return fetch(
    `${FORUM_BASE_URL}forum/qa-by-tag/${forumTagId}?page=${pageNo}`,
    requestOptions
  )
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
};

export const getMyForumList = (params) => {
  const { pageNo, token } = params;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  let requestOptions = {
    method: "GET",
    headers: myHeaders,
  };
  return fetch(`${FORUM_BASE_URL}forum/my-qa?page=${pageNo}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
};

export const deleteForum = (params) => {
  const { forumId, token } = params;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  let formData = new FormData();
  formData.append("qa_id", forumId);

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData,
  };
  return fetch(`${FORUM_BASE_URL}forum/delete`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
};

export const getForumDetails = (params) => {
  const { forumId, token } = params;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  let requestOptions = {
    method: "GET",
    headers: myHeaders,
  };
  return fetch(`${FORUM_BASE_URL}forum/detail/${forumId}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
};

export const likeUnlikeDislike = (params) => {
  const { forumId, voteType, token } = params;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  let formData = new FormData();
  formData.append("vote", voteType);
  formData.append("qa_id", forumId);

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData,
  };
  return fetch(`${FORUM_BASE_URL}forum/votes`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
};

export const postAForumQuestion = (params) => {
  const { scat_id, cat_id, question, token, type, id, tag } = params;
  
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  let formData = new FormData();
  formData.append("cat_id", cat_id);
  formData.append("scat_id", scat_id);
  formData.append("question", question);
  if(tag){
        tag.forEach((t, index) => {
            formData.append(`tag[${index}]`, t.label);
        });
    }
  // formData.append('faq', JSON.stringify(faq));

  // formData.append('structure_data', structureData);
  if (id) {
    formData.append("id", id);
  }
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData,
  };
  const fUrl =
    type == "edit"
      ? `${FORUM_BASE_URL}forum/update`
      : `${FORUM_BASE_URL}forum/add`;
  return fetch(fUrl, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
};

export const likeUnlikeDislikeAnswer = (params) => {
  const { forumId, voteType, token, answerId } = params;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  let formData = new FormData();
  formData.append("like", voteType);
  formData.append("qa_id", forumId);
  formData.append("answer_id", answerId);

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData,
  };
  return fetch(`${FORUM_BASE_URL}forum/like`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
};

export const forumCommentAndReply = (params) => {
  const { forumId, comment, answerId, token } = params;

  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  let formData = new FormData();
  formData.append("qa_id", forumId);
  formData.append("answers", comment);
  formData.append("answer_id", answerId || 0);

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData,
  };
  return fetch(`${FORUM_BASE_URL}forum/answer`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
};

export const getSearchedData = (params) => {
  const { pageNo, keyword } = params;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  // let formData = new FormData();
  // formData.append('keyword', keyword);
  // formData.append('type', type);
  let requestOptions = {
    method: "GET",
    headers: myHeaders,
    //   body: formData
  };
  return fetch(
    `${FORUM_BASE_URL}forum/search?page=${pageNo}&title=${keyword}`,
    requestOptions
  )
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
};

export const getForumTags = () => {
      // const { pageNo } = params;
      let myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      let requestOptions = {
          method: "GET",
          headers: myHeaders,
      };
      return fetch(`${FORUM_BASE_URL}forum/alltags`, requestOptions)
          .then((response) => response.json())
          .catch((error) => console.log("error", error));
  };