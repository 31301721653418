    import React, { useEffect, useState } from 'react';
    import SectionDescription from '../../../common/sectionDescription';
    import BreadCrumbs from '../../../common/BreadCrumbs';
    import { CDN_BASE_URL, ERROR_SOMETHING_WENT_WRONG, nFormatter, slugToNormal, yearSatra } from '../../../../utils/utils';
    import { getAllStreams } from '../../../../api/careernews';
    import { toast } from "react-toastify";
    import { Loadingicon } from '../../../../AppIcons';
    import ViewAllButton from '../../../common/viewallbutton';
    import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
    import { getBlogCategory, getBlogListing, getBlogSubCategory, getSearchedData } from '../../../../api/blog';
    import { useSearch } from '../../../../Context/SearchContext';
    import ExamListItem from '../common/examslist';
    import { getExamListing, getSearchedList } from '../../../../api/exam';
    import { useAuth } from '../../../../Context/AuthContext';
import NotFound from '../../../common/notfound';
    function ExamsList() {
        const { globalToken } = useAuth();
        const [pageNo, setPageNo] = useState(1);
        const [showLoadMore, setShowLoadMore] = useState(false);
        const [loading, setLoading] = useState(true);
        const [isNotFound, setIsNotFound] = useState(false);
        const [loadingLoadMore, setLoadingLoadMore] = useState(false);
        const [allContent, setAllContent] = useState([]); 
        const [topic, setTopic] = useState(null);
        const [isSearch, setIsSearch] = useState(false);
        const [searchTerm, setSearchTerm] = useState();
        const [totalRecordFound, setTotalRecordFound] = useState(0);
        const [examSubCatId, setExamSubCatId] = useState();
        const [filter, setFilter] = useState('all'); // State to manage filter selection
        const [filteredContent, setFilteredContent] = useState([]);
        const location = useLocation();
        const paramDatas = new URLSearchParams(location.search);
        const navigation = useNavigate();
        const { searchValue } = useSearch();

        const params = useParams();

        useEffect(() => {
            const searchTerm = paramDatas?.get('searchTerm');
            if(searchTerm){
                searchExam(1, searchTerm);
                setSearchTerm(searchTerm);
            } else if (params?.examSubCatSlug) {
                const ESubId = params?.examSubCatSlug;
                setExamSubCatId(ESubId)
                handleExamsBySubCategory(1, ESubId);
            }
        }, []);
        useEffect(() => {
                
            const searchTerm = paramDatas?.get('searchTerm');
            if(searchTerm){
                searchExam(1, searchTerm);
                setSearchTerm(searchTerm);
            }else{
                setSearchTerm('');
                setIsSearch(false);
                if(examSubCatId){
                handleExamsBySubCategory(1, examSubCatId);
                }
            }
                
        }, [searchValue]);

        const handleFilterChange = async (e) => {
            setFilter(e.target.value);
            if (e.target.value === 'attempted') {
                const filteredExams = await allContent.filter(exam => exam.is_attempted);
                    setFilteredContent(filteredExams);
            } else {
                setFilteredContent(allContent);
            }
        };

        // fetch all blogs by subcategory
        const handleExamsBySubCategory = async (pageNo, sCatId) => {
            const resAllExamSubCategory = await getExamListing({ token: globalToken, pageNo: pageNo, subcatId: sCatId });
            if (resAllExamSubCategory?.isExam) {
                setTopic(resAllExamSubCategory?.topic); 
                setIsNotFound(false)
                resAllExamSubCategory?.exams?.next_page_url ? setShowLoadMore(true) : setShowLoadMore(false);
                if (resAllExamSubCategory?.exams?.data?.length && pageNo == 1) {
                    setAllContent(resAllExamSubCategory?.exams?.data);
                } else if (resAllExamSubCategory?.exams?.data?.length && pageNo !== 1) {
                    setAllContent([...allContent, ...resAllExamSubCategory?.exams?.data]);
                } else {
                    if (pageNo == 1) {
                        setAllContent([]);
                        setIsNotFound(true);
                    }
                }
            } else {
                toast.error(ERROR_SOMETHING_WENT_WRONG)
            }
            setLoading(false);
            setLoadingLoadMore(false);
        }


        //load more blog by subcat data
        const loadMore = () => {
            setLoadingLoadMore(true);
            const nxtPage = pageNo + 1;
            
            if (searchTerm?.length) {
                searchExam(nxtPage, searchTerm, 'loadMore');
            }else{
                handleExamsBySubCategory(nxtPage, examSubCatId);
            }
            setPageNo(nxtPage);
        }



        const handleSearchExam = (e) => {
            const searchTxt = e.target.value;
            setPageNo(1);
            setSearchTerm(searchTxt);
        
            if (searchTxt == '') {   
                setIsSearch(false);           
                setLoading(true);          
                handleExamsBySubCategory(1, examSubCatId);
                return;
            }
            if (searchTxt !== '' && e.key === "Enter") {
                setIsSearch(false);
                searchExam(1, searchTxt);
            }
        }

        const searchExam = async (pNo, keyword, loadMore=null) => {
            !loadMore && setLoading(true);
            const  resSearchExams = await getSearchedList({ page: pNo, keyword });
            setIsSearch(true);

            if ( resSearchExams?.success) {
                setTotalRecordFound( resSearchExams?.exams?.total || 0)
                setIsNotFound(false)
                resSearchExams?.exams?.next_page_url ? setShowLoadMore(true) : setShowLoadMore(false);
                if ( resSearchExams?.exams?.data?.length && pNo == 1) {
                    setAllContent( resSearchExams?.exams?.data);
                } else if ( resSearchExams?.exams?.data?.length && pNo !== 1) {
                    setAllContent([...allContent, ... resSearchExams?.exams?.data]);
                } else {
                    if (pageNo == 1) {
                        setAllContent([]);
                        setIsNotFound(true);
                    }
                }
            } else {
                toast.error(ERROR_SOMETHING_WENT_WRONG)
            }
            setLoading(false);
            setLoadingLoadMore(false);
        }





        return (
          <>
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex justify-content-between mb-3 w-100">
                <nav
                  aria-label="breadcrumb"
                  className="pt-0 px-0 pb-0 breadcrumb-card"
                >
                  <ol
                    className="breadcrumb px-0 pt-0 breadcrumb-main-mobilea"
                    style={{ display: "flex", flexWrap: "nowrap" }}
                  >
                    <li className={`breadcrumb-item capitalize rest-bread`}>
                      <NavLink
                        to="/"
                        style={{ fontSize: 14, textDecoration: "none" }}
                      >
                        Home
                      </NavLink>
                    </li>
                    <li className={`breadcrumb-item capitalize rest-bread`}>
                      <NavLink
                        to="/exams"
                        style={{ fontSize: 14, textDecoration: "none" }}
                      >
                        Exams
                      </NavLink>
                    </li>
                    <li className={`breadcrumb-item capitalize rest-bread`}>
                      <NavLink
                        to={`/exams/${topic?.subject?.slug}`}
                        style={{ fontSize: 14, textDecoration: "none" }}
                      >
                        {topic?.subject?.subject_title}
                      </NavLink>
                    </li>
                    <li
                      className={`breadcrumb-item capitalize active clamping ellipsis`}
                    >
                      {slugToNormal(params.examSubCatSlug)}
                    </li>
                  </ol>
                </nav>

                {/* Right-aligned Search and My Blogs */}
                <div className="d-flex align-items-center justify-content-end">
                  {/* Search Box */}
                  {/* <div
                    className="search-boxs px-2 d-flex align-items-center border rounded bg-white"
                    style={{ marginBottom: "1rem" }}
                  >
                    <input
                      type="text"
                      autoComplete="chrome-off"
                      onKeyUp={handleSearchExam}
                      className="searchInput px-md-3 px-0 form-control border-0 bg-transparent outline-0"
                      placeholder="Search Exams"
                    />
                    <i
                      className="fa fa-search opacity-1 cursor-pointer"
                      style={{ color: "#ff7200" }}
                      onClick={() => searchExam(1, searchTerm)}
                    ></i>
                  </div> */}

                  {/* My Blogs Button */}
                  <div className="btn-group question-box question-box-card ms-3 mb-3">
                    <button
                      className="btn-soft cursor-pointer hover:bg-themecolor text-white"
                      style={{ backgroundColor: "#ff7200" }}
                      onClick={() => navigation("/exams/myExams")}
                    >
                      My Exams
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1">
                  {!isSearch ? (
                    <>
                      <div className="d-flex align-items-center justify-content-between">
                        <SectionDescription
                          title={`<strong>All ${slugToNormal(
                            params?.examSubCatSlug
                          )} in ${
                            topic?.subject?.subject_title
                          } Exams Mock Test For Practice Session ${yearSatra()}</strong>`}
                          subTitle={`Compiled by Experts across various academic and professional specialties, the Practice Mock Tests for ${topic?.topic_name} in ${topic?.subject?.subject_title} will help you revise all the concepts thoroughly & quickly. Learners/Students are advised to practice all Mock Tests for ${topic?.topic_name} in ${topic?.subject?.subject_title} designed as per latest syllabus with updated content to help identify the areas where you most need preparation.`}
                        />
                      </div>
                      {/* <div className="dropdown">
                        <button className="btn btn-secondary dropdown-toggle" type="button" id="filterDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                            {filter === 'attempted' ? 'Attempted' : 'All'} Exams
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="filterDropdown">
                            <li>
                                <button className="dropdown-item" value="all" onClick={handleFilterChange}>All Exams</button>
                            </li>
                            <li>
                                <button className="dropdown-item" value="attempted" onClick={handleFilterChange}>Attempted Exams</button>
                            </li>
                        </ul>
                    </div> */}
                      <hr
                        style={{ borderColor: "#ccc", marginBottom: 0 }}
                        className="mb-4"
                      />
                    </>
                  ) : null}

                  {isSearch ? (
                    <>
                      <h6 className="text-black-50s label label-default w-100 py-2">
                        Search Result For '{searchTerm}' : {totalRecordFound}{" "}
                        Result Found{" "}
                      </h6>
                      <hr style={{ borderColor: "#f0f0f0", marginTop: 0 }} />
                    </>
                  ) : null}
                  {loading ? (
                    <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                      <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                        <div className="h-10 w-10">
                          <Loadingicon />
                        </div>
                      </div>
                    </div>
                  ) : isNotFound ? (
                    <NotFound/>
                  ) : (
                    <div className="row row-mob">
                      {allContent?.map((content, sIndex) => (
                        <ExamListItem
                          key={sIndex}
                          topic={topic}
                          examData={content}
                          redirectionFn={() =>
                            navigation(`/exams/detail/${content?.slug}`)
                          }
                        />
                      ))}
                    </div>
                  )}
                </div>
                {showLoadMore ? (
                  <ViewAllButton
                    disabled={false}
                    redirectionUrl={"/"}
                    handleClickFn={loadMore}
                    btnText={"Load More"}
                    type={"button"}
                    btnClass={"loadButton card-btn-1"}
                    loading={loadingLoadMore}
                  />
                ) : null}
              </div>
            </div>
          </>
        );
    }

    export default ExamsList;