import React from 'react'
// import notFound from '../../../assets/images/not_found_new.png';
import notFound from '../../../assets/images/nfound.webp';


const NotFound = ({message=`No data found`}) => {
    return (
        <div className="flex-column not-found d-flex text-center">
            <img src={notFound} className="notFoundImg" width={120} opacity={0.4}/>
            <span style={{ opacity: 0.4}}>{message}!</span>
        </div>
    )
}
export default NotFound;
