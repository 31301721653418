import React, { useEffect, useRef, useState } from 'react';
import SectionDescription from '../../../common/sectionDescription';
import BreadCrumbs from '../../../common/BreadCrumbs';
import { ERROR_SOMETHING_WENT_WRONG, nFormatter } from '../../../../utils/utils';

import { toast } from "react-toastify";
import { Loadingicon } from '../../../../AppIcons';
import ViewAllButton from '../../../common/viewallbutton';
import PopularCareerCategories from '../popularcategories';
import { useNavigate } from 'react-router-dom';
import { getAllArtists } from '../../../../api/lyrics';
import LyricistArtistList from '../common/lyricistArtistList';
import { isArray } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import NotFound from '../../../common/notfound';


function LyricsArtist() {
    const [pageNo, setPageNo] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isNotFound, setIsNotFound] = useState(false);
    const [filterType, setFilterType] = useState('artists');   
     const [scrollLoading, setScrollLoading] = useState(false);
    const [loadingLoadMore, setLoadingLoadMore] = useState(false);
    const [allLyricsArtists, setAllLyricsArtists] = useState([]);
    const [allFilteredLyricsArtists, setAllFilteredLyricsArtists] = useState([]);
    const [totalArtists, setTotalArtists] = useState(0);
    const [isSearch, setIsSearch] = useState(false);
    const [searchTerm, setSearchTerm] = useState();
    const [totalRecordFound, setTotalRecordFound] = useState(0);
    // const searchBox = useRef(null);
    const navigation = useNavigate();
    useEffect(() => {
        handleFetchAllLyricsLyricists(1);
    }, [])

    // fetch all lyrics lyricists
    const handleFetchAllLyricsLyricists = async (pageNo) => {
        const resAllLyricsLyricists = await getAllArtists({ pageNo: pageNo });
        if (resAllLyricsLyricists?.success) {
            setTotalArtists(resAllLyricsLyricists?.total)
            setIsNotFound(false)
            resAllLyricsLyricists?.next_page_url ? setShowLoadMore(true) : setShowLoadMore(false);
            if (resAllLyricsLyricists?.data?.length && pageNo == 1) {
                setAllLyricsArtists(resAllLyricsLyricists?.data);
                setAllFilteredLyricsArtists(resAllLyricsLyricists?.data);
            } else if (resAllLyricsLyricists?.data?.length && pageNo !== 1) {
                setAllLyricsArtists([...allLyricsArtists, ...resAllLyricsLyricists?.data]);
                setAllFilteredLyricsArtists([...allLyricsArtists, ...resAllLyricsLyricists?.data]);
            } else {
                if (pageNo == 1) {
                    setAllLyricsArtists([]);
                    setIsNotFound(true);
                }
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }

        setLoading(false);
        setLoadingLoadMore(false);
        setScrollLoading(false);
    }

    // load more quotes albums data
    const loadMore = () => {
        setScrollLoading(true);

        setLoadingLoadMore(true);
        const nxtPage = pageNo + 1;
        handleFetchAllLyricsLyricists(nxtPage);
        setPageNo(nxtPage);
        // searchBox.current.value = '';
        setIsSearch(false)
    }

    const popularCareerCats = [
        {
            name: "Joyce Meyer",
            value: "Joyce Meyer",
            redirectUrl: "#"
        },
        {
            name: "Henry Rollins",
            value: "Henry Rollins",
            redirectUrl: "#"
        },
        {
            name: "Helen Mirren",
            value: "Helen Mirren",
            redirectUrl: "#"
        },
        {
            name: "Justin Trudeau",
            value: "Justin Trudeau",
            redirectUrl: "#"
        },
        {
            name: "Helen Fisher",
            value: "Helen Fisher",
            redirectUrl: "#"
        },
        {
            name: "Maya Angelou",
            value: "Maya Angelou",
            redirectUrl: "#"
        },
        {
            name: "Helen Keller",
            value: "Helen Keller",
            redirectUrl: "#"
        },
        {
            name: "Julie Sweet",
            value: "Julie Sweet",
            redirectUrl: "#"
        },
        {
            name: "More ...",
            value: "More ...",
            redirectUrl: "#"
        }
    ];


    const handleSearchArtist = (e) => {
        const searchTxt = e.target.value;
        setSearchTerm(searchTxt);
        if (searchTxt == '') {
            setIsSearch(false);
            handleFetchAllLyricsLyricists(1);
            return;
        }
        if (searchTxt !== '' && e.key === "Enter") {
            setIsSearch(true);
            searchArtist(searchTxt);
        }
    }

    const searchArtist = (searchTxt) =>{            
            const fArtists = allLyricsArtists?.filter((ar, index)=>ar.name.toLowerCase().includes(searchTxt.toLowerCase()) );
            setAllFilteredLyricsArtists(fArtists);
            setTotalRecordFound(fArtists?.length);
    }

    const filterTypeData = [
        {
            name:'Albums',
            value:'albums',
        },{
            name:'Lyricists',
            value:'lyricists'
        },{
            name:'Artists',
            value:'artists'
        }
    ]

    const handleFilterChange = (e) =>{
        setPageNo(1);
        const fValue = e.target.value;
            navigation(`/lyrics/${fValue}`)
    }
    return (<>
        <div className='d-flex align-items-center justify-content-between'>
            <BreadCrumbs />
            {/* <div className="search-boxs px-2 d-flex  align-items-center border rounded bg-white d-flex justify-content-between" style={{ marginBottom: '1rem' }}><input type="text" autocomplete="chrome-off" onKeyUp={handleSearchArtist} className="searchInput px-md-3 px-0 form-control border-0 bg-transparent outline-0" ref={searchBox} placeholder="Search Artist in List" />
                <i className="fa fa-search opacity-1 cursor-pointer" style={{ color: '#ff7200' }} onClick={() => searchArtist(searchTerm)}></i>
            </div> */}
        </div>
        <div className='row'>
            <div className='col-md-12'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1">
                    {
                        !isSearch?<><div className="d-md-flex align-items-center justify-content-between">
                        <SectionDescription title={`<strong>Top 10 Popular Artists</strong>`} subTitle={`Find any song lyrics , searching by over ${nFormatter(totalArtists)} song artists, updated with the latest releases and song lyrics!`} />
                        <select className='form-control mt-md-0 mt-4' style={{maxWidth:120}}  onChange={handleFilterChange}  value={filterType}>
                            {
                                filterTypeData?.map((fData, i)=><option key={i+'ftype'} value={fData?.value}>{fData?.name}</option>)
                            }                                    
                        </select>
                    </div>

                    <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-4" /></>:null
                    }
                    
                    {
                        isSearch ? <><h6 className="text-black-50s label label-default w-100 py-2">Search Result For '{searchTerm}' : {totalRecordFound} Result Found</h6><hr style={{ borderColor: '#f0f0f0', marginTop: 0 }} /></> : null
                    }
                    {loading ? (
                        <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                            <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                                <div className="h-10 w-10">
                                    <Loadingicon />
                                </div>
                            </div>
                        </div>) : isNotFound ? (
                            <NotFound/>) : <div className='row'>
                                <InfiniteScroll
                                    dataLength={allFilteredLyricsArtists?.length}
                                    next={() =>loadMore()}
                                    hasMore={showLoadMore}
                                    loader={scrollLoading && (
                                    <div className="w-full flex items-center justify-center mb-20 text-themecolor min-h-[400px] absolute top-0 left-0 bg-white h-full bg-opacity-50">
                                        <div className="h-10 w-10">
                                        <Loadingicon />
                                        </div>
                                    </div>
                                    )}
                                    pullDownToRefresh
                                    pullDownToRefreshThreshold={90}
                                    refreshFunction={() => {
                                    setPageNo(1);
                                    setAllFilteredLyricsArtists([]);
                                    }}
                                    scrollableTarget={"qBank"}
                                    className={"relative"}
                                >
                                    <div className="py-8 grid pr-2 p-md-4 px-3 pr-md-2 gap-4 multi-card lg:mt-0 mb-0 grid-cols-4 scroll-container-custom" style={{ overflow: "auto" }} id="qBank">
                                    {isArray(allFilteredLyricsArtists) && allFilteredLyricsArtists.map((artist, sIndex) => (<>
                                        <LyricistArtistList laData={artist} key={sIndex} type="artist" mH={false} boxSize={'col-md-12'} redirectionFn={() => navigation(`/lyrics/artist/${artist?.id}/${artist?.slug}`)} />
                                    </>
                                    ))}
                                    </div>
                                </InfiniteScroll>
                        {/* {
                            allFilteredLyricsArtists?.map((artist, sIndex) => (
                                <LyricistArtistList laData={artist} key={sIndex} type="artist" mH={false} boxSize={'col-md-3'} redirectionFn={() => navigation(`/lyrics/artist/${artist?.id}/${artist?.slug}`)} />
                            ))
                        } */}
                    </div>
                    }
                </div>
                {/* {
                    showLoadMore ? <ViewAllButton
                        disabled={false}
                        redirectionUrl={'/'}
                        handleClickFn={loadMore}
                        btnText={'Load More'}
                        type={'button'}
                        btnClass={'loadButton card-btn-1'}
                        loading={loadingLoadMore}
                    /> : null
                } */}

            </div>

        </div>

    </>)
}

export default LyricsArtist;
