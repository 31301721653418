import React, { useEffect, useState } from 'react';
import SectionDescription from '../../../common/sectionDescription';
import BreadCrumbs from '../../../common/BreadCrumbs';
import { ERROR_SOMETHING_WENT_WRONG, slugToNormal, yearSatra } from '../../../../utils/utils';
import { getLatestGovNews, getTopSarkariNews } from '../../../../api/careernews';
import { toast } from "react-toastify";
import { Loadingicon } from '../../../../AppIcons';
import ViewAllButton from '../../../common/viewallbutton';
import PopularCareerCategories from '../popularcategories';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../../Context/AuthContext';
import BranchDetailList from '../common/branchDetailList';
import NewsListItem from '../common/newsList';
import NotFound from '../../../common/notfound';


function LatestHindiNews() {
    const { globalToken } = useAuth();
    const [pageNo, setPageNo] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isNotFound, setIsNotFound] = useState(false);
    const [loadingLoadMore, setLoadingLoadMore] = useState(false);
    const [newsList, setNewsList] = useState([]);
    const navigation = useNavigate();
    useEffect(() => {        
            handleFetchAllHindiNews(1);  
    }, [])

    // fetch all hindi news
    const handleFetchAllHindiNews = async (pageNo) => {
        const resHindiNews = await getTopSarkariNews({ token:globalToken, pageNo});
        if (resHindiNews?.success) {
            setIsNotFound(false)
            resHindiNews?.next_page_url ? setShowLoadMore(true) : setShowLoadMore(false);
            if (resHindiNews?.data?.length && pageNo == 1) {
                setNewsList(resHindiNews?.data);
            } else if (resHindiNews?.data?.length && pageNo !== 1) {
                setNewsList([...newsList, ...resHindiNews?.data]);
            } else {
                if (pageNo == 1) {
                    setNewsList([]);
                    setIsNotFound(true);
                }
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }

        setLoading(false);
        setLoadingLoadMore(false);
    }

    // load more news data
    const loadMore = () => {
        setLoadingLoadMore(true);
        const nxtPage = pageNo + 1;
        handleFetchAllHindiNews(nxtPage);
        setPageNo(nxtPage);
    }

    
    const popularCareerCats = [
        {
            name: "Latest Govt Jobs",
            value: "Latest Govt Jobs",
            redirectUrl: "#"
        },
        {
            name: "Govt Jobs By Qualification",
            value: "Govt Jobs By Qualification",
            redirectUrl: "#"
        },
        {
            name: "Govt Jobs By Location",
            value: "Govt Jobs By Location",
            redirectUrl: "#"
        },
        {
            name: "Latest Private Jobs",
            value: "Latest Private Jobs",
            redirectUrl: "#"
        },
        {
            name: "Private Jobs By Qualification",
            value: "Private Jobs By Qualification",
            redirectUrl: "#"
        },
        {
            name: "Private Jobs By Location",
            value: "Private Jobs By Location",
            redirectUrl: "#"
        },
        {
            name: "Latest Internships",
            value: "Latest Internships",
            redirectUrl: "#"
        },
        {
            name: "Internships By Education",
            value: "Internships By Education",
            redirectUrl: "#"
        },
        {
            name: "Internships By Location",
            value: "Internships By Location",
            redirectUrl: "#"
        }
    ];

    return (<>
        {/* <BreadCrumbs /> */}
        <nav aria-label="breadcrumb" className="pt-0 px-0 pb-0 breadcrumb-card">
                <ol className="breadcrumb px-0 pt-0 breadcrumb-main-mobilea" style={{ display: 'flex', flexWrap: 'nowrap' }}>
                    <li className={`breadcrumb-item capitalize rest-bread`}>
                    <NavLink to='/' style={{ fontSize: 14, textDecoration: 'none' }}>Home</NavLink>
                    </li>                    
                    <li className={`breadcrumb-item capitalize rest-bread`}>
                    <NavLink to='/career-news' style={{ fontSize: 14, textDecoration: 'none' }}>Career News</NavLink>
                    </li> 
                    <li 
                        className={`breadcrumb-item capitalize active clamping ellipsis`}
                        >
                        {slugToNormal(`latest-hindi-news`)}
                    </li>
                    
      </ol>
    </nav>
        <div className='row'>
            <div className='col-md-9'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1">
                    <div className="d-flex align-items-center justify-content-between">
                        <SectionDescription title={`<strong>आज की सरकारी नौकरी न्यूज़ - सबसे तेज़ , सबसे पहले</strong>`} subTitle={`TuteeHUB Sarkari Hindi News के माध्यम से केंद्र एवं राज्य सरकार की सरकारी नौकरी जैसे :- पुलिस भर्ती, भारतीय सेना , भारतीय नौसेना अथवा वायुसेना भर्ती, बैंक भर्ती, रेलवे भर्ती, एसएससी, यूपीएससी, पीएससी, प्रवेश पत्र, सरकारी रिजल्ट, सामान्य ज्ञान, करंट अफेयर्स, Full Form एवं शब्दकोष यहाँ पर सबसे पहले प्राप्त कर सकते हैं। इसके अलावा Sarkari Exam Preparation करने वाले नि:शुल्क Online Test Practiceकर सकते हैं ।`} />
                    </div>

                    <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-4" />

                    {loading ? (
                        <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                            <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                                <div className="h-10 w-10">
                                    <Loadingicon />
                                </div>
                            </div>
                        </div>) : isNotFound ? (
                            <NotFound/>) : <>
                        {
                            newsList?.map((newsData, sIndex) => (                             
                                <NewsListItem
                                key={sIndex} 
                                thumbPrefix={`thcn/hindi-news/desktop/thumb/`}
                                newsData={newsData}  
                                type="hindiNews" 
                                redirectionFn={()=>navigation(`/career-news/hindi-news/${newsData?.id}/${newsData?.slug}`)}
                                />
                            ))
                        }

                    </>
                    }
                </div>
                {
                    showLoadMore ? <ViewAllButton
                        disabled={false}
                        redirectionUrl={'/'}
                        handleClickFn={loadMore}
                        btnText={'Load More'}
                        type={'button'}
                        btnClass={'loadButton card-btn-1'}
                        loading={loadingLoadMore}
                    /> : null
                }

            </div>
            <div className='col-md-3'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1 position-sticky" style={{ top: 80 }}>
                    
                    <PopularCareerCategories catsList={popularCareerCats} title={`Popular Categories`}/>
                </div>
            </div>
        </div>

    </>)
}

export default LatestHindiNews;
